import { useEffect } from 'react';
import { useAuthStore } from '@studio/features/auth';
import { useChannelStore } from '@studio/features/channel-select';

declare global {
  interface Window {
    heap: {
      identify: (email: string) => void;
      addUserProperties: (properties: Record<string, string>) => void;
      track: (event: string, properties?: Record<string, string>) => void;
    };
  }
}

export default function HeapAnalytics() {
  const { activeChannelUcid, getActiveChannel } = useChannelStore();
  const { user } = useAuthStore();

  const email = user?.email;

  useEffect(() => {
    if (window.heap && email && getActiveChannel()) {
      window.heap.identify(email);
      window.heap.addUserProperties({
        name: `${user.firstName} ${user.lastName}`,
        channel_selected_name: getActiveChannel().name,
        channel_selected_ucid: getActiveChannel().ucid,
        app_name: 'studio',
      });
    }
  }, [email, activeChannelUcid]);

  return null;
}
