import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type LiveBlockState = {
  connectionError: string | null;
  setLiveBlockConnectionError: (error: string) => void;
};

export const useLiveBlockStore = create<LiveBlockState>()(
  devtools(
    (set) => ({
      connectionError: null,
      setLiveBlockConnectionError: (connectionError) =>
        set(
          () => ({ connectionError }),
          false,
          'liveblocks/set-live-block-connection-error'
        ),
    }),
    { name: 'liveblocks' }
  )
);
