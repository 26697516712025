import React, { createContext, useContext, PropsWithChildren } from 'react';

interface PaginationContextProps {
  currentPage: number;
  totalPages: number;
  nextPage?: boolean;
  prevPage?: boolean;
  onPageChange?: (page: number) => void;
}

const PaginationContext = createContext<PaginationContextProps | undefined>(
  undefined
);

const usePagination = () => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

const PaginationProvider: React.FC<
  PropsWithChildren<PaginationContextProps>
> = ({
  currentPage,
  totalPages,
  onPageChange,
  nextPage,
  prevPage,
  children,
}) => {
  return (
    <PaginationContext.Provider
      value={{
        nextPage,
        prevPage,
        totalPages,
        currentPage,
        onPageChange,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export { PaginationProvider, usePagination };
