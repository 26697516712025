import {
  PERFORMANCE_RATIO_THRESHOLD_SUPER,
  PERFORMANCE_RATIO_THRESHOLD_EXPLOSIVE,
} from './constants';
import { PowerKeywordTooltipVariant } from './types';

export const getPowerKeywordTooltipVariant = (
  performanceRatio: string
): PowerKeywordTooltipVariant => {
  const ratio = parseFloat(performanceRatio);

  // Default to STRONG if input is invalid
  if (isNaN(ratio)) {
    return PowerKeywordTooltipVariant.STRONG;
  }

  if (ratio < PERFORMANCE_RATIO_THRESHOLD_SUPER) {
    return PowerKeywordTooltipVariant.STRONG;
  }

  if (
    ratio >= PERFORMANCE_RATIO_THRESHOLD_SUPER &&
    ratio < PERFORMANCE_RATIO_THRESHOLD_EXPLOSIVE
  ) {
    return PowerKeywordTooltipVariant.SUPER;
  }

  return PowerKeywordTooltipVariant.EXPLOSIVE;
};
