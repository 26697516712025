import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function SubscriptionIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="28"
      height="31"
      viewBox="0 0 28 31"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1679 0.366735C13.6314 0.099736 14.2021 0.0997618 14.6655 0.366803C17.4186 1.95305 20.1772 3.59338 22.8903 5.20664C24.0239 5.88068 25.1495 6.55 26.2635 7.20866C26.7202 7.47873 27.0003 7.96999 27 8.5006C26.9997 9.0312 26.7192 9.52218 26.2622 9.79178L14.6788 16.6251C14.2086 16.9025 13.6248 16.9025 13.1545 16.6251L1.57119 9.79178C1.11158 9.52065 0.830624 9.02575 0.833363 8.49214C0.836103 7.95852 1.12213 7.46654 1.58449 7.20014C3.51842 6.08586 5.42934 4.94519 7.35194 3.79753L7.35576 3.79525C9.27639 2.64878 11.2087 1.49535 13.1679 0.366735ZM5.29845 8.50748L13.9167 13.5916L22.5535 8.49651C22.1488 8.25602 21.7434 8.01499 21.3375 7.77364C18.8793 6.31191 16.4008 4.83816 13.9172 3.39919C12.236 4.37603 10.569 5.371 8.89343 6.37122L8.87987 6.37931C7.69166 7.08858 6.49905 7.80048 5.29845 8.50748Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5851 7.19995C2.05019 6.93227 2.62285 6.93343 3.08685 7.20299L14.7535 13.9809C15.2156 14.2493 15.5 14.7434 15.5 15.2779V28.8333C15.5 29.3672 15.2162 29.8609 14.7549 30.1296C14.2935 30.3982 13.7241 30.4014 13.2598 30.138C11.2918 29.0213 9.35055 27.8801 7.42066 26.7456L7.40693 26.7376C5.46927 25.5985 3.54298 24.4662 1.5932 23.3601C1.12355 23.0937 0.833344 22.5954 0.833344 22.0555V8.5C0.833344 7.96338 1.12001 7.46763 1.5851 7.19995ZM3.83334 11.1062V21.1837C5.54697 22.1643 7.24154 23.1604 8.92726 24.1513L8.93119 24.1537C10.1225 24.854 11.3094 25.5517 12.5 26.2423V16.1412L3.83334 11.1062Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4189 7.20225C26.8818 7.47054 27.1667 7.96502 27.1667 8.5V22.0555C27.1667 22.5986 26.873 23.0994 26.399 23.3645C24.423 24.4699 22.4706 25.6015 20.5066 26.7398L20.5026 26.7421C18.5405 27.8793 16.5667 29.0232 14.5656 30.1425C14.101 30.4023 13.5336 30.3965 13.0745 30.1272C12.6154 29.858 12.3333 29.3656 12.3333 28.8333V15.2779C12.3333 14.7401 12.6212 14.2435 13.0878 13.9763L24.9211 7.19839C25.3854 6.9325 25.9561 6.93397 26.4189 7.20225ZM15.3333 16.1473V26.2609C16.5563 25.5618 17.7751 24.8555 18.9983 24.1465L19.012 24.1386C20.7182 23.1497 22.4329 22.1559 24.1667 21.1777V11.0878L15.3333 16.1473Z"
        fill="currentColor"
      />
    </svg>
  );
}
