import { graphql } from '@lib/gql';

export const GET_CHANNEL_STATS_LIST_QUERY = graphql(`
  query channelStatsList($input: ChannelStatsListInput!) {
    channelStatsList(input: $input) {
      count
      currentPage
      currentPageSize
      numPages
      results {
        ucid
        name
        thumbnail
        subscribers
        videoCount
        keywords
        description
        lifetimeViews
        runDate
      }
    }
  }
`);
