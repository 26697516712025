export function isValidYouTubeUrl(url: string) {
  try {
    const newUrl = new URL(url);
    const allowedHostNames = ['www.youtube.com', 'youtu.be', 'youtube.com'];
    const isYouTubeUrl = allowedHostNames.some((host) =>
      newUrl.hostname.includes(host)
    );

    if (newUrl.hostname.includes('youtube.com')) {
      if (
        newUrl.pathname === '/watch' &&
        newUrl.searchParams.has('v') &&
        newUrl.searchParams.get('v')?.length === 11
      ) {
        return isYouTubeUrl;
      }

      if (
        newUrl.pathname.startsWith('/shorts/') &&
        newUrl.pathname.split('/')[2].length === 11
      ) {
        return isYouTubeUrl;
      }
    }

    if (newUrl.hostname.includes('youtu.be')) {
      return isYouTubeUrl && newUrl.pathname.length === 12;
    }

    return false;
  } catch (error) {
    return false;
  }
}
