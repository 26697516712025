import { useState } from 'react';
import { trackEvent } from '@studio/lib/heap';
import * as EVENTS from '../../heap.constants';

export const useProjectPropertiesAnalytics = (
  id: string,
  {
    sponsors,
    targetDuration,
  }: { sponsors?: string[]; targetDuration?: string } = {}
) => {
  // Use to store previous value, for analytics
  const [localSponsorValue, setLocalSponsorValue] = useState(sponsors);
  const [localTargetDuration, setLocalTargetDuration] =
    useState(targetDuration);

  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onAccessChange = (oldValue: string, newValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_SHARED_TYPE_TOGGLE, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onProjectStatusOpen = () => {
    track(EVENTS.PROJECTS_PROPERTIES_PROJECT_STATUS_OPEN);
  };

  const onProjectStatusChange = (oldValue: string, newValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_PROJECT_STATUS_CHANGE, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onSponsorChange = (newValue: string[]) => {
    track(EVENTS.PROJECTS_PROPERTIES_SPONSOR_CHANGE, {
      old_value: localSponsorValue?.join(', '),
      new_value: newValue.join(', '),
    });
    setLocalSponsorValue(sponsors);
  };

  const onSponsorReset = (oldValue: string[]) => {
    track(EVENTS.PROJECTS_PROPERTIES_SPONSOR_RESET, {
      old_value: oldValue.join(', '),
      new_value: '',
    });
  };

  const onTargetDateChange = (
    oldValue: Date | undefined,
    newValue: Date | undefined
  ) => {
    track(EVENTS.PROJECTS_PROPERTIES_PUBLISH_DATE_CHANGE, {
      old_value: oldValue?.toISOString(),
      new_value: newValue?.toISOString(),
    });
  };

  const onTargetDateReset = (oldValue: Date | undefined) => {
    track(EVENTS.PROJECTS_PROPERTIES_PUBLISH_DATE_RESET, {
      old_value: oldValue?.toISOString(),
      new_value: '',
    });
  };

  const onVideoLengthChange = (newValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_TARGET_VIDEO_LENGTH_CHANGE, {
      old_value: localTargetDuration,
      new_value: newValue,
    });
    setLocalTargetDuration(newValue);
  };

  const onVideoLengthReset = (oldValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_TARGET_VIDEO_LENGTH_RESET, {
      old_value: oldValue,
      new_value: '',
    });
  };

  const onVideoTypeOpen = () => {
    track(EVENTS.PROJECTS_PROPERTIES_VIDEO_TYPE_OPEN);
  };

  const onVideoTypeChange = (oldValue: string, newValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_VIDEO_TYPE_CHANGE, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onYTUrlChange = (oldValue: string, newValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_YOUTUBE_URL_CHANGE, {
      old_value: oldValue,
      new_value: newValue,
    });
  };

  const onYTUrlReset = (oldValue: string) => {
    track(EVENTS.PROJECTS_PROPERTIES_YOUTUBE_URL_RESET, {
      old_value: oldValue,
      new_value: '',
    });
  };

  return {
    onAccessChange,
    onProjectStatusOpen,
    onProjectStatusChange,
    onSponsorChange,
    onSponsorReset,
    onTargetDateChange,
    onTargetDateReset,
    onVideoLengthChange,
    onVideoLengthReset,
    onVideoTypeOpen,
    onVideoTypeChange,
    onYTUrlChange,
    onYTUrlReset,
  };
};
