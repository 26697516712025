import { CSSProperties, ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import { hiddenScrollbars } from './layout.css';
import type { BaseProps, BoxProps, FlexProps, GridProps } from './layout.types';

export const Container = forwardRef(
  (props: BaseProps, ref: ForwardedRef<HTMLElement>) => {
    const { as: Element = 'div', children, className, style, ...rest } = props;
    return (
      <Element ref={ref} className={className} style={style} {...rest}>
        {children}
      </Element>
    );
  }
);

export const Flex = forwardRef(
  (
    props: BaseProps & FlexProps & HTMLAttributes<HTMLElement>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { as, children, className, style, ...rest } = props;

    const {
      alignContent,
      alignItems,
      alignSelf,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      gap,
      height,
      justifyContent,
      maxWidth,
      minHeight,
      minWidth,
      order,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
      ...attributes
    } = rest;

    const styles: CSSProperties = {
      alignContent,
      alignItems,
      alignSelf,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      gap,
      height,
      justifyContent,
      maxWidth,
      minHeight,
      minWidth,
      order,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
    };

    return (
      <Container
        ref={ref}
        as={as}
        className={clsx(hiddenScrollbars, className)}
        style={{ ...styles, ...style, display: 'flex' }}
        {...attributes}
      >
        {children}
      </Container>
    );
  }
);

export const Grid = forwardRef(
  (
    props: BaseProps & GridProps & HTMLAttributes<HTMLElement>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { as, children, className, style = {}, ...rest } = props;
    const {
      alignContent,
      alignItems,
      area,
      autoFlow,
      columnGap,
      columns,
      gap,
      height,
      justifyContent,
      maxWidth,
      minHeight,
      minWidth,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      rowGap,
      rows,
      width,
      ...attributes
    } = rest;

    const styles: CSSProperties = {
      alignContent,
      alignItems,
      columnGap,
      gridTemplateColumns: columns,
      gap,
      gridArea: area,
      gridAutoFlow: autoFlow,
      gridTemplateRows: rows,
      height,
      justifyContent,
      maxWidth,
      minHeight,
      minWidth,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      rowGap,
      width,
    };

    return (
      <Container
        ref={ref}
        as={as}
        className={clsx(hiddenScrollbars, className)}
        style={{ ...styles, ...style, display: 'grid' }}
        {...attributes}
      >
        {children}
      </Container>
    );
  }
);

export const Box = forwardRef(
  (
    props: BaseProps & BoxProps & HTMLAttributes<HTMLElement>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const { as, children, className, style, ...rest } = props;

    const {
      height,
      maxWidth,
      minHeight,
      minWidth,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
      ...attributes
    } = rest;

    const styles: CSSProperties = {
      height,
      maxWidth,
      minHeight,
      minWidth,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
    };

    return (
      <Container
        ref={ref}
        as={as}
        className={clsx(hiddenScrollbars, className)}
        style={{ ...style, ...styles, display: 'block' }}
        {...attributes}
      >
        {children}
      </Container>
    );
  }
);
