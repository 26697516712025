import { type ReactNode } from 'react';
import { StatsigProvider, StatsigUser } from '@statsig/react-bindings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuthStore } from '@studio/features/auth';
import { useAutoTokenRefresh } from '@studio/hooks/use-auto-token-refresh';
import Intercom from '@studio/lib/intercom/intercom';
import { useStatsigClient } from '@studio/lib/statsig';
import { getEnvironment } from '@studio/utils/env-utils';
import { Datadog } from '@lib/datadog';
import { Toast } from '@lib/ui';
import HeapAnalytics from '../lib/heap/analytics';

export const queryClient = new QueryClient();

export function AppProviders({ children }: { children: ReactNode }) {
  const { email, id, isInternalUser, firstName, lastName } = useAuthStore(
    (store) => ({
      email: store.user?.email,
      id: store.user?.id,
      firstName: store.user?.firstName,
      lastName: store.user?.lastName,
      isInternalUser: store.isInternalUser(),
    })
  );

  const statsigUser: StatsigUser = {
    email,
    userID: email,
    custom: {
      isInternal: isInternalUser,
      spotterUserId: id,
    },
  };

  const statsigOptions = { initialUser: statsigUser };
  const { client: statsigClient, isLoading: isStatsigLoading } =
    useStatsigClient(statsigOptions);

  const datadogUserData = {
    email: email ?? '',
    name: `${firstName} ${lastName}`,
    id: id ?? '',
  };

  const datadogRumConfig = {
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    service: import.meta.env.VITE_DATADOG_SERVICE,
  };

  useAutoTokenRefresh();

  // Ensure that Statsig is ready with latest values before rendering
  // https://docs.statsig.com/client/javascript-sdk/init-strategies#overview
  if (isStatsigLoading || !statsigClient) {
    return null; // or a loading screen...
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StatsigProvider client={statsigClient}>
          <Toast.ToastProvider>{children}</Toast.ToastProvider>
        </StatsigProvider>
      </QueryClientProvider>
      <Intercom />
      <HeapAnalytics />
      <Datadog
        clientToken={import.meta.env.VITE_DATADOG_CLIENT_TOKEN}
        site={import.meta.env.VITE_DATADOG_SITE}
        env={getEnvironment()}
        version={import.meta.env.VITE_APP_VERSION}
        rumInitConfig={datadogRumConfig}
        userData={datadogUserData}
      />
    </>
  );
}
