import { create } from 'zustand';
import { USER_ROLE } from '@lib/types';
import { CHANNEL_ROLE, UserEntitlement } from './types';

export type InviteUserFormState = {
  email: string;
  role: string;
  entitlements: UserEntitlement[];
};

const INITIAL_FORM_DATA = {
  email: '',
  role: USER_ROLE.USER, // defaults to user
  entitlements: [],
};

type FormStateActions = {
  isFormValid: () => boolean;
  setField: (
    name: keyof InviteUserFormState,
    value: string | UserEntitlement[]
  ) => void;
  getField: (name: keyof InviteUserFormState) => string | UserEntitlement[];
  getAllFields: () => InviteUserFormState;
  reset: () => void;
};

export const useInviteUserStore = create<
  InviteUserFormState & FormStateActions
>((set, get) => ({
  ...INITIAL_FORM_DATA,
  setField: (name, value) => set({ [name as string]: value }),
  getField: (name) => get()[name],
  reset: () =>
    set({
      ...INITIAL_FORM_DATA,
    }),
  isFormValid: () => {
    const isEmailFilled = !!get().email;
    const hasChannelAccess =
      get().entitlements.filter(({ role }) => role !== CHANNEL_ROLE.NONE)
        .length > 0;

    return isEmailFilled && hasChannelAccess;
  },
  getAllFields: () => {
    return {
      email: get().email,
      role: get().role,
      entitlements: get().entitlements.filter(
        ({ role }) => role === CHANNEL_ROLE.USER || role === CHANNEL_ROLE.ADMIN
      ),
    };
  },
}));
