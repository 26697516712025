// Kirby Client Utils for using the Kirby Client in Studio
import { KirbyConfig } from '@lib/kirby-client';

export interface StudioKirbyConfig extends Omit<KirbyConfig, 'origin'> {
  origin?: string;
}

export function createStudioKirbyConfig(
  studioKirbyConfig: StudioKirbyConfig
): KirbyConfig {
  // NOTE (@torch2424): Don't need extra headers because we pass along our Auth0 Cookies
  return {
    origin: import.meta.env.VITE_KIRBY_ORIGIN,
    transports: ['websocket'],
    ...studioKirbyConfig,
  };
}
