export const ideaWallRoutes = [
  {
    path: ':organizationId/:channelUcid/idea-wall',
    async lazy() {
      const { IdeaWallRoute } = await import('./idea-wall');
      return {
        Component: IdeaWallRoute,
      };
    },
  },
];
