const hexToRgb = (hex: string): string => {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

export const convertColorsToRGB = (colors: {
  [key: string]: string;
}): { [key: string]: string } => {
  const colorsRGB: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(colors)) {
    colorsRGB[key] = hexToRgb(value);
  }

  return colorsRGB;
};
