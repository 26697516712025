import { useState } from 'react';
import { format } from 'date-fns';
import {
  ClassNames,
  DayPicker,
  DayPickerSingleProps,
  DateFormatter,
  SelectSingleEventHandler,
} from 'react-day-picker';
import {
  button,
  calendarVariants,
  cell,
  headCell,
  monthHeader,
  nav,
  table,
  tableWrapper,
  type CalendarVariants,
} from './datepicker.css';

export type DatepickerProps = Omit<DayPickerSingleProps, 'mode'> &
  CalendarVariants & {
    className?: string;
    minDate?: Date | undefined;
    maxDate?: Date | undefined;
    value?: Date | undefined;
    onChange?: (date: Date) => void;
  };

export const Datepicker = ({
  onChange,
  minDate = undefined,
  maxDate = new Date(),
  value,
  variant = 'light',
  ...props
}: DatepickerProps) => {
  const initialDate = value ?? undefined;
  const [selected, setSelected] = useState<Date | undefined>(initialDate);

  const selectDate: SelectSingleEventHandler = (date: Date | undefined) => {
    const newDate = date || new Date();
    setSelected(newDate);
    if (onChange) {
      onChange(newDate);
    }
  };

  const styleParts: ClassNames = {
    button: button,
    caption: monthHeader,
    cell: cell,
    head_cell: headCell,
    month: tableWrapper,
    nav: nav,
    table: table,
  };

  const formatWeekdayName: DateFormatter = (day) => {
    return format(day, 'EEE');
  };

  return (
    <DayPicker
      className={calendarVariants({ variant })}
      classNames={styleParts}
      initialFocus={true}
      defaultMonth={selected}
      selected={selected}
      onSelect={selectDate}
      fromDate={minDate}
      toDate={maxDate}
      modifiersClassNames={{
        selected: 'selected',
      }}
      formatters={{ formatWeekdayName }}
      required
      {...props}
      mode="single"
    />
  );
};
