import { User } from '@lib/types';
import * as Y from 'yjs';
export type Version = {
  contributors: Partial<User>[];
  createdAt: string;
  snapshot: Uint8Array;
};

export const DEFAULT_MERGE_TIMEDIFF = 300000; // 5 minutes
export const DEFAULT_VERSION_KEY = 'versions';
export const DEFAULT_VERSION_ORIGIN = 'insertHistory';

const insertNewVersion = (
  versionsArray: Y.Array<Y.Map<unknown>>,
  author: Partial<User>,
  doc: Y.Doc
) => {
  const version = new Y.Map();
  const contributors = new Y.Array<Partial<User>>();
  contributors.push([author]);
  version.set('contributors', contributors);
  version.set('createdAt', new Date().toISOString());
  version.set('snapshot', Y.encodeSnapshot(Y.snapshot(doc)));
  versionsArray.push([version]);
};

export const createVersion = (
  doc: Y.Doc,
  author: Partial<User>,
  mergeTimeDiff = DEFAULT_MERGE_TIMEDIFF,
  versionKey = DEFAULT_VERSION_KEY,
  origin = DEFAULT_VERSION_ORIGIN
) => {
  doc.transact(() => {
    const versionsArray = doc.getArray<Y.Map<unknown>>(versionKey);
    const lastVersion = versionsArray.get(versionsArray.length - 1);
    // is first edit
    if (!lastVersion) {
      insertNewVersion(versionsArray, author, doc);
      return;
    }
    // is passed time diff
    const lastDate = new Date(lastVersion.get('createdAt') as string);
    if (Date.now() - lastDate.getTime() > mergeTimeDiff) {
      insertNewVersion(versionsArray, author, doc);
      return;
    }
    // else update the previous snapshot
    const yPrevContributors = lastVersion.get('contributors') as Y.Array<
      Partial<User>
    >;
    const prevContributors = yPrevContributors.toJSON();
    if (
      !prevContributors.map((contrib) => contrib.email).includes(author.email)
    ) {
      yPrevContributors.push([author]);
    }
    lastVersion.set('createdAt', new Date().toISOString());
    lastVersion.set('snapshot', Y.encodeSnapshot(Y.snapshot(doc)));
  }, origin);
};
