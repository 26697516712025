import { create } from 'zustand';

export type ModalStore = {
  modals: Record<string, boolean>;
  modalData?: Record<string, (() => void) | undefined | null>;
  closeModal: (id: string) => void;
  openModal: (id: string, data?: () => void | undefined) => void;
  toggleModal: (id: string) => void;
};

export const useModalStore = create<ModalStore>((set, get) => ({
  modals: {},
  modalData: {},
  closeModal: (id) => {
    set((state) => {
      const newModalData = { ...state.modalData };
      delete newModalData[id];
      return {
        modals: { ...state.modals, [id]: false },
        modalData: newModalData,
      };
    });
  },
  openModal: (id, data) => {
    set((state) => ({
      modals: { ...state.modals, [id]: true },
      modalData: { ...state.modalData, [id]: data },
    }));
  },
  toggleModal: (id) => {
    const modals = get().modals;
    const isOpen = modals[id];
    set((state) => {
      const newModalData = { ...state.modalData };
      if (isOpen) {
        delete newModalData[id];
      }
      return {
        modals: { ...modals, [id]: !isOpen },
        modalData: newModalData,
      };
    });
  },
}));
