import { api } from '@studio/lib';
import { CreateVideoProjectInput } from '@lib/gql/graphql';

export type CreateProjectResponse = {
  type: string;
  id: string;
  lastConnectionAt: string;
  createdAt: string;
  metadata: {
    env: string;
  };
  defaultAccesses: unknown[];
  usersAccesses: Record<string, string[]>;
  videoProjectId: string;
};

export const createProject = async (
  input: CreateVideoProjectInput
): Promise<CreateProjectResponse> => {
  const { channelId, orgId, ...restOfInput } = input;
  const response = await api.lakitu.post<CreateProjectResponse>(
    `/api/orgs/${orgId}/channels/${channelId}/video-projects`,
    {
      data: restOfInput,
      headers: {
        'content-type': 'application/json',
      },
    }
  );
  return response;
};

export default { createProject };
