import { PropsWithChildren, forwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import clsx from 'clsx';
import * as Styles from './checkbox.css';
import type { OverridableStyles } from '../../types';

/**
 * Checkbox Root
 */
export type CheckboxRootProps = PropsWithChildren &
  OverridableStyles & {
    disabled?: boolean;
  };

export function Root(props: CheckboxRootProps) {
  const { children, className, disabled } = props;
  return (
    <div className={clsx(Styles.root, { disabled }, className)}>{children}</div>
  );
}

Root.displayName = 'CheckboxRoot';

/**
 * Checkbox Input
 */
export type InputProps = PropsWithChildren &
  OverridableStyles &
  CheckboxPrimitive.CheckboxProps &
  Styles.CheckboxVariants;

export function Input(props: InputProps) {
  const {
    checked,
    onCheckedChange,
    children,
    className,
    variant = 'primary',
    size = 'sm',
    id,
    ...otherProps
  } = props;
  return (
    <CheckboxPrimitive.Root
      id={id}
      className={clsx(Styles.checkboxVariants({ variant, size }), className)}
      checked={checked}
      onCheckedChange={onCheckedChange}
      {...otherProps}
    >
      {children}
    </CheckboxPrimitive.Root>
  );
}

Input.displayName = 'CheckboxInput';

/**
 * Checkbox Indicator
 */
export type CheckboxIndicatorProps = PropsWithChildren & OverridableStyles;

export const Indicator = forwardRef<HTMLDivElement, CheckboxIndicatorProps>(
  (props, ref) => {
    const { children, ...otherProps } = props;
    return (
      <CheckboxPrimitive.Indicator ref={ref} asChild {...otherProps}>
        {children}
      </CheckboxPrimitive.Indicator>
    );
  }
);

Indicator.displayName = 'CheckboxIndicator';
export type CheckedState = CheckboxPrimitive.CheckedState;
