import { useQuery } from '@tanstack/react-query';
import { api } from '@studio/lib';
import { UserProfile } from '@studio/types';
import { USE_QUERY_KEYS } from './constants';

export function useProfileQuery() {
  return useQuery({
    queryKey: [USE_QUERY_KEYS.PROFILE_QUERY_KEY],
    queryFn: async () => api.bowser.get<UserProfile>('/api/users/profile'),
  });
}
