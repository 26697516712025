import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function PowerKeywordsExplosiveIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="48"
      height="64"
      viewBox="0 0 48 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7819 4.17732C15.7819 6.00707 15.234 6.6261 14.558 6.6261C13.882 6.6261 13.334 6.00707 13.334 4.17732C13.334 2.34758 14.3697 0 14.558 0C14.7463 0 15.7819 2.34758 15.7819 4.17732ZM39.4552 19.7259C39.3229 18.584 39.1513 17.102 40.5787 19.254L40.579 19.2561C53.6238 43.859 43.1811 59.1099 29.6575 62.6674C28.1641 63.3059 26.5771 63.7185 24.964 63.8975C24.4556 63.9778 23.9398 64.0113 23.4252 63.9971C20.7174 64.0476 17.9964 63.4409 15.5754 62.1412C8.12792 59.5128 1.90151 52.9416 0.515193 42.0123C0.0444206 37.6655 0.964355 33.297 3.14229 29.4416C5.11694 25.9407 8.59645 20.6374 13.9831 15.2883C17.1676 12.0268 19.6377 7.6584 19.2562 3.40807C19.0399 2.40056 20.2952 1.69665 21.0528 2.33876C21.6588 2.80577 34.54 13.3269 33.1493 27.7464C34.0264 26.803 34.9655 25.9633 35.8448 25.177C37.9352 23.3076 39.6875 21.7406 39.4642 19.8039L39.4552 19.7259ZM37.6914 11.0556C38.4179 13.7594 37.8097 14.9024 36.7562 15.1839C35.7028 15.4654 34.6031 14.7788 33.8766 12.075C33.1501 9.37111 33.8319 5.47078 34.1254 5.39237C34.4188 5.31396 36.9649 8.35174 37.6914 11.0556ZM2.10514 20.2257C3.04962 20.7695 4.29009 20.3902 5.69356 17.966C7.09703 15.5418 7.45066 11.5984 7.18756 11.4469C6.92446 11.2954 3.67677 13.5725 2.2733 15.9967C0.869825 18.4209 1.16066 19.6819 2.10514 20.2257ZM46.3809 24.4119C47.0569 24.4119 47.6049 23.7929 47.6049 21.9632C47.6049 20.1334 46.5692 17.7858 46.3809 17.7858C46.1926 17.7858 45.157 20.1334 45.157 21.9632C45.157 23.7929 45.705 24.4119 46.3809 24.4119Z"
        fill="url(#paint0_linear_2519_11404)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2519_11404"
          x1="24"
          y1="0"
          x2="24"
          y2="64.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D854EF" />
          <stop offset="0.49" stopColor="#FFA70B" />
          <stop offset="1" stopColor="#DBD623" />
        </linearGradient>
      </defs>
    </svg>
  );
}
