import { forwardRef, Ref } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import clsx from 'clsx';
import * as Styles from './toggle-group.css';

export type ToggleGroupItemProps = ToggleGroup.ToggleGroupItemProps & {
  id: string;
  label?: string;
};

export type ToggleGroupProps = {
  description?: string;
} & (
  | ({
      type: 'single';
      onValueChange?: (value: string) => void;
    } & Omit<ToggleGroup.ToggleGroupSingleProps, 'onValueChange'>)
  | ({
      type: 'multiple';
      onValueChange?: (value: string[]) => void;
    } & Omit<ToggleGroup.ToggleGroupMultipleProps, 'onValueChange'>)
);

type Props = ToggleGroupProps & Styles.ToggleGroupVariants;

const Root = forwardRef(
  (
    {
      className,
      description = 'Toggle option',
      disabled,
      pill = false,
      size = 'md',
      variant = 'secondary',
      direction = 'horizontal',
      ...props
    }: Props,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    return (
      <ToggleGroup.Root
        ref={ref}
        className={clsx(
          Styles.toggleGroupVariants({
            disabled,
            pill,
            size,
            variant,
            direction,
          }),
          className
        )}
        aria-label={description}
        {...props}
      />
    );
  }
);

Root.displayName = 'ToggleGroupRoot';

const Item = forwardRef(
  (
    { className, id, label, value, ...props }: ToggleGroupItemProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <ToggleGroup.Item
        ref={ref}
        aria-label={label || value}
        className={clsx(Styles.item, className)}
        id={id}
        value={value}
        {...props}
      />
    );
  }
);

Item.displayName = 'ToggleGroupItem';

export { Root, Item };
