import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

i18n.use(initReactI18next).init({
  // For now we will only be supporting EN
  // Read more: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
