import { useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NotFound } from '@studio/components';
import { useOrganizationStore } from '@studio/stores';

export function ValidOrganizationMatch(props: PropsWithChildren) {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { setActiveOrganizationId, isValidOrganization } =
    useOrganizationStore();

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    if (isValidOrganization(organizationId)) {
      setActiveOrganizationId(organizationId);
    }
  }, [organizationId]);

  if (organizationId && !isValidOrganization(organizationId)) {
    return (
      <NotFound
        status={t('Not Found')}
        message={t(
          'We could not find this organization. You can navigate to the home screen or logout. If the issue persists please contact customer service.'
        )}
      />
    );
  }

  return props.children;
}
