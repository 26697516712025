import { useTranslation } from 'react-i18next';

export const useCurrencyFormatter = () => {
  const { i18n } = useTranslation();

  const formatCurrency = (
    amountInCents: number,
    currency: string = 'USD'
  ): string => {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
    }).format(amountInCents / 100);
  };

  return { formatCurrency };
};
