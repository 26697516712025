import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function DragHorizontalIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        d="M15.1417 10.0008L12.5 12.6424L13.675 13.8174L17.5 10.0008L13.675 6.17578L12.5 7.35911L15.1417 10.0008ZM4.85833 10.0008L7.5 7.35911L6.325 6.18411L2.5 10.0008L6.325 13.8258L7.5 12.6424L4.85833 10.0008Z"
        fill="currentColor"
      />
    </svg>
  );
}
