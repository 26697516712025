import { PresignedS3UploadRes } from '@lib/types';

export const CHANNEL_ROLE = {
  USER: 'user',
  ADMIN: 'admin',
  NONE: 'none',
  REMOVE: 'remove',
  PRIMARY: 'primary',
} as const;

export type ChannelRole = (typeof CHANNEL_ROLE)[keyof typeof CHANNEL_ROLE];

export type UserEntitlement = {
  channelId: string;
  role: ChannelRole;
};
export type ManageUserInput = {
  organizationId: string;
  orgUserId: string;
  entitlements: UserEntitlement[];
};

export type CastMember = {
  id: string;
  name?: string;
  image?: string | null;
  isPrimary: boolean;
};

interface Page {
  current: number;
  next: number | null;
  previous: number | null;
  total: number;
}
export interface CastMembersResponseData {
  results: CastMember[];
  page: Page;
  totalRecords: number;
}

export type CreateCastMemberResponse = {
  upload: PresignedS3UploadRes;
  castMember: CastMember;
};
