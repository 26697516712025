import { UserProfileEntitlementRole, UserProfileOrganizationUserRole } from '@studio/types';

export const ADD_FAVORITE_CREATOR_MODAL = 'add-favorite-creator-modal';
export const INVITE_USER_MODAL = 'invite-user-modal';
export const REMOVE_USER_MODAL = 'remove-user-modal';
export const REMOVE_FAVORITE_CREATOR_MODAL = 'remove-favorite-creator-modal';
export const REVOKE_USER_MODAL = 'revoke-user-modal';
export const DISABLE_CAST_MEMBERS_MODAL = 'disable-cast-members-modal';
export const MANAGE_USER_MODAL = 'manage-user-modal';
export const DELETE_SAVED_ITEM_MODAL = 'delete-saved-item-modal';
export const DELETE_CAST_MEMBER_MODAL = 'delete-cast-member-modal';

export const FILTER_PARAMS = {
  SORT_BY: 'sortBy',
  CHANNELS: 'channels',
} as const;

export const PARAM_KEYS = {
  ...FILTER_PARAMS,
};

export const ROLES: Record<string, UserProfileOrganizationUserRole | UserProfileEntitlementRole> = {
  PRIMARY: 'primary',
  USER: 'user',
  ADMIN: 'admin',
};
