import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function FavoriteOnIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3168 21.5715C17.6425 21.7435 18.0233 21.4645 17.9609 21.0995L16.756 14.0072L21.8649 8.98742C22.1292 8.72835 21.9836 8.27735 21.6194 8.22384L14.5585 7.19387L11.3985 0.738139C11.2352 0.405646 10.7649 0.405646 10.6015 0.738139L7.43835 7.18957L0.380617 8.22376C0.0164365 8.27725 -0.129241 8.72826 0.135059 8.98734L5.24616 14.0071L4.03919 21.0995C3.97675 21.4645 4.35758 21.7434 4.68327 21.5714L11.0034 18.2213L17.3168 21.5715Z"
        fill="currentColor"
      />
    </svg>
  );
}
