export const authRoutes = [
  /**
   * Home
   */
  {
    path: '/home',
    async lazy() {
      const { Login } = await import('./routes');
      return {
        Component: Login,
      };
    },
  },

  /**
   * Sign Up
   */
  {
    path: '/auth/signup',
    async lazy() {
      const { Signup } = await import('./routes');
      return {
        Component: Signup,
      };
    },
  },

  /**
   * Direct to Payment
   */
  {
    path: '/auth/direct-to-payment',
    async lazy() {
      const { DirectToPayment } = await import('./routes');
      return {
        Component: DirectToPayment,
      };
    },
  },

  /**
   * Pricing Plans
   */
  {
    path: '/auth/pricing-plans',
    async lazy() {
      const { PricingPlans } = await import('@studio/features/auth');
      return {
        Component: PricingPlans,
      };
    },
  },

  /**
   * Auth Callback
   */
  {
    path: '/auth/callback',
    async lazy() {
      const { AuthCallback } = await import('./routes');
      return {
        Component: AuthCallback,
      };
    },
  },

  /**
   * Channel connect
   */
  {
    path: '/auth/connect-channel',
    async lazy() {
      const { ConnectChannel } = await import('./routes');
      return {
        Component: ConnectChannel,
      };
    },
  },

  /**
   * No videos
   */
  {
    path: '/auth/no-videos',
    async lazy() {
      const { NoVideos } = await import('./routes');
      return {
        Component: NoVideos,
      };
    },
  },

  /**
   * Channel Limit
   */
  {
    path: '/auth/channel-limit',
    async lazy() {
      const { ChannelLimit } = await import('./routes');
      return {
        Component: ChannelLimit,
      };
    },
  },

  /**
   * No Channels
   */
  {
    path: '/auth/no-channels',
    async lazy() {
      const { NoChannels } = await import('./routes');
      return {
        Component: NoChannels,
      };
    },
  },

  /**
   * No Long Form Videos
   */
  {
    path: '/auth/no-long-form-videos',
    async lazy() {
      const { NoLongFormVideos } = await import('./routes');
      return {
        Component: NoLongFormVideos,
      };
    },
  },

  /**
   * No Long Form Videos
   */
  {
    path: '/auth/new-channel-already-in-org',
    async lazy() {
      const { ChannelExistsInOrg } = await import('./routes');
      return {
        Component: ChannelExistsInOrg,
      };
    },
  },

  /**
   * Connect server error
   */
  {
    path: '/auth/connect-server-error',
    async lazy() {
      const { ServerError } = await import('./routes');
      return {
        Component: ServerError,
      };
    },
  },

  /**
   * Already authenticated
   */
  {
    path: '/auth/already-authenticated',
    async lazy() {
      const { AlreadyAuthenticated } = await import('./routes');
      return {
        Component: AlreadyAuthenticated,
      };
    },
  },

  /**
   * Channel connect
   */
  {
    path: '/auth/connect-callback',
    async lazy() {
      const { ConnectCallback } = await import('./routes');
      return {
        Component: ConnectCallback,
      };
    },
  },

  /**
   * Auth Error
   */
  {
    path: '/auth/error',
    async lazy() {
      const { AuthError } = await import('./routes');
      return {
        Component: AuthError,
      };
    },
  },

  /**
   * Server Error
   */
  {
    path: '/auth/server-error',
    async lazy() {
      const { ServerError } = await import('./routes');
      return {
        Component: ServerError,
      };
    },
  },

  /**
   * Need Access
   */
  {
    path: '/auth/need-access',
    async lazy() {
      const { NeedAccess } = await import('./routes');
      return {
        Component: NeedAccess,
      };
    },
  },

  /**
   * Need Access
   */
  {
    path: '/auth/unexpected-error',
    async lazy() {
      const { UnexpectedError } = await import('./routes');
      return {
        Component: UnexpectedError,
      };
    },
  },

  /**
   * Need Access
   */
  {
    path: '/auth/is-mobile',
    async lazy() {
      const { IsMobile } = await import('./routes');
      return {
        Component: IsMobile,
      };
    },
  },

  /**
   * Logout
   */
  {
    path: '/auth/logout',
    async lazy() {
      const { Logout } = await import('./routes');
      return {
        Component: Logout,
      };
    },
  },

  /**
   * Signup Callback
   */
  {
    path: '/auth/signup-callback',
    async lazy() {
      const { SignupCallback } = await import('./routes');
      return {
        Component: SignupCallback,
      };
    },
  },
];
