import TaskItem from '@tiptap/extension-task-item';
import {
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { Checkbox } from '@lib/ui';
import * as styles from './checkbox-list.css';

// A custom task item that uses the checkbox primitive
export const CheckboxList = TaskItem.extend({
  addNodeView: () => {
    return ReactNodeViewRenderer(TiptapCheckbox);
  },
});

function TiptapCheckbox({ node, updateAttributes }: NodeViewProps) {
  return (
    <NodeViewWrapper className={styles.tiptapTaskItem}>
      <label className={styles.tiptipTaskItemCheckbox} contentEditable={false}>
        <Checkbox.Root>
          <Checkbox.Input
            defaultChecked={false}
            checked={node.attrs.checked}
            onCheckedChange={(checked: boolean) =>
              updateAttributes({ checked })
            }
          />
        </Checkbox.Root>
      </label>
      <NodeViewContent className={styles.tiptipTaskItemContent} />
    </NodeViewWrapper>
  );
}
