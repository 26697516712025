import { Channel } from './channel';

export const USER_STATUS = {
  ACTIVE: 'active',
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  INVITATION_PENDING: 'invitation expired',
  REMOVED: 'removed',
  DELETED: 'deleted',
} as const;

export const USER_ROLE = {
  ADMIN: 'admin',
  NONE: 'none',
  OWNER: 'primary',
  USER: 'user',
} as const;

export type UserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE];

export type UserStatus = (typeof USER_STATUS)[keyof typeof USER_STATUS];

export type UserEntitlement = {
  channel: Channel;
  id: string;
  role: UserRole;
  source?: string;
};

export type User = {
  email: string;
  id: string;
  entitlement: UserEntitlement[];
  firstName: string | null;
  imgUrl: string | null;
  jobTitle: string | null;
  lastName: string | null;
  phoneNumber: string | null;
};
