import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleFilter } from '@studio/components';
import { ParamsObject, useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';
import { NumberParser } from '@lib/utils';
import { PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';

function serializeValue(value: number) {
  if (!value) {
    return '';
  }
  // prettier-ignore
  const n = Intl.NumberFormat('en-us', { maximumFractionDigits: 0 }).format(value);
  return n;
}

function deserializeValue(value: string) {
  if (!value) {
    return NaN;
  }
  const parser = new NumberParser('en');
  const s = parser.parse(value);
  return Number(s);
}

export function SubscribersFilterInput() {
  const { params, setParamsTracked, removeParamTracked } = useParamStore();
  const { t } = useTranslation();

  const handleSetParams = (value: ParamsObject) => {
    setParamsTracked(EVENTS.OUTLIERS_FILTER_SUBS_CHANGE, value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    for (const [key, value] of formData.entries()) {
      if (!value && !params[key]) {
        return;
      }
      setParamsTracked(EVENTS.OUTLIERS_FILTER_SUBS_CHANGE, {
        [key]: deserializeValue(value as string).toString(),
      });
    }
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(EVENTS.OUTLIERS_FILTER_SUBS_CHANGE, value);
  };

  return (
    <Form.Root onSubmit={handleSubmit}>
      <ToggleFilter
        min={0}
        minId={PARAM_KEYS.SUBSCRIBERS_GTE}
        minLabel={t('Minimum')}
        minPlaceholder={'0'}
        max={Infinity}
        maxId={PARAM_KEYS.SUBSCRIBERS_LTE}
        maxLabel={t('Maximum')}
        maxPlaceholder={'260,000,000'}
        params={params}
        setParams={handleSetParams}
        removeParam={removeAndTrackParams}
        serializeValue={serializeValue}
        deserializeValue={deserializeValue}
      />
      <Form.Submit />
    </Form.Root>
  );
}
