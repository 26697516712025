import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function TrophyIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <g clipPath="url(#clip0_2259_37297)">
        <path
          d="M2.84111 5.95912C2.15398 5.27557 1.74716 4.35986 1.70052 3.39176H2.66405V2.36235H1.26611C1.10721 2.36235 0.954826 2.42547 0.84247 2.53783C0.730113 2.65019 0.666992 2.80257 0.666992 2.96147V3.20235C0.668389 4.13792 0.935365 5.05386 1.43687 5.84366C1.93837 6.63346 2.6538 7.26465 3.49993 7.66382C3.19906 7.12975 2.97518 6.55584 2.83493 5.95912M12.7358 2.36235H11.3358V3.39176H12.3014C12.2547 4.35681 11.8503 5.26987 11.167 5.95294C11.0303 6.54923 10.8099 7.12316 10.5123 7.65765C11.3552 7.25715 12.0674 6.62616 12.5666 5.8377C13.0657 5.04924 13.3314 4.13554 13.3329 3.20235V2.96147C13.3331 2.88289 13.3179 2.80503 13.288 2.73235C13.2581 2.65968 13.2142 2.59362 13.1587 2.53795C13.1033 2.48229 13.0373 2.43813 12.9648 2.408C12.8922 2.37786 12.8144 2.36235 12.7358 2.36235ZM8.68405 11.4974L8.1364 10.3279L8.05817 10.1488C7.88523 10.2497 7.70817 10.3403 7.52493 10.4247C7.36032 10.5006 7.1812 10.5399 6.99993 10.5399C6.81866 10.5399 6.63955 10.5006 6.47493 10.4247C6.29328 10.3411 6.11599 10.2484 5.94376 10.1468L5.86346 10.3321L5.31582 11.5015L8.68405 11.4974ZM10.1541 12.6194V13.7518H3.81699V12.6194L4.52935 11.9091H9.44376C9.7217 12.185 9.87817 12.3415 10.1541 12.6194ZM11.1361 0.25H2.86376C2.67846 0.25 2.54464 0.517647 2.54464 0.783235C2.54464 1.09412 2.67846 1.36176 2.86376 1.36176H11.1361C11.3214 1.36176 11.4532 1.09412 11.4532 0.828529C11.4532 0.517647 11.3214 0.25 11.1361 0.25Z"
          fill="currentColor"
        />
        <path
          d="M3.07617 4.46237C3.07741 5.63674 3.41431 6.78628 4.04719 7.77554C4.68006 8.76479 5.58253 9.55252 6.64823 10.0459C6.75864 10.0967 6.87875 10.123 7.00029 10.123C7.12183 10.123 7.24194 10.0967 7.35235 10.0459C8.41618 9.55706 9.31775 8.77376 9.95041 7.78865C10.5831 6.80354 10.9203 5.65784 10.9223 4.48707V1.77148H3.07617V4.46237Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2259_37297">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
