import clsx from 'clsx';
import { vars } from '@lib/theme';
import { Flex, Grid } from '@lib/ui';
import * as Styles from './skeleton.css';

export function FavoriteCreatorProfileSkeleton() {
  return (
    <Grid className={Styles.skeletonRow}>
      <div className={clsx(Styles.skeletonPlaceholder, Styles.circle)}></div>
      <Flex
        justifyContent="flex-start"
        gap={vars.scales.s04}
        flexDirection="column"
      >
        <div className={clsx(Styles.skeletonPlaceholder, Styles.firstLine)} />
        <div className={clsx(Styles.skeletonPlaceholder, Styles.secondLine)} />
      </Flex>
    </Grid>
  );
}
