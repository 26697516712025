import React from 'react';

const CategoryList = ({ categories }: { categories: string[] }) => {
  if (categories.length === 0) {
    return '';
  }

  if (categories.length === 3) {
    return (
      <>
        <span>{categories[0]}</span>, <span>{categories[1]}</span>, and&nbsp;
        <span>{categories[2]}</span>.
      </>
    );
  }
  if (categories.length === 2) {
    return (
      <>
        <span>{categories[0]}</span> and <span>{categories[1]}</span>.
      </>
    );
  }
  return (
    <>
      <span>{categories[0]}</span>.
    </>
  );
};

// 'category1 ** category2' will return 'category2'
const getL2TopCategories = (topCategories: string[]) => {
  return topCategories.map((category) => {
    const parts = category.split(' ** ');
    return parts.length > 1 ? parts[1] : parts[0];
  });
};

export const getPowerKeywordTooltipText = (
  numCategories: number,
  topCategories: string[] = []
): React.ReactNode | null => {
  if (!numCategories || numCategories === 0) {
    return null;
  }

  const numCategoriesString = numCategories.toString();
  const l2TopCategories = getL2TopCategories(topCategories);

  if (topCategories.length === 0) {
    return <>overperformed in {numCategoriesString} categories.</>;
  }

  if (numCategories > 1) {
    return (
      <>
        overperformed in {numCategoriesString} categories, including&nbsp;
        <CategoryList categories={l2TopCategories} />
      </>
    );
  }

  return (
    <>
      overperformed in <CategoryList categories={l2TopCategories} />
    </>
  );
};
