import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function SearchIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0912 6.44552C12.0912 3.32748 9.56356 0.799805 6.44552 0.799805C3.32748 0.799805 0.799805 3.32748 0.799805 6.44552C0.799805 9.56356 3.32748 12.0912 6.44552 12.0912C7.84392 12.0912 9.12941 11.5788 10.1196 10.7363L10.3541 10.9708V11.6569L14.6969 15.9998L15.9998 14.6969L11.6569 10.3541H10.9708L10.7363 10.1196C11.5788 9.12941 12.0912 7.84392 12.0912 6.44552ZM2.53613 6.4447C2.53613 4.27328 4.27328 2.53613 6.4447 2.53613C8.61613 2.53613 10.3533 4.27328 10.3533 6.4447C10.3533 8.61613 8.61613 10.3533 6.4447 10.3533C4.27328 10.3533 2.53613 8.61613 2.53613 6.4447Z"
        fill="currentColor"
      />
    </svg>
  );
}
