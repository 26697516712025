import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Heading } from '../../';
import { container, content, iconContainer, wrapper } from './no-results.css';

/**
 * No Results
 */

export type IconProps = {
  className?: string;
  icon?: ReactNode;
};

export type TitleProps = {
  className?: string;
  text?: string;
};

export type ContainerProps = {
  className?: string;
  children?: ReactNode;
};

const Root = (props: ContainerProps) => {
  const { className, children } = props;
  return <span className={clsx(wrapper, className)}>{children}</span>;
};
Root.displayName = 'NoResultRoot';

const Icon = (props: ContainerProps) => {
  const { className, children } = props;
  if (!children) return null;
  return <span className={clsx(iconContainer, className)}>{children}</span>;
};
Icon.displayName = 'NoResultIcon';

const Title = (props: TitleProps) => {
  const { className, text } = props;
  const { t } = useTranslation();
  return (
    <Heading size="22" className={className}>
      {text ?? t('No results found')}
    </Heading>
  );
};
Title.displayName = 'NoResultTitle';

const Content = (props: ContainerProps) => {
  const { className, children } = props;
  return <div className={clsx(content, className)}>{children}</div>;
};
Content.displayName = 'NoResultContent';

const Actions = (props: ContainerProps) => {
  const { className, children } = props;
  return <div className={clsx(container, className)}>{children}</div>;
};
Actions.displayName = 'NoResultActions';

export { Root, Icon, Title, Content, Actions };
