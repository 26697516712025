import { useQuery } from '@tanstack/react-query';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { fetchStripePlanById } from '../requests';

export const useFetchStripePlanById = (planId: string) =>
  useQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_STRIPE_PLAN_BY_ID_KEY, planId],
    queryFn: () => {
      return fetchStripePlanById(planId);
    },
    refetchOnWindowFocus: false,
  });
