import { IconProps } from '../types';

export function SortDownIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M10.7217 13.75C10.4009 14.3056 9.59906 14.3056 9.27831 13.75L6.39156 8.75C6.07081 8.19445 6.47175 7.5 7.11325 7.5L12.8868 7.5C13.5283 7.5 13.9292 8.19445 13.6084 8.75L10.7217 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
