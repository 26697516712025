import { type ForwardedRef, forwardRef } from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import clsx from 'clsx';
import { Button, ButtonProps } from '../button';
import * as Styles from './toggle-button.css';

export type ToggleButtonProps = TogglePrimitive.ToggleProps & ButtonProps;

export const ToggleButton = forwardRef(
  (props: ToggleButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { className, value, ...rest } = props;
    return (
      <TogglePrimitive.Root asChild>
        <Button
          ref={ref}
          variant="subtle"
          fill="ghost"
          {...rest}
          value={value}
          className={clsx(Styles.root, className)}
        />
      </TogglePrimitive.Root>
    );
  }
);

ToggleButton.displayName = 'ToggleButton';
