/**
 * Escapes '<' and '>' characters in a string to prevent them
 * from being interpreted as HTML tags in React or browsers,
 * and capitalizes the first letter of the output.
 *
 * @param lemma - The input string potentially containing '<' or '>'.
 * @returns The escaped string with '<' replaced by '&lt;', '>' replaced by '&gt;', and the first letter capitalized.
 */
export function getLemmaLabel(lemma: string): string {
  // Replace all '<' with '&lt;' and all '>' with '&gt;'
  const escapedLemma = lemma.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Capitalize the first letter and concatenate with the rest of the string
  return escapedLemma.charAt(0).toUpperCase() + escapedLemma.slice(1);
}
