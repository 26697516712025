import { useTranslation } from 'react-i18next';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useParamStore } from '@studio/stores';
import { ToggleButton } from '@lib/ui';
import { FILTER_PARAMS, SOURCE, SOURCE_ITEMS } from '../../../constants';
import * as Styles from './source-filter-toggles.css';

export function SourceFilterToggles() {
  const { params, setParams } = useParamStore();
  const { t } = useTranslation();
  const currentSource = params[FILTER_PARAMS.SOURCE] || SOURCE.ALL;

  const handleClick = (value: string) => {
    setParams({
      [FILTER_PARAMS.SOURCE]: value,
    });
  };

  return (
    <RadioGroup.Root className={Styles.sourceButtons} value={currentSource}>
      {SOURCE_ITEMS.map((source) => (
        <RadioGroup.Item key={source.value} asChild value={source.value}>
          <ToggleButton
            variant="secondary"
            className={Styles.sourceButton}
            key={source.value}
            onClick={() => handleClick(source.value)}
          >
            {t(source.label)}
          </ToggleButton>
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
}
