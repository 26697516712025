import { ForwardedRef, forwardRef, type HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Icon from '../icons';
import * as Styles from './sorting-button.css';

export type SortingButtonProps = HTMLAttributes<HTMLButtonElement> & {
  buttonLabel: string;
  sortOrder?: string;
};

type Props = SortingButtonProps & Styles.SortingButtonVariants;

export const SortingButton = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      size = 'md',
      className,
      buttonLabel,
      sortOrder = '',
      ...rest
    } = props;

    const { t } = useTranslation();

    const isSorterActive = sortOrder === 'asc' || sortOrder === 'desc';

    return (
      <button
        ref={ref}
        type="button"
        className={clsx(
          Styles.sortingButtonVariants({ size }),
          isSorterActive && Styles.SelectedHeader,
          className
        )}
        {...rest}
      >
        <label>{buttonLabel}</label>
        {sortOrder === 'asc' ? (
          <Icon.SortUpIcon aria-label={t(`Sort ${buttonLabel} ascending`)} />
        ) : sortOrder === 'desc' ? (
          <Icon.SortDownIcon aria-label={t(`Sort ${buttonLabel} descending`)} />
        ) : null}
      </button>
    );
  }
);
