import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function EyeIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.35684 8.21493C1.31078 8.07676 1.31074 7.92716 1.35671 7.78895C2.28229 5.00648 4.907 3 8.00035 3C11.0923 3 13.716 5.00462 14.6427 7.78507C14.6887 7.92325 14.6888 8.07285 14.6428 8.21105C13.7172 10.9935 11.0925 13 7.99918 13C4.90727 13 2.28356 10.9954 1.35684 8.21493Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99981 8C9.99981 9.10457 9.10437 10 7.9998 10C6.89524 10 5.9998 9.10457 5.9998 8C5.9998 6.89543 6.89524 6 7.9998 6C9.10437 6 9.99981 6.89543 9.99981 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
