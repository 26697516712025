import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useAuthStore } from '@studio/features/auth';
import type {
  UserProfileEntitlement,
  UserProfileOrganizationUser,
} from '@studio/types';
import { ExtractState } from '@studio/types/zustand';

type OrganizationStore = {
  activeOrganizationId: string;
  organizations: UserProfileEntitlement[];
  organizationUsers: UserProfileOrganizationUser[];
  isSelected: (id: string) => boolean;
  setActiveOrganizationId: (id: string) => void;
  setOrganizations: (organizations: UserProfileEntitlement[]) => void;
  setOrganizationUsers: (
    organizationUsers: UserProfileOrganizationUser[]
  ) => void;
  clearOrganizations: () => void;
  getSpotterOrg: () => UserProfileOrganizationUser | undefined;
  getActiveOrganizationUser: () => UserProfileOrganizationUser | undefined;
  hasCastMembersDisabled: (channelUcid?: string) => boolean;
  hasCreatorDescriptions: (channelUcid?: string) => boolean;
  isSpotterOrgActive: () => boolean;
  isValidOrganization: (id: string) => boolean;
};

export const useOrganizationStore = create<OrganizationStore>()(
  devtools(
    persist(
      (set, get) => ({
        activeOrganizationId: '',
        organizations: [],
        organizationUsers: [],
        setActiveOrganizationId: (id) => {
          set({ activeOrganizationId: id });
        },
        setOrganizations: (organizations) => {
          set({ organizations });
        },
        setOrganizationUsers: (organizationUsers) => {
          set({ organizationUsers });
        },
        isSelected: (id: string) => {
          return id === get().activeOrganizationId;
        },
        getActiveOrganizationUser: () => {
          const activeOrgId = get().activeOrganizationId;
          return get().organizationUsers.find((orgUser) => {
            return orgUser.organization.id === activeOrgId;
          });
        },
        isSpotterOrgActive: () => {
          return !!get().getActiveOrganizationUser()?.organization
            .isSpotterOrganization;
        },
        getSpotterOrg: () => {
          return get().organizationUsers.find((org) => {
            return org.organization.isSpotterOrganization;
          });
        },
        isValidOrganization: (id) => {
          const user = useAuthStore.getState().user;

          if (!user) {
            return false;
          }

          /**
           * Need to check both `entitlements` and `organizationUsers` due to
           * internal users not having Studio entitlements in certain rare cases.
           * This can be due to stale data, or bugs in local setup.
           */
          const foundEntitlement = user.entitlements.some(
            (entitlement) => entitlement.organizationId === id
          );
          const foundOrganizationUser = user.organizationUsers.some(
            (organizationUser) => organizationUser.organizationId === id
          );
          const found = foundEntitlement || foundOrganizationUser;

          return !!found;
        },
        hasCreatorDescriptions: (channelUcid): boolean => {
          const activeOrganizationId = get().activeOrganizationId;
          const activeOrganization = get().organizations.find((org) => {
            const isOrg = org.organizationId === activeOrganizationId;
            const isChannel = org.channel.ucid === channelUcid;
            return isOrg && isChannel;
          });

          if (!activeOrganization) {
            return false;
          }

          const entitlement = activeOrganization.entitlementState;

          if (!entitlement) {
            return false;
          }
          const creatorDescriptions =
            entitlement.services?.kirby?.creatorDescriptions;
          if (creatorDescriptions) {
            return Object.values(creatorDescriptions).some((value) => value);
          }
          return false;
        },
        hasCastMembersDisabled: (channelUcid): boolean => {
          const activeOrganizationId = get().activeOrganizationId;
          const activeOrganization = get().organizations.find((org) => {
            const isOrg = org.organizationId === activeOrganizationId;
            const isChannel = org.channel.ucid === channelUcid;
            return isOrg && isChannel;
          });

          if (!activeOrganization) {
            return false;
          }

          const entitlement = activeOrganization.entitlementState;

          return (
            entitlement?.services?.kirby?.channelOrgPreferences
              ?.thumbnailOptOut ?? false
          );
        },
        clearOrganizations: () => {
          set({
            organizations: [] as UserProfileEntitlement[],
            organizationUsers: [] as UserProfileOrganizationUser[],
          });
        },
      }),
      {
        name: 'organizations',
      }
    )
  )
);

type Params<U> = Parameters<
  typeof useStoreWithEqualityFn<typeof useOrganizationStore, U>
>;

function useStoreHookFactory<U>(selector: Params<U>[1]) {
  return useStoreWithEqualityFn(useOrganizationStore, selector);
}

export const useActiveOrganizationId = () => {
  return useStoreHookFactory(
    (state: ExtractState<typeof useOrganizationStore>) =>
      state.activeOrganizationId
  );
};
