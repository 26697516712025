import { ProjectsSearchInput } from '../components';

export const projectsRoutes = [
  /**
   * Project listings
   */
  {
    path: ':organizationId/:channelUcid/projects',
    async lazy() {
      const { ProjectsRoute } = await import('./projects');
      return {
        Component: ProjectsRoute,
      };
    },
    handle: {
      searchComponent: <ProjectsSearchInput />,
    },
  },

  /**
   * New project
   */
  {
    path: ':organizationId/:channelUcid/projects/new-project',
    async lazy() {
      const { ProjectDetail } = await import('./project');
      return {
        Component: ProjectDetail,
      };
    },
  },

  /**
   * Project by id
   */
  {
    path: ':organizationId/:channelUcid/projects/:projectId',
    async lazy() {
      const { ProjectDetail } = await import('./project');
      return {
        Component: ProjectDetail,
      };
    },
  },
];
