import { Ref, forwardRef } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { Icons } from '@lib/ui';
import {
  accordionVariants,
  AccordionVariants,
  content,
  header,
  item,
  trigger,
} from './accordion.css';

/**
 * Accordion Root
 */
export type RootProps =
  | ({
      type: 'single';
      onValueChange?: (value: string) => void;
    } & Omit<Accordion.AccordionSingleProps, 'onValueChange'>)
  | ({
      type: 'multiple';
      onValueChange?: (value: string[]) => void;
    } & Omit<Accordion.AccordionMultipleProps, 'onValueChange'>);

const Root = forwardRef(
  (
    { children, className, variant, ...props }: RootProps & AccordionVariants,
    ref: Ref<HTMLDivElement>
  ) => (
    <Accordion.Root
      className={clsx(accordionVariants({ variant }), className)}
      ref={ref}
      {...props}
    >
      {children}
    </Accordion.Root>
  )
);

/**
 * Accordion Trigger
 */
export type TriggerProps = Accordion.AccordionTriggerProps;

const Trigger = forwardRef(
  (
    { className, children, ...props }: TriggerProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <Accordion.Trigger
        className={clsx(trigger, className)}
        {...props}
        ref={ref}
      >
        {children}
      </Accordion.Trigger>
    );
  }
);

/**
 * Accordion Header
 */
export type HeaderProps = Accordion.AccordionHeaderProps;

const Header = forwardRef(
  (
    { children, className, ...props }: HeaderProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <Accordion.Header className={clsx(header, className)} {...props} ref={ref}>
      {children}
    </Accordion.Header>
  )
);

/**
 * Accordion HeaderTrigger
 */
export type HeaderTriggerProps = HeaderProps & TriggerProps;

const HeaderTrigger = forwardRef(
  (
    { children, className, ...props }: HeaderTriggerProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <Header className={className}>
      <Trigger {...props} ref={ref}>
        {children}
        <Icons.ChevronDownIcon aria-hidden />
      </Trigger>
    </Header>
  )
);

/**
 * Accordion Item
 */
export type ItemProps = Accordion.AccordionItemProps;

const Item = forwardRef(
  ({ children, className, ...props }: ItemProps, ref: Ref<HTMLDivElement>) => (
    <Accordion.Item className={clsx(item, className)} {...props} ref={ref}>
      {children}
    </Accordion.Item>
  )
);

/**
 * Accordion Content
 */
export type ContentProps = Accordion.AccordionContentProps;

const Content = forwardRef(
  (
    { children, className, ...props }: ContentProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <Accordion.Content
      className={clsx(content, className)}
      {...props}
      ref={ref}
    >
      {children}
    </Accordion.Content>
  )
);

export { Root, Trigger, Content, Header, HeaderTrigger, Item };
