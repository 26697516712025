import { useTranslation } from 'react-i18next';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useParamStore } from '@studio/stores';
import { ToggleButton } from '@lib/ui';
import { CONTENT_ITEMS, FILTER_PARAMS } from '../../../constants';
import * as Styles from './source-filter-toggles.css';

export function MadeForKidsFilterToggles() {
  const { params, setParams } = useParamStore();
  const { t } = useTranslation();
  const currentParam = params[FILTER_PARAMS.MADE_FOR_KIDS];

  const handleClick = (value: string) => {
    setParams({
      [FILTER_PARAMS.MADE_FOR_KIDS]: value,
    });
  };

  return (
    <RadioGroup.Root className={Styles.sourceButtons} value={currentParam}>
      {CONTENT_ITEMS.map((content) => (
        <RadioGroup.Item key={content.value} asChild value={content.value}>
          <ToggleButton
            variant="secondary"
            key={content.value}
            onClick={() => handleClick(content.value)}
          >
            {t(content.label)}
          </ToggleButton>
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
}
