import { create } from 'zustand';

type ImageDetails = {
  image?: string | null;
  contentType?: string | null;
  filename?: string | null;
};

export type CreateCastMemberFormState = {
  firstName?: string | null;
  profilePicture?: string | null;
  imageDetails: ImageDetails | null;
};

export const CREATE_CAST_FIELDS: {
  [K in keyof CreateCastMemberFormState]: K;
} = {
  firstName: 'firstName',
  profilePicture: 'profilePicture',
  imageDetails: 'imageDetails',
};

const INITIAL_FORM_DATA: CreateCastMemberFormState = {
  firstName: '',
  profilePicture: null,
  imageDetails: null,
};

type FormStateActions = {
  setField: (
    name: keyof CreateCastMemberFormState,
    value: string | null | undefined | ImageDetails
  ) => void;
  getField: (
    name: keyof CreateCastMemberFormState
  ) => string | null | undefined | ImageDetails;
  reset: () => void;
  isFormValid: () => boolean;
  getAllFields: () => CreateCastMemberFormState;
};

export const useCreateCastMemberStore = create<
  CreateCastMemberFormState & FormStateActions
>((set, get) => ({
  ...INITIAL_FORM_DATA,
  setField: (name, value) => set({ [name as string]: value }),
  getField: (name) => get()[name],
  reset: () => {
    set({
      ...INITIAL_FORM_DATA,
    });
  },
  isFormValid: () => {
    const isImageDetailsAvailable = !!get().imageDetails;
    return isImageDetailsAvailable;
  },
  getAllFields: () => {
    return get();
  },
}));
