import {
  ChangeEvent,
  forwardRef,
  type ForwardedRef,
  ReactNode,
  TextareaHTMLAttributes,
  useRef,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { HEAP_REPLAY } from '../../constants';
import { DragBothIcon, DragHorizontalIcon, DragVerticalIcon } from '../icons';
import * as Styles from './text-area.css';

export type TextAreaProps = Styles.TextAreaVariants &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    resize: 'none' | 'vertical' | 'horizontal' | 'both';
    /**
     * The preventEnter is a tag to remove the behavior of new line from enter or return key
     */
    preventNewLine?: boolean;
    /**
     * Puts the input itself into an "errored" visual state
     */
    hasError?: boolean;
  };

const TextArea = forwardRef(
  (props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement | null>) => {
    const {
      autoHeight,
      border = 'none',
      bottomLeftCorner,
      bottomRightCorner,
      className,
      fill = 'solid',
      hasError,
      radii = 'sm',
      resize,
      size = 'md',
      style,
      topLeftCorner,
      topRightCorner,
      variant = 'dark',
      preventNewLine,
      ...textAreaProps
    } = props;

    const containerRef = useRef<HTMLDivElement>(null);

    const dragIcon: Record<string, ReactNode> = {
      both: <DragBothIcon className={Styles.dragIcon} />,
      horizontal: <DragHorizontalIcon className={Styles.dragIcon} />,
      vertical: <DragVerticalIcon className={Styles.dragIcon} />,
    };

    const handleAutoHeight = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (containerRef?.current) {
        containerRef.current.dataset.replicatedValue = event.target.value;
      }
    };

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;

      if (!preventNewLine) {
        textAreaProps?.onChange?.(event);
        return;
      }

      const sanitizedValue = inputValue.replace(/[\r\n\v]+/g, '');

      if (inputValue === sanitizedValue) {
        textAreaProps?.onChange?.(event);
        return;
      }

      if (containerRef?.current) {
        containerRef.current.dataset.replicatedValue = sanitizedValue;
      }
    };

    useEffect(() => {
      if (autoHeight && containerRef?.current) {
        containerRef.current.dataset.replicatedValue =
          textAreaProps.value?.toString();
      }
    }, [containerRef, autoHeight, textAreaProps?.value]);

    return (
      <div
        ref={containerRef}
        className={clsx(
          Styles.root,
          Styles.textAreaVariants({
            autoHeight,
            border,
            bottomLeftCorner,
            bottomRightCorner,
            topLeftCorner,
            topRightCorner,
            error: hasError,
            fill,
            radii,
            size,
            variant,
          }),
          `resize-${resize}`,
          className
        )}
        style={style}
      >
        <textarea
          className={clsx(Styles.textArea, HEAP_REPLAY)}
          ref={ref}
          onInput={autoHeight ? handleAutoHeight : undefined}
          {...textAreaProps}
          onChange={handleOnChange}
        />
        {dragIcon[resize] || null}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export { TextArea };
