export const HOME_CLICK = 'home_click';
export const HOME_BRAINSTORM_KEYWORDS_CHANGE = 'home_brainstorm_keywords_change';
export const HOME_BRAINSTORM_CLICK = 'home_brainstorm_click';
export const HOME_NEW_PROJECT_CLICK = 'home_new_project_click';
export const HOME_OUTLIERS_SEE_MORE_CLICK = 'home_outliers_see_more_click';
export const HOME_PROFILE_DROPDOWN_CLICK = 'home_profile_dropdown_click';
export const HOME_OUTLIER_BRAINSTORM_CLICK = 'home_outlier_brainstorm_click';
export const HOME_DROPDOWN_SWITCH_CHANNEL_CLICK = 'home_dropdown_switch_channel_click';
export const HOME_DROPDOWN_ADD_CHANNEL_CLICK = 'home_dropdown_add_channel_click';
export const HOME_SWITCH_CHANNEL_CONTINUE_CLICK = 'home_switch_channel_continue_click';
