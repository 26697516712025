import { StatsigClient, StatsigOptions } from '@statsig/js-client';
import { getStatsigEnv } from './get-statsig-env';

let isInitialized = false;
let client: StatsigClient | null = null;

export async function getStatsigClient() {
  if (!isInitialized) {
    if (!client) {
      client = new StatsigClient(
        import.meta.env.VITE_STATSIG_STUDIO_CLIENT_KEY,
        {}, // Empty user object at first
        { environment: getStatsigEnv() } as StatsigOptions
      );
      await client.initializeAsync();

      isInitialized = true;
      return client;
    }
    return null;
  }
  return client;
}
