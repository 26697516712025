import { ForwardedRef, forwardRef } from 'react';
import * as ToggleGroupPrimitives from '@radix-ui/react-toggle-group';
import clsx from 'clsx';
import * as Styles from './filter-chips.css';

/**
 * Filter Chips Root
 */
export const Root = forwardRef(
  (
    props: ToggleGroupPrimitives.ToggleGroupSingleProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <ToggleGroupPrimitives.Root
        ref={ref}
        {...props}
        className={clsx(Styles.root, props.className)}
      />
    );
  }
);
Root.displayName = 'FilterChipsRoot';

/**
 * Filter Chips Item
 */
export const Item = forwardRef(
  (
    props: ToggleGroupPrimitives.ToggleGroupItemProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <ToggleGroupPrimitives.Item
        ref={ref}
        {...props}
        className={clsx(Styles.item, props.className)}
      />
    );
  }
);
Item.displayName = 'FilterChipsItem';
