import React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import clsx from 'clsx';
import * as Styles from './tabs.css';

export type TabsProps = TabsPrimitive.TabsProps & {
  children: React.ReactNode;
  className?: string;
};

const Root = (props: TabsProps) => {
  return (
    <TabsPrimitive.Root
      className={clsx(Styles.root, props.className)}
      {...props}
    />
  );
};
Root.displayName = 'TabsRoot';

const List = (props: TabsPrimitive.TabsListProps) => {
  return (
    <TabsPrimitive.List
      {...props}
      className={clsx(Styles.list, props.className)}
    />
  );
};
List.displayName = 'TabsList';

const Trigger = (props: TabsPrimitive.TabsTriggerProps) => {
  return (
    <TabsPrimitive.Trigger
      {...props}
      className={clsx(Styles.trigger, props.className)}
    />
  );
};
Trigger.displayName = 'TabsTrigger';

const Content = (props: TabsPrimitive.TabsContentProps) => {
  return (
    <TabsPrimitive.Content
      {...props}
      className={clsx(Styles.content, props.className)}
    />
  );
};
Content.displayName = 'TabsContent';

export { Root, List, Trigger, Content };
