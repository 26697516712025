import { io } from 'socket.io-client';

const CLIENT_URL = `${import.meta.env.VITE_BIRDO_URL}/user/notifications`;

const birdoClient = () => {
  return io(CLIENT_URL, {
    transports: ['websocket'],
    withCredentials: true,
  });
};

export default birdoClient();
