import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ChevronLeftIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6061 1.20503C11.8795 1.47839 11.8795 1.92161 11.6061 2.19497L5.80106 8L11.6061 13.805C11.8795 14.0784 11.8795 14.5216 11.6061 14.795C11.3327 15.0683 10.8895 15.0683 10.6161 14.795L4.31614 8.49497C4.04277 8.22161 4.04277 7.77839 4.31614 7.50502L10.6161 1.20503C10.8895 0.931658 11.3327 0.931658 11.6061 1.20503Z"
        fill="currentColor"
      />
    </svg>
  );
}
