import { ForwardedRef, forwardRef } from 'react';
import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { IconButton, Icons } from '../../';
import { closeButton, content, trigger } from './popover.css';

/**
 * Popover Trigger
 */
const Trigger = forwardRef(
  (
    props: Popover.PopoverTriggerProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Popover.Trigger
        ref={ref}
        {...props}
        className={clsx(trigger, props.className)}
      />
    );
  }
);
Trigger.displayName = 'PopoverTrigger';

/**
 * Popover Content
 */
const Content = forwardRef(
  (props: Popover.PopoverContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Popover.Content
        ref={ref}
        sideOffset={8}
        {...props}
        className={clsx(content, props.className)}
      />
    );
  }
);
Content.displayName = 'PopoverContent';

/**
 * Popover Close Button
 * NOTE: If UX requires more than one style of close button we will need to resist the assumptions made in this component
 */
const Close = forwardRef(
  (props: Popover.PopoverCloseProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Popover.Close ref={ref} {...props} asChild>
        <IconButton
          size="xs"
          label="Close"
          icon={<Icons.CloseIcon aria-hidden />}
          className={clsx(closeButton, props.className)}
        />
      </Popover.Close>
    );
  }
);
Close.displayName = 'PopoverClose';

const Arrow = Popover.Arrow;
const Anchor = Popover.Anchor;
const Root = Popover.Root;
const Portal = Popover.Portal;

export { Root, Trigger, Arrow, Close, Content, Portal, Anchor };
