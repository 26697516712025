import React, { useState, useEffect, useId } from 'react';
import * as Styles from './ideation-element.css';

export type IdeationSpiderThreadProps = {
  open: boolean;
  itemRef?: React.RefObject<HTMLElement>;
  actionRef?: React.RefObject<HTMLElement>;
  containerRef?: React.RefObject<HTMLElement>;
};

type Props = IdeationSpiderThreadProps;

export function IdeationSpiderThread({ ...props }: Props) {
  const { itemRef, actionRef, containerRef, open } = props;

  const [svgWidth, setSvgWidth] = useState<number>(0);
  const [svgHeight, setSvgHeight] = useState<number>(0);
  const [svgTop, setSvgTop] = useState<string>('0');
  const [pathData, setPathData] = useState<string>('');
  const [offset, setOffset] = useState<number>(350);
  const id = useId();

  const hasAllRequiredRefs = itemRef && actionRef;

  // Calculate the size of the SVG so the height encapsulates both item and action
  useEffect(() => {
    if (!hasAllRequiredRefs) {
      return;
    }

    const updatePathData = () => {
      // Get all elements and their sizes and positions
      const itemElement = itemRef.current;
      const optionElement = actionRef.current;

      const hasAllRequiredElements = itemElement && optionElement;
      if (!hasAllRequiredElements) {
        return;
      }

      const itemRect = itemElement.getBoundingClientRect();
      const optionRect = optionElement.getBoundingClientRect();

      // Figure out if our anchor is above or below the option, that way we can make various decisions
      // around where the Y position needs to be.
      // NOTE: We are checking if the middle of the the option and the item
      // is above one another.
      let isItemAboveOption = false;
      if (itemRect.y + itemRect.height < optionRect.y + optionRect.height / 2) {
        isItemAboveOption = true;
      }

      // Align the SVG so that the space of the SVG covers both the thread and the option
      let newSvgTop = '';
      if (!isItemAboveOption) {
        // If the option is ABOVE the item, do Nothing
        newSvgTop = '0px';
      }

      // Calculate how large the SVG needs to be to cover the distance between the anchor and option button
      const newSvgWidth = Math.abs(
        optionRect.x - (itemRect.x + itemRect.width)
      );
      // Default case is the item is above the option
      let newSvgHeight =
        itemRect.height +
        optionRect.height +
        (optionRect.y - itemRect.y - itemRect.height);

      if (!isItemAboveOption) {
        newSvgHeight =
          itemRect.height +
          optionRect.height +
          (itemRect.y - optionRect.y - optionRect.height);
      }

      const isLandscape = newSvgHeight <= newSvgWidth;

      let pathRadii = newSvgWidth / 2;
      if (isLandscape) {
        pathRadii = newSvgHeight / 2;
      }

      // Calculate the path of the line from the the anchor to the option
      const lineStartX = 0;
      const lineEndX = newSvgWidth;

      // Default Case: Item is above the option
      let lineStartY = itemRect.height / 2;
      let lineEndY = newSvgHeight - optionRect.height / 2;
      if (!isItemAboveOption) {
        lineStartY = newSvgHeight - itemRect.height / 2;
        lineEndY = optionRect.height / 2;
      }

      let midPoint1Y = lineStartY + pathRadii;
      if (!isItemAboveOption) {
        midPoint1Y = lineStartY - pathRadii;
      }

      let midPoint2Y = lineEndY - pathRadii;
      if (!isItemAboveOption) {
        midPoint2Y = lineEndY + pathRadii;
      }

      let newPathData = `M${lineStartX},${lineStartY}
        Q${pathRadii},${lineStartY}  ${pathRadii},${midPoint1Y}
        V${midPoint2Y}
        Q${pathRadii},${lineEndY} ${lineEndX},${lineEndY}
      `;

      if (Math.abs(lineStartY - lineEndY) < 2) {
        newPathData = `M${lineStartX},${lineStartY} L ${lineEndX},${lineEndY}`;
      }

      setSvgTop(newSvgTop);
      setSvgWidth(newSvgWidth);
      setSvgHeight(newSvgHeight);
      setPathData(newPathData);
    };

    updatePathData();
    // Listen for window resize to adjust line if necessary
    window.addEventListener('resize', updatePathData);
    const observer = new ResizeObserver(updatePathData);
    const container = containerRef?.current; // best practice is to save refs to variables for clean up
    if (container) {
      observer.observe(container);
    }
    return () => {
      window.removeEventListener('resize', updatePathData);
      if (container) {
        observer.unobserve(container);
      } else {
        observer.disconnect();
      }
    };
  }, [actionRef, hasAllRequiredRefs, itemRef]);

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        setOffset(0);
      } else {
        setOffset(350);
      }
    }, 10);
  }, [open]);

  const hasRequiredState = pathData && svgWidth && svgHeight;
  if (!hasAllRequiredRefs || !hasRequiredState) {
    return;
  }

  return (
    <svg
      aria-hidden
      className={Styles.webThread}
      style={{
        width: svgWidth,
        height: svgHeight,
        top: svgTop,
        bottom: '0px',
      }}
    >
      <defs>
        <linearGradient id={id} gradientUnits="userSpaceOnUse">
          <stop offset="5%" stopColor="#FFC600" />
          <stop offset="60%" stopColor="#616161" />
        </linearGradient>
      </defs>
      <path
        d={pathData}
        stroke={`url(#${id})`}
        strokeWidth="1"
        className={Styles.threadPath}
        style={{
          strokeDashoffset: offset,
        }}
        fill={'transparent'}
      />
    </svg>
  );
}
