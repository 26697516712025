import { Video } from '@lib/gql/graphql';

export enum SavedItemsType {
  TITLE = 'title',
  CONCEPT = 'concept',
  THUMBNAIL = 'thumbnail',
  OUTLIERS = 'outliers',
}

export type ConceptSavedItem = {
  id: string;
  assetId: string;
  type: SavedItemsType.CONCEPT;
  concept: string;
};

export type TitleSavedItem = {
  id: string;
  assetId: string;
  type: SavedItemsType.TITLE;
  title: string;
};

export type ThumbnailSavedItem = {
  id: string;
  assetId: string;
  type: SavedItemsType.THUMBNAIL;
  thumbnail: string;
  description?: string;
};

export type VideoSavedItem = {
  id: string;
  type: SavedItemsType.OUTLIERS;
  outliersVideo: Video;
  outliersVideoId: string;
};

export type NewConceptSavedItem = Omit<ConceptSavedItem, 'id'>;
export type NewTitleSavedItem = Omit<TitleSavedItem, 'id'>;
export type NewThumbnailSavedItem = Omit<ThumbnailSavedItem, 'id'>;
export type NewVideoSavedItem = Omit<VideoSavedItem, 'id' | 'outliersVideoId'>;

export type BackupSavedItem =
  | ConceptSavedItem
  | TitleSavedItem
  | ThumbnailSavedItem;

export type SavedItem =
  | ConceptSavedItem
  | TitleSavedItem
  | ThumbnailSavedItem
  | VideoSavedItem;

export type NewSavedItem =
  | NewConceptSavedItem
  | NewTitleSavedItem
  | NewThumbnailSavedItem
  | NewVideoSavedItem;

export type NewBackupSavedItem =
  | NewConceptSavedItem
  | NewTitleSavedItem
  | NewThumbnailSavedItem;

export interface SavedItemInput<T = NewSavedItem> {
  channelUcid: string;
  orgId: string;
  data: T;
}

export interface SavedItemsPage {
  current: number;
  next: number | null;
  previous: number | null;
  total: number;
}

export interface SavedItemsResponseData<T = SavedItem> {
  results: T[];
  page: SavedItemsPage;
  totalRecords: number;
}
