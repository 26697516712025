import { useCallback, useEffect, useState } from 'react';
import { StatsigClient, StatsigUser } from '@statsig/js-client';
import { getStatsigClient } from '../get-statsig-client';

export function useStatsigClient({
  initialUser,
}: {
  initialUser?: StatsigUser;
} = {}): { isLoading: boolean; client: StatsigClient | null; error: unknown } {
  const [client, setClient] = useState<StatsigClient | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);

  const getClientAsync = useCallback(
    async function getClientAsync() {
      try {
        const client = await getStatsigClient();
        if (initialUser) {
          await client?.updateUserAsync(initialUser);
        }
        setClient(client);
        setIsLoading(false);
      } catch (error: unknown) {
        console.error(error);
        setError(error);
      }
    },
    [initialUser]
  );

  useEffect(() => {
    getClientAsync();
  }, [getClientAsync]);

  return { client, isLoading, error };
}
