/**
 * Pulled from https://observablehq.com/@mbostock/localized-number-parsing
 * Light formatting applied to better adhere to our style guide, the functionality remains untouched.
 */
// prettier-ignore
export class NumberParser {
  group
  decimal
  numeral
  index

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.group = new RegExp(`[${parts.find(d => d.type === "group")!.value}]`, "g");
    this.decimal = new RegExp(`[${parts.find(d => d.type === "decimal")!.value}]`);
    this.numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this.index = (d: unknown) => index.get(d as string);
  }
  parse(string: string) {
    // eslint-disable-next-line no-cond-assign
    return (string = string.trim()
      .replace(this.group, "")
      .replace(this.decimal, ".")
      // @ts-expect-error If you can get this working with TypeScript be my guest.
      .replace(this.numeral, this.index)) ? +string : NaN;
  }
}
