import React, { ReactNode } from 'react';

type Params = {
  children: ReactNode;
  separator: ReactNode;
};

export const useBreadcrumbs = ({
  children,
  separator,
}: Params): ReactNode[] => {
  return React.Children.toArray(children).reduce<ReactNode[]>(
    (acc, child, index, array) => {
      acc.push(child);
      if (index < array.length - 1) {
        acc.push(<span key={index}>{separator}</span>);
      }
      return acc;
    },
    []
  );
};
