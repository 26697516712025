import { useEffect, useRef, type RefObject } from 'react';

type PrunedIntersectionObserverOptions = Pick<
  IntersectionObserverInit,
  'root' | 'rootMargin' | 'threshold'
>;

export function useIsOnScreen(
  ref: RefObject<HTMLElement>,
  callback: () => void,
  options?: PrunedIntersectionObserverOptions
) {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          callback();
        }
      },
      { ...options }
    );

    observerRef.current = observer;

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      // Cleanup the observer
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [ref, callback, options]);
}
