import { Icons, PowerKeywordTooltipVariant } from '@lib/ui';

export const getTooltipIcon = (variant: PowerKeywordTooltipVariant) => {
  switch (variant) {
    case PowerKeywordTooltipVariant.SUPER:
      return <Icons.PowerKeywordsSuperIcon />;
    case PowerKeywordTooltipVariant.EXPLOSIVE:
      return <Icons.PowerKeywordsExplosiveIcon />;
    case PowerKeywordTooltipVariant.STRONG:
    default:
      return <Icons.PowerKeywordsStrongIcon />;
  }
};
