import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '@studio/features/auth';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useActiveOrganizationId } from '@studio/stores';
import { UserProfile, UserProfileEntitlementRole } from '@studio/types';

interface ChannelRoleRouteGuardProps {
  allowedRoles: UserProfileEntitlementRole[];
  redirectRoute: string;
}

export function isAllowableByEntitlement(
  allowedRoles: UserProfileEntitlementRole[],
  user: UserProfile | null,
  activeOrganizationId: string,
  activeChannelUcid: string
) {
  let isAllowed = false;

  const entitlement = user?.entitlements.find((entitlement) => {
    return (
      entitlement.organizationId === activeOrganizationId &&
      entitlement.channel.ucid === activeChannelUcid
    );
  });

  if (entitlement) {
    const hasAllowedRoles = allowedRoles.includes(
      entitlement.role as UserProfileEntitlementRole
    );
    if (hasAllowedRoles) {
      isAllowed = true;
    }
  }

  return isAllowed;
}

export const ChannelRoleRouteGuard = ({
  allowedRoles,
  redirectRoute,
}: ChannelRoleRouteGuardProps) => {
  const activeChannelUcid = useActiveChannelUcid();
  const activeOrganizationId = useActiveOrganizationId();
  const { user } = useAuthStore();

  return isAllowableByEntitlement(
    allowedRoles,
    user,
    activeOrganizationId,
    activeChannelUcid
  ) ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};
