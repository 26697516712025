import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { IconButton } from '@lib/ui';
import * as styles from './toolbar.css';

type IconButtonProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataActive?: boolean;
  isActive?: boolean;
  icon?: ReactNode;
  label?: string;
};

export const ToolbarIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { onClick, disabled, dataActive, label = '', icon, isActive, className },
    ref
  ) => {
    return (
      <IconButton
        iconSize="12"
        size="xs"
        fill={isActive ? 'solid' : undefined}
        variant="dark"
        className={clsx(styles.toolbarButton, className)}
        onClick={onClick}
        disabled={disabled}
        data-active={dataActive ? 'true' : undefined}
        label={label}
        ref={ref}
        icon={icon}
      />
    );
  }
);

export default ToolbarIconButton;
