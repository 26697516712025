export const calendarRoutes = [
  /**
   * Calendar
   */
  {
    path: ':organizationId/:channelUcid/calendar',
    async lazy() {
      const { EventCalendar } = await import('./event-calendar');
      return {
        Component: EventCalendar,
      };
    },
  },
];
