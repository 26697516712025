import { useAuthStore } from '@studio/features/auth';
import { useChannelStore } from '@studio/features/channel-select';
import { createStudioKirbyConfig, StudioKirbyConfig } from '@studio/lib';
import {
  type Prompt,
  type PromptType,
  useKirbyPrompt,
} from '@lib/kirby-client';

type StudioPrompt = Omit<Prompt, 'source' | 'username' | 'channel' | 'origin'>;

/**
 * @example
 * ```react
 * const MyComponent = () => {
 *   const { send, data, isLoading, error } = usePrompt('title', {
 data: {
  settings: {
    inspirationSliderValue: 0.5,
  }
 },
 variant: 'emptyState',
 eventName: 'Studio Project Detail',
 *   })

 if (error) {
 return <div>Error!</div>
 }

 return isLoading ? (
 <div>loading results...</div>
 .   ) : (
 <main>
 <div onClick={send}>send it</div>
 <ul>{data?.elements.map(item => <li>{item.result}</li>)}</ul>
 </main>
 )
 * }
 * ```
 */
export const usePrompt = (
  type: PromptType,
  { data, variant, eventName }: StudioPrompt,
  studioConfig?: StudioKirbyConfig
) => {
  const { activeChannelUcid } = useChannelStore();
  const { user } = useAuthStore();

  if (!user) throw new Error('User not properly logged in.');

  const config = createStudioKirbyConfig(studioConfig ?? {});

  return useKirbyPrompt(
    type,
    {
      data,
      variant,
      username: user.email,
      channel: activeChannelUcid,
      eventName,
      source: 'studio',
    },
    config
  );
};
