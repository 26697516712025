import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { setSentryUser } from '@studio/sentry';
import { UserProfile } from '@studio/types';

interface AuthStore {
  loading: boolean;
  user: UserProfile | null;
  isInternalUser: () => boolean;
  setUser: (user: UserProfile) => void;
  clearUser: () => void;
  handleLogoutRedirect: (returnTo?: string) => void;
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set, get) => ({
        user: null,
        loading: true,
        organization: null,

        /**
         * Synchronously sets a user
         */
        setUser: (user) => {
          set({ user, loading: false });
          setSentryUser(user);
        },

        /**
         * Returns if the set user has a Spotter email address
         */
        isInternalUser: () => {
          const user = get().user;

          if (!user) return false;

          // Ensures users can't sign up with `spotter.la@gmail.com` or something similar
          const userEmailDomain = user.email.split('@')[1];
          const allowedInternalDomains = ['spotter.la', 'spotter.com'];
          const hasSpotterEmail = allowedInternalDomains.some(
            (domain) => userEmailDomain === domain
          );

          return hasSpotterEmail;
        },

        handleLogoutRedirect: (returnTo?: string) => {
          const returnToValue = returnTo ?? window.location.origin;
          // prettier-ignore
          window.location.href = `${
            import.meta.env.VITE_BOWSER_URL
          }/api/auth0/logout-redirect/?returnTo=${returnToValue}`;
        },

        clearUser: () => {
          set({ user: {} as UserProfile, loading: false });
          setSentryUser(null);
        },
      }),
      {
        name: 'user',
        partialize: (state) => ({
          user: state.user,
        }),
      }
    )
  )
);
