import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ChevronRightIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31462 14.7935C4.04209 14.5193 4.04345 14.076 4.31765 13.8035L10.1959 7.96111L4.31765 2.11871C4.04345 1.84618 4.04209 1.40296 4.31462 1.12876C4.58715 0.854562 5.03037 0.853207 5.30457 1.12574L11.6823 7.46463C11.8145 7.59602 11.8889 7.77472 11.8889 7.96111C11.8889 8.1475 11.8145 8.3262 11.6823 8.4576L5.30457 14.7965C5.03037 15.069 4.58715 15.0677 4.31462 14.7935Z"
        fill="currentColor"
      />
    </svg>
  );
}
