/**
 * Generates a string containing the first letters of the provided first and last names.
 * If neither first name nor last name is provided, returns empty string.
 *
 * @param {string | undefined} [firstName] - Optional. The first name of the person.
 * @param {string | undefined} [lastName] - Optional. The last name of the person.
 * @returns {string} The concatenated first letters of the provided names or empty string if no names are provided.
 */
export const getInitials = (firstName?: string, lastName?: string): string => {
  let initials = '';
  if (firstName) {
    initials += firstName[0];
  }
  if (lastName) {
    initials += lastName[0];
  }
  return initials;
};
