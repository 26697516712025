export const DEFAULT_PAGE_SIZE = '24';
export const DEFAULT_PERFORMANCE_INDEX_GTE = '1.1';
export const DEFAULT_MADE_FOR_KIDS = 'false';
export const DISPLAY_FORMAT = 'M/dd/yyyy';
export const MIN_LABEL = 'Min';
export const MAX_LABEL = 'Max';

export const AUTO_COMPLETE_MODE = {
  MIXED: 'mixed',
  VIDEO: 'video',
  CHANNEL: 'channel',
};

export const AUTO_COMPLETE_PARAMS = {
  SEARCH_QUERY: 'search_query',
  SEARCH_TERM: 'search_term',
  PAGE: 'page',
  COUNT: 'count',
};

export const SOURCE = {
  ALL: 'all',
  AAW: 'aaw',
  MYVIDS: 'myvids',
  FAVORITECHANNELS: 'favorite_channels',
} as const;

export const CONTENT_VALUES = {
  YES: 'true',
  NO: 'false',
  BOTH: '',
};

export const SOURCE_ITEMS = [
  {
    label: 'All of YouTube',
    value: SOURCE.ALL,
  },
  {
    label: 'Following',
    value: SOURCE.FAVORITECHANNELS,
  },
  {
    label: 'My audience also watched',
    value: SOURCE.AAW,
  },
  {
    label: 'My videos',
    value: SOURCE.MYVIDS,
  },
];

export const CONTENT_ITEMS = [
  {
    label: 'Yes',
    value: CONTENT_VALUES.YES,
  },
  {
    label: 'No',
    value: CONTENT_VALUES.NO,
  },
  {
    label: 'Both',
    value: CONTENT_VALUES.BOTH,
  },
];

export const LAYOUT = {
  GRID: 'grid',
  TABLE: 'table',
} as const;

export const FILTER_CHIPS = {
  SOURCE: 'source',
  UPLOAD_DATE: 'uploadDate',
  SUBSCRIBERS: 'subscribers',
  OUTLIER_INDEX: 'outlierIndex',
  V30: 'v30',
  VIEWS: 'view',
  DURATION: 'duration',
  MADE_FOR_KIDS: 'madeForKids',
  SEARCH: 'search',
} as const;

export const SORT_ORDER = {
  PERFORMANCE_INDEX_ASC: 'performance_index_asc',
  PERFORMANCE_INDEX_DESC: 'performance_index_desc',
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  TITLE1_ASC: 'title1_asc',
  TITLE1_DESC: 'title1_desc',
  UPLOADED_ASC: 'uploaded_asc',
  UPLOADED_DESC: 'uploaded_desc',
  VIEWS_ASC: 'views_asc',
  VIEWS_DESC: 'views_desc',
  SUB_ASC: 'subscribers_asc',
  SUB_DESC: 'subscribers_desc',
  V7_VIEWS_ASC: 'v7_score_asc',
  V7_VIEWS_DESC: 'v7_score_desc',
  CATEGORY_ASC: 'category_asc',
  CATEGORY_DESC: 'category_desc',
} as const;

export const DISPLAY_PARAMS = {
  COUNT: 'count',
  LAYOUT: 'layout',
  PAGE: 'page',
  SORT_BY: 'sortBy',
  SOURCE: 'source',
} as const;

export const FILTER_PARAMS = {
  DURATION_GTE: 'durationGte',
  DURATION_LTE: 'durationLte',
  PERFORMANCE_INDEX_GTE: 'performanceIndexGte',
  PERFORMANCE_INDEX_LTE: 'performanceIndexLte',
  SUBSCRIBERS_GTE: 'subscribersGte',
  SUBSCRIBERS_LTE: 'subscribersLte',
  UPLOADED_END: 'uploadedEnd',
  UPLOADED_START: 'uploadedStart',
  V30_GTE: 'v30Gte',
  V30_LTE: 'v30Lte',
  VIEWS_GTE: 'viewsGte',
  VIEWS_LTE: 'viewsLte',
  MADE_FOR_KIDS: 'madeForKids',
  SEARCH: 'search',
  SEARCH_BY: 'searchBy',
  COUNT: 'count',
  LAYOUT: 'layout',
  PAGE: 'page',
  SORT_BY: 'sortBy',
  SOURCE: 'source',
  NAME: 'name',
  UCID: 'ucid',
} as const;

export const SEARCH_BY_VALUES = {
  ALL: 'all',
  CHANNELS: 'channels',
  TITLES: 'titles',
} as const;

export const PARAM_KEYS = {
  ...DISPLAY_PARAMS,
  ...FILTER_PARAMS,
};

export interface DefaultValues {
  [key: string]: string;
}

export const DEFAULT_FILTER_VALUES: DefaultValues = {
  [PARAM_KEYS.DURATION_GTE]: '',
  [PARAM_KEYS.DURATION_LTE]: '',
  [PARAM_KEYS.NAME]: '',
  [PARAM_KEYS.MADE_FOR_KIDS]: DEFAULT_MADE_FOR_KIDS,
  [PARAM_KEYS.PERFORMANCE_INDEX_GTE]: DEFAULT_PERFORMANCE_INDEX_GTE,
  [PARAM_KEYS.PERFORMANCE_INDEX_LTE]: '',
  [PARAM_KEYS.SOURCE]: SOURCE.ALL,
  [PARAM_KEYS.SUBSCRIBERS_GTE]: '',
  [PARAM_KEYS.SUBSCRIBERS_LTE]: '',
  [PARAM_KEYS.UCID]: '',
  [PARAM_KEYS.UPLOADED_END]: '',
  [PARAM_KEYS.UPLOADED_START]: '',
  [PARAM_KEYS.VIEWS_GTE]: '',
  [PARAM_KEYS.VIEWS_LTE]: '',
} as const;

export const DEFAULT_VALUES: DefaultValues = {
  ...DEFAULT_FILTER_VALUES,
  [PARAM_KEYS.SEARCH_BY]: SEARCH_BY_VALUES.ALL,
  [PARAM_KEYS.SORT_BY]: SORT_ORDER.UPLOADED_DESC,
  [PARAM_KEYS.COUNT]: DEFAULT_PAGE_SIZE,
  [PARAM_KEYS.LAYOUT]: LAYOUT.GRID,
} as const;

export const FILTER_PRESETS_USER_GATE = 'outliers_filter_presets';
