import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@lib/ui';
import * as Styles from './footer.css';

export function AuthFooter() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  function showCookieDrawer() {
    // Osano is the privacy & cookie manager for the site, it requires special treatment
    // @ts-expect-error Osano privacy manager
    window.Osano?.cm.showDrawer();
  }

  return (
    <footer className={Styles.root}>
      <div>
        <Text>
          <Trans t={t} currentYear={currentYear}>
            &copy; {{ currentYear }} Spotter Memberships, LLC. All rights
            reserved.
          </Trans>
        </Text>
      </div>
      <div className={Styles.links}>
        <ul>
          <li>
            <a
              href={import.meta.env.VITE_PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Text>{t('Privacy Policy')}</Text>
            </a>
          </li>
          <li>
            <a
              href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Text>{t('Terms of service')}</Text>
            </a>
          </li>
          <li>
            <a
              href={import.meta.env.VITE_DMCA_POLICY_URL}
              target="_blank"
              rel="noreferrer"
            >
              <Text>{t('DMCA Policy')}</Text>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              href={
                import.meta.env
                  .VITE_DO_NOT_SELL_OR_SHARE_MY_PERSONAL_INFORMATION_URL
              }
              target="_blank"
              rel="noreferrer"
            >
              <Text>{t('Do Not Sell or Share My Personal Information')}</Text>
            </a>
          </li>
          <li>
            <button onClick={showCookieDrawer}>
              <Text>{t('Cookie & Ad Settings')}</Text>
            </button>
          </li>
        </ul>
      </div>
    </footer>
  );
}
