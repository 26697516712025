import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function CheckmarkIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0383 2.42079C11.2431 2.62605 11.2427 2.95846 11.0375 3.16326L4.60615 9.57992C4.40144 9.78417 4.0701 9.78441 3.86509 9.58047L0.904739 6.63552C0.699179 6.43104 0.698312 6.09862 0.902802 5.89306C1.10729 5.68751 1.4397 5.68664 1.64526 5.89113L4.2348 8.46719L10.2959 2.41995C10.5011 2.21516 10.8335 2.21554 11.0383 2.42079Z"
        fill="currentColor"
      />
    </svg>
  );
}
