/**
 * Formats a given date object into a string with the format `YYYY.DD.MM-HH:MM`.
 *
 * If no date is provided, the current date and time will be used.
 */
export function formatTimestamp(date: Date = new Date()) {
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${month}.${day}.${year}-${hours}:${minutes}`;
}
