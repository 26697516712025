import { type PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Icons } from '@lib/ui';
import * as Styles from './filters.css';

/**
 * Due to #2279 in Radix we can't use Form.Message (or Form.Error in our system) outside of a Form.Field
 *
 * This makes it impossible to have a unified error message per filter, since we are using
 * multiple fields per filter in a lot of cases.
 *
 * Link: https://github.com/radix-ui/primitives/issues/2279
 *
 * TODO: Replace this component in use with `Form.Error` when the above is fixed
 */
export function ErrorText(props: PropsWithChildren) {
  return (
    <p className={clsx(Styles.errorMessage)}>
      <Icons.ExclamationCircleIcon />
      {props.children}
    </p>
  );
}
