import { ForwardedRef, forwardRef } from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import clsx from 'clsx';
import { focusOutline } from '@lib/theme';
import { SwitchProvider, useSwitch } from './context';
import * as Styles from './switch.css';

/**
 * Switch Root
 */
export type RootProps = SwitchPrimitives.SwitchProps & {
  variant?: 'dark' | 'light';
};
export const Root = forwardRef(
  (props: RootProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { variant = 'dark' } = props;
    return (
      <SwitchProvider variant={variant}>
        <SwitchPrimitives.Root
          className={clsx(
            focusOutline,
            Styles.rootVariants({ variant }),
            props.className
          )}
          ref={ref}
          {...props}
        >
          {props.children}
        </SwitchPrimitives.Root>
      </SwitchProvider>
    );
  }
);

Root.displayName = 'SwitchRoot';

/**
 * Switch Thumb
 */
export type ThumbProps = SwitchPrimitives.SwitchThumbProps;
export const Thumb = forwardRef(
  (props: RootProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { variant } = useSwitch();

    return (
      <SwitchPrimitives.Thumb
        className={clsx(Styles.thumbVariants({ variant }), props.className)}
        ref={ref}
        {...props}
      />
    );
  }
);

Thumb.displayName = 'SwitchThumb';
