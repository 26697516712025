import { DocumentNode } from 'graphql';
import client from '@studio/graphql-client';
import {
  ChannelStatsListInput,
  ChannelStatsListPagination,
} from '@lib/gql/graphql';
import { GET_CHANNEL_STATS_LIST_QUERY } from '../queries';

export const fetchChannelStatsList = async (
  pageParam = 1,
  params: ChannelStatsListInput
): Promise<ChannelStatsListPagination> => {
  const input = { ...params, page: Number(pageParam) };
  try {
    const { channelStatsList } = await client.request<{
      channelStatsList: ChannelStatsListPagination;
    }>(GET_CHANNEL_STATS_LIST_QUERY as DocumentNode, { input });

    return channelStatsList;
  } catch (err) {
    return {
      results: [],
    };
  }
};

export default { fetchChannelStatsList };
