import { PropsWithChildren, ReactElement } from 'react';

interface DefaultProps {
  children: ReactElement;
  when: never;
}

interface WhenProps {
  children: ReactElement;
  when: string | number;
}

interface SwitchProps {
  children:
    | ReactElement<DefaultProps | WhenProps>
    | ReactElement<DefaultProps | WhenProps>[];
  condition: string | number;
}

export const Switch = ({ condition, children }: SwitchProps) => {
  const childArray = Array.isArray(children) ? children : [children];
  const filtered = childArray.filter((child) => child.props.when === condition);
  const defaultCase = childArray.filter((child) => !child.props.when);

  if (!childArray.length) {
    throw new Error('Switch Router: must have at least one child component');
  }

  if (defaultCase.length > 1) {
    throw new Error('Switch Router: only one default case allowed');
  }

  return filtered.length ? filtered : defaultCase[0];
};

Switch.When = ({ children }: WhenProps) => children;
Switch.Default = ({ children }: PropsWithChildren) => children;
