import React, { forwardRef, Ref } from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import clsx from 'clsx';
import * as Styles from './toggle.css';

export type ToggleProps = TogglePrimitive.ToggleProps & {
  children: React.ReactNode;
  description?: string;
};

type Props = ToggleProps & Styles.ToggleVariants;

const Toggle = forwardRef(
  (
    {
      border = true,
      className,
      disabled = false,
      description = 'Toggle',
      iconOnly = false,
      pill = false,
      size = 'md',
      variant = 'light',
      ...props
    }: Props,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <TogglePrimitive.Root
        ref={ref}
        className={clsx(
          Styles.toggleVariants({
            border,
            disabled,
            iconOnly,
            pill,
            size,
            variant,
          }),
          className
        )}
        aria-label={description}
        {...props}
      />
    );
  }
);

export { Toggle };
