import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tiptap/react';
import { useAutoFocus } from '@studio/hooks';
import { Popover, TextInput, Icons, Form, Button, Text } from '@lib/ui';
import * as styles from './toolbar.css';
import { ToolbarIconButton } from '.';

type Props = {
  editor: Editor;
};

export const ToolbarLink = forwardRef<HTMLDivElement, Props>(
  ({ editor }, ref) => {
    const { t } = useTranslation();

    const currentLink = editor.getAttributes('link').href;
    const currentText =
      editor.view.state.selection
        .content()
        .content.textBetween(
          0,
          editor.view.state.selection.content().content.size,
          ' '
        ) ||
      editor.getAttributes('link').text ||
      '';

    function toggleLink(link: string) {
      if (link) {
        editor.chain().focus().toggleLink({ href: link }).run();
      } else {
        editor.commands.unsetLink();
      }
    }

    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <ToolbarIconButton
            disabled={!editor.can().chain().focus().setLink({ href: '' }).run()}
            data-active={editor.isActive('link') ? 'is-active' : undefined}
            label={t('Link')}
            icon={<Icons.LinkIcon aria-hidden />}
          />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="start" className={styles.popoverContent}>
            <Popover.Close className={styles.popoverClose} />
            <LinkPopover
              text={currentText}
              link={currentLink}
              onSubmit={toggleLink}
              onRemoveLink={() => toggleLink('')}
              showRemove={!!editor.getAttributes('link').href}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
);

type LinkPopoverProps = {
  text: string;
  link: string;
  onSubmit: (url: string) => void;
  onRemoveLink: () => void;
  showRemove: boolean;
};

function LinkPopover({
  text,
  link,
  onSubmit,
  onRemoveLink,
  showRemove,
}: LinkPopoverProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(link);

  const { ref: inputRef } = useAutoFocus();

  const validateLink = (url: string) => {
    if (!url) return '';
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <>
      <Text size="24">{t('Add a Link')}</Text>
      <Form.Root
        className={styles.toolbarPopoverForm}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(validateLink(value));
        }}
      >
        <Form.Field name="text">
          <Form.Label className={styles.toolbarPopoverLabel} htmlFor="text">
            <Text size="11">{t('Text')}</Text>
          </Form.Label>
          <Form.Control asChild>
            <div className={styles.toolbarPopoverBar}>
              <TextInput.Root variant="light">
                <TextInput.Input disabled defaultValue={text} />
              </TextInput.Root>
            </div>
          </Form.Control>
        </Form.Field>
        <Form.Field name="link">
          <Form.Label className={styles.toolbarPopoverLabel} htmlFor="link">
            <Text size="11">{t('Link')}</Text>
          </Form.Label>
          <Form.Control asChild>
            <div className={styles.toolbarPopoverBar}>
              <TextInput.Root variant="light">
                <TextInput.Input
                  ref={inputRef}
                  placeholder="https://"
                  value={value}
                  onChange={(e) => setValue(e)}
                />
              </TextInput.Root>
            </div>
          </Form.Control>
        </Form.Field>
        <div className={styles.toolbarPopoverButtons}>
          {showRemove ? (
            <Button
              fill="ghost"
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveLink();
              }}
              aria-label={t('Remove link')}
            >
              {t('Cancel')}
            </Button>
          ) : null}
          <Form.Submit className={styles.toolbarPopoverButton} asChild>
            <Button type="submit" size="sm">
              {t('Add')}
            </Button>
          </Form.Submit>
        </div>
      </Form.Root>
    </>
  );
}
