import { createContext, useCallback, useEffect } from 'react';
import { useAuthStore } from '@studio/features/auth';
import { useProfileQuery } from '@studio/hooks';
import { useOrganizationStore } from '@studio/stores';
import { UserProfileEntitlement } from '@studio/types';
import client from './io';

const BirdoContext = createContext({});

interface Egg {
  eggType: 'refresh' | 'logout';
  userEmail: string;
}

function uniqueByChannelAndOrg(arr: UserProfileEntitlement[]) {
  const seen = new Set();
  return arr.filter((item) => {
    const key = `${item.organizationId}-${item.channel.ucid}`;
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export const useRefreshUserEntitlements = () => {
  const { organizations, getSpotterOrg, setOrganizations } =
    useOrganizationStore();
  const { setUser } = useAuthStore();
  const userQuery = useProfileQuery();

  return useCallback(() => {
    userQuery
      .refetch()
      .then(({ data }) => {
        if (data) {
          setUser(data);
          let additionOrgs: UserProfileEntitlement[] = [];
          const spotterOrg = getSpotterOrg();
          if (spotterOrg) {
            additionOrgs = organizations.filter(
              (org) => org.organizationId === spotterOrg.organizationId
            );
          }
          const finalList = uniqueByChannelAndOrg([
            ...additionOrgs,
            ...data.entitlements.filter(
              (entitlement) => entitlement.source === 'Studio'
            ),
          ]);
          setOrganizations(finalList);
        }
      })
      .catch((error) => {
        console.error('Failed to refresh user entitlements from Birdo:', error);
      });
  }, []);
};

export const BirdoProvider = () => {
  const { handleLogoutRedirect } = useAuthStore();
  const refreshUserEntitlements = useRefreshUserEntitlements();

  useEffect(() => {
    refreshUserEntitlements();
    client.on('system', (msg: Egg) => {
      switch (msg.eggType) {
        case 'refresh': {
          refreshUserEntitlements();
          break;
        }
        case 'logout': {
          handleLogoutRedirect();
          break;
        }
      }
    });

    return () => {
      client.off('system');
    };
  }, [handleLogoutRedirect, refreshUserEntitlements]);

  return <BirdoContext.Provider value={true} />;
};
