import { useEffect } from 'react';
import { useAuthStore } from '../features/auth/stores';

const tokenRefresh = async () => {
  try {
    // Attempt to refresh token and get new cookies.
    const bowserUrl = `${import.meta.env.VITE_BOWSER_URL}/api/auth0/refresh`;
    const attempt = await fetch(bowserUrl, {
      method: 'POST',
      credentials: 'include',
    });

    if ([400, 401].includes(attempt.status) || attempt.status >= 500) {
      throw new Error('Unauthorized');
    }
  } catch (error) {
    window.location.href = `${window.location.origin}/auth/logout`;
  }
};

export const useAutoTokenRefresh = () => {
  const user = useAuthStore((state) => state.user);
  useEffect(() => {
    if (user?.email) {
      const interval = setInterval(tokenRefresh, 60 * 60 * 1000); // Every hour
      return () => clearInterval(interval);
    }
  }, [user]);
  return;
};
