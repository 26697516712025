import { create } from 'zustand';

export type SpiderStore = {
  spiders: Record<string, string | undefined>;
  closeSpider: (group: string) => void;
  openSpider: (group: string, id: string) => void;
  hasSpiders: (obj: string) => boolean;
  clearSpiders: () => void;
};

export const useSpiderStore = create<SpiderStore>((set, get) => ({
  spiders: {},
  closeSpider: (group) => {
    set((state) => ({
      spiders: {
        ...state.spiders,
        [group]: undefined,
      },
    }));
  },
  openSpider: (group, id) => {
    set((state) => ({
      spiders: {
        ...state.spiders,
        [group]: id,
      },
    }));
  },
  clearSpiders: () => {
    set({ spiders: {} });
  },
  /** Check if spiders are present on elements other than the package */
  hasSpiders: (property) => {
    const spiderWebs = get().spiders;
    return Object.keys(spiderWebs).some((key) => key !== property);
  },
}));
