import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icons, Tooltip } from '@lib/ui';
import { TextArea, TextAreaProps } from '../text-area';
import * as Styles from './ideation-textarea.css';

const TOOLTIP_TIMEOUT_MS = 3000;

type IdeationTextAreaProps = TextAreaProps & {
  showAdornment: boolean;
  showMaxLengthError: boolean;
};

export const IdeationTextArea = React.forwardRef<
  HTMLTextAreaElement,
  IdeationTextAreaProps
>(({ showMaxLengthError, hasError, showAdornment, ...restProps }, ref) => {
  const { t } = useTranslation();

  const [isTooltipVisible, setIsTooltipVisible] = useState(showMaxLengthError);

  // Manage tooltip visibility with a timeout
  useEffect(() => {
    if (showMaxLengthError) {
      setIsTooltipVisible(true);
      const timer = setTimeout(
        () => setIsTooltipVisible(false),
        TOOLTIP_TIMEOUT_MS
      );
      return () => clearTimeout(timer);
    }
  }, [showMaxLengthError]);

  return (
    <>
      <Tooltip.Provider>
        <Tooltip.Root open={isTooltipVisible} delayDuration={0}>
          <Tooltip.Trigger asChild>
            <TextArea {...restProps} hasError={hasError} ref={ref} />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content side="bottom" sideOffset={8}>
              <Tooltip.Arrow />
              {t('Text limit reached')}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
      {showAdornment && (
        <div
          className={clsx(Styles.textareaAdornment, {
            error: hasError,
          })}
        >
          <Icons.EditIcon aria-hidden />
        </div>
      )}
    </>
  );
});

IdeationTextArea.displayName = 'IdeationTextArea';
