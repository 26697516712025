import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Flex, Icons } from '@lib/ui';
import * as Styles from './applied-filter-layout.css';

type RootProps = PropsWithChildren & {
  className?: string;
};

export const Root = ({ children, className }: RootProps) => {
  return <Flex className={clsx(Styles.root, className)}>{children}</Flex>;
};

type ContainerProps = PropsWithChildren & {
  className?: string;
};

export const Container = ({ children, className }: ContainerProps) => {
  return <Flex className={clsx(Styles.container, className)}>{children}</Flex>;
};

type ArrowProps = {
  direction: 'left' | 'right';
  handleClick: (value: number) => void;
};

export const Arrow = ({ direction, handleClick }: ArrowProps) => {
  const { t } = useTranslation();

  const isLeftDirection = direction === 'left';
  return (
    <>
      <div
        className={clsx(
          Styles.fadeOverlay,
          isLeftDirection ? Styles.fadeLeft : Styles.fadeRight
        )}
      />
      <div
        className={clsx(
          Styles.scrollArrows,
          isLeftDirection ? Styles.leftArrow : Styles.rightArrow
        )}
        onClick={() => handleClick(isLeftDirection ? -100 : 100)}
      >
        {isLeftDirection ? (
          <Icons.ChevronLeftIcon aria-label={t('Navigate left')} />
        ) : (
          <Icons.ChevronRightIcon aria-label={t('Navigate right')} />
        )}
      </div>
    </>
  );
};

type ContentProps = PropsWithChildren & {
  className?: string;
};

export const Content = forwardRef(
  ({ children, className }: ContentProps, ref: ForwardedRef<HTMLElement>) => {
    return (
      <Flex ref={ref} className={clsx(Styles.content, className)}>
        {children}
      </Flex>
    );
  }
);

type ResetButtonProps = {
  handleReset: () => void;
};

export const ResetButton = ({ handleReset }: ResetButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      fill="text"
      size="sm"
      variant="secondary"
      onClick={handleReset}
      className={Styles.buttonColor}
    >
      {t('Reset all')}
    </Button>
  );
};
