import { forwardRef, Ref } from 'react';
import { generateColor } from '@marko19907/string-to-color';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import clsx from 'clsx';
import { Icons } from '../../';
import { avatarVariants, AvatarVariants, image, fallback } from './avatar.css';

/**
 * Avatar Root
 */
export type RootProps = AvatarPrimitive.AvatarProps &
  AvatarVariants & {
    name?: string;
    className?: string;
    onClick?: () => void;
  };

export const Root = forwardRef(
  (
    {
      className,
      onClick,
      border = false,
      name,
      pill = false,
      size = '24',
      variant,
      ...props
    }: RootProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <AvatarPrimitive.Root
        ref={ref}
        className={clsx(
          avatarVariants({ border, size, pill, variant }),
          className
        )}
        style={{
          background: name
            ? generateColor(name, { lightness: 40 })
            : 'currentcolor',
        }}
        onClick={onClick}
        {...props}
      />
    );
  }
);

Root.displayName = 'AvatarRoot';

/**
 * Avatar Image
 */

export type ImageProps = AvatarPrimitive.AvatarImageProps & {
  className?: string;
  name?: string;
};

export const Image = forwardRef(
  ({ className, ...props }: ImageProps, ref: Ref<HTMLImageElement>) => {
    return (
      <AvatarPrimitive.Image
        ref={ref}
        className={clsx(image, className)}
        aria-label={props.name}
        {...props}
      />
    );
  }
);

Image.displayName = 'AvatarImage';

/**
 * Avatar Fallback Text
 */

export type FallbackProps = AvatarPrimitive.AvatarFallbackProps & {
  children?: string;
  className?: string;
};

export const Fallback = forwardRef(
  (
    { className, children, ...props }: FallbackProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <AvatarPrimitive.Fallback
        ref={ref}
        className={clsx(fallback, className)}
        {...props}
      >
        {children || <Icons.PersonIcon aria-hidden />}
      </AvatarPrimitive.Fallback>
    );
  }
);

Fallback.displayName = 'AvatarFallback';
