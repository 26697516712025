/* eslint-disable @typescript-eslint/no-explicit-any */

import Handlebars from 'handlebars';

export function compileTemplates(
  systemTemplate: string,
  userTemplate: string,
  variables: any
) {
  try {
    const handlebarSystemTemplate = Handlebars.compile(systemTemplate);
    const compiledSystemTemplate = handlebarSystemTemplate(variables);

    const handlebarUserTemplate = Handlebars.compile(userTemplate);
    const compiledUserTemplate = handlebarUserTemplate(variables);

    const messages = [
      {
        role: 'system',
        content: compiledSystemTemplate,
      },
      {
        role: 'user',
        content: compiledUserTemplate,
      },
    ];

    return messages;
  } catch (error) {
    console.error(error);
  }

  return undefined;
}

Handlebars.registerHelper('checkRequired', function (value, varName) {
  if (value === undefined) {
    throw new Error(`Missing required variable: ${varName}`);
  }
  return value;
});

Handlebars.registerHelper(
  'ifEquals',
  function (
    this: any,
    arg1: any,
    arg2: any,
    options: Handlebars.HelperOptions
  ) {
    return arg1 === arg2 ? options.fn(this) : options.inverse(this);
  }
);

Handlebars.registerHelper(
  'thisButOrEmptyInspiredBy',
  function (
    this: any,
    thisBut: any,
    inspiredBy: any,
    options: Handlebars.HelperOptions
  ) {
    return thisBut || inspiredBy === ''
      ? options.fn(this)
      : options.inverse(this);
  }
);
