import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function UploadIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.5 27.9717L13.5 11.9829L7.13299 18.4094C6.54994 18.9979 5.6002 19.0023 5.01169 18.4193C4.42319 17.8362 4.41877 16.8865 5.00183 16.298L14.0187 7.19686C14.3004 6.91254 14.684 6.75258 15.0843 6.75258C15.4845 6.75258 15.8681 6.91254 16.1498 7.19686L25.1667 16.298C25.7498 16.8865 25.7453 17.8362 25.1568 18.4193C24.5683 19.0023 23.6186 18.9979 23.0355 18.4094L16.5 11.8128V27.9717C16.5 28.8001 15.8284 29.4717 15 29.4717C14.1716 29.4717 13.5 28.8001 13.5 27.9717ZM0.0168457 2.01663C0.0168457 1.6188 0.174881 1.23727 0.456186 0.955965C0.73749 0.674662 1.11902 0.516626 1.51685 0.516626H28.4831C29.3116 0.516626 29.9831 1.1882 29.9831 2.01663V6.39865C29.9831 7.22708 29.3116 7.89865 28.4831 7.89865C27.6547 7.89865 26.9831 7.22708 26.9831 6.39865V3.51663H3.01685L3.01685 6.39865C3.01685 7.22708 2.34527 7.89865 1.51685 7.89865C0.688419 7.89865 0.0168457 7.22708 0.0168457 6.39865V2.01663Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
