import { trackEvent } from '@studio/lib/heap';
import { ProjectElementType } from '@lib/types';
import * as EVENTS from '../../heap.constants';

export const useProjectPackageAnalytics = (id: string) => {
  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onBrainstormEvent = (
    properties?: Record<string, string | undefined>
  ) => {
    track(EVENTS.PROJECTS_BRAINSTORM_CLICK, properties);
  };

  const onDeleteProject = () => {
    track(EVENTS.PROJECTS_DELETE_PROJECT_CLICK);
  };

  const onProjectDuplicate = () => {
    track(EVENTS.PROJECTS_DUPLICATE_PROJECT_CLICK);
  };

  const onCreateElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_ADD_NEW_CLICK_ON_SIDE, {
      element,
    });
  };

  const onDeleteElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_DELETE_CLICK_ON_SIDE, {
      element,
    });
  };

  const onCopyElement = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_COPY_CLICK_ON_SIDE, {
      element,
    });
  };

  const onUploadClick = () => {
    track(EVENTS.PROJECTS_THUMBNAIL_UPLOAD_CLICK);
  };

  const onUploadSuccess = () => {
    track(EVENTS.PROJECTS_THUMBNAIL_UPLOAD_SUCCESS);
  };

  const onThumbnailDownload = () => {
    track(EVENTS.PROJECTS_DOWNLOAD_CLICK_ON_SIDE);
  };

  return {
    onBrainstormEvent,
    onDeleteProject,
    onProjectDuplicate,
    onCreateElement,
    onDeleteElement,
    onCopyElement,
    onUploadClick,
    onUploadSuccess,
    onThumbnailDownload,
  };
};
