import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const PANELS = {
  HISTORY: 'history',
  NOTEPAD: 'notepad',
} as const;

export type PanelType = (typeof PANELS)[keyof typeof PANELS];

type SidePanelStore = {
  visiblePanel: PanelType | '';
  toggleVisibility: (value: PanelType | '') => void;
};

export const useSidePanelStore = create<SidePanelStore>()(
  devtools(
    (set) => ({
      visiblePanel: '',
      toggleVisibility: (value: PanelType | '') =>
        set(
          () => ({ visiblePanel: value }),
          false,
          'side-panel/toggle-visibility'
        ),
    }),
    { name: 'side-panel' }
  )
);
