import { createContext, useContext, PropsWithChildren } from 'react';
import { SwitchRootVariants } from './switch.css';

type SwitchContextType = PropsWithChildren & SwitchRootVariants;

export const SwitchContext = createContext<SwitchContextType | null>(null);

export const SwitchProvider = ({ children, variant }: SwitchContextType) => {
  return (
    <SwitchContext.Provider value={{ variant }}>
      {children}
    </SwitchContext.Provider>
  );
};

export const useSwitch = () => {
  const context = useContext(SwitchContext);
  if (!context) {
    throw new Error('useSwitch must be used within a SwitchProvider');
  }
  return context;
};
