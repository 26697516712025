// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<F extends (...args: any[]) => void>(
  func: F,
  wait: number,
  immediate?: boolean
): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  /**
   * Note: `this` here is not actually an arg being passed in but is the canonical way of typing `this` in functions.
   * https://www.typescriptlang.org/docs/handbook/2/functions.html#declaring-this-in-a-function
   */
  return function (this: unknown, ...args: Parameters<F>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    if (!!immediate && !timeout) {
      func.apply(context, args);
    }

    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
}
