import { forwardRef, ReactNode, Ref, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Popover, useIsOnScreen } from '@lib/ui';
import * as Styles from './auto-complete-menu.css';

type AutoCompleteProps = {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  handleCallNextPage: () => void;
  width?: number;
};

export const Root = forwardRef(
  (
    {
      children,
      isOpen = false,
      width,
      handleClose,
      handleCallNextPage,
    }: AutoCompleteProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const loadMoreRef = useRef(null);

    useIsOnScreen(loadMoreRef, () => {
      handleCallNextPage();
    });

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          contentRef.current &&
          !contentRef.current.contains(event.target as Node)
        ) {
          handleClose();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <Popover.Root open={isOpen} modal={false}>
        <Popover.Trigger></Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align="start"
            sideOffset={4}
            style={{ width }}
            className={Styles.content}
            ref={contentRef}
            onOpenAutoFocus={(event) => event.preventDefault()}
            onCloseAutoFocus={(event) => event.preventDefault()}
            onFocus={(event) => event.preventDefault()}
            onKeyDown={(event) => event.stopPropagation()}
          >
            {children}
            <div ref={loadMoreRef} style={{ width: '100%', height: '100px' }} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
);

type ItemProps = {
  children: ReactNode;
  className?: string;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  isFocused?: boolean;
};

export const Item = forwardRef(
  (
    { children, className, onFocus, onKeyDown, isFocused }: ItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        className={clsx(Styles.item, className)}
        tabIndex={-1}
        ref={ref}
        onFocus={onFocus}
        onKeyDown={(event) => {
          event.preventDefault(); // Prevent any default behavior (like scrolling)
          event.stopPropagation(); // Stop the event from bubbling up
          onKeyDown?.(event);
        }}
      >
        {children}
      </div>
    );
  }
);
