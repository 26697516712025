import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RangeFilter } from '@studio/components';
import { ParamsObject, useParamStore } from '@studio/stores';
import { Form } from '@lib/ui';
import { removeCharactersFromString } from '@lib/utils';
import { PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';

function serializeValue(value: number | string) {
  const str = '00:00:00';
  const numbers = value.toString().split('');

  let numberIndex = numbers.length - 1;
  let result = '';

  for (let i = str.length - 1; i >= 0; i--) {
    if (str[i] === '0' && numberIndex >= 0) {
      result = numbers[numberIndex] + result;
      numberIndex--;
    } else {
      result = str[i] + result;
    }
  }

  return result;
}

function deserializeValue(value: string) {
  return Number(removeCharactersFromString(value));
}

export function VideoDurationFilterInput() {
  const { params, setParamsTracked, removeParamTracked } = useParamStore();
  const { t } = useTranslation();

  const handleSetParams = (value: ParamsObject) => {
    setParamsTracked(EVENTS.OUTLIERS_FILTER_VIDEO_DURATION_CHANGE, value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    for (const [key, value] of formData.entries()) {
      if (!value && !params[key]) {
        return;
      }
      const numericValue = deserializeValue(value as string);
      setParamsTracked(EVENTS.OUTLIERS_FILTER_VIDEO_DURATION_CHANGE, {
        [key]: numericValue ? numericValue.toString() : '',
      });
    }
  };

  const removeAndTrackParams = (value: string) => {
    removeParamTracked(EVENTS.OUTLIERS_FILTER_VIDEO_DURATION_CHANGE, value);
  };

  return (
    <Form.Root onSubmit={handleSubmit}>
      <RangeFilter
        min={0}
        minId={PARAM_KEYS.DURATION_GTE}
        minLabel={t('Minimum')}
        minPlaceholder="00:00:00"
        max={Infinity}
        maxId={PARAM_KEYS.DURATION_LTE}
        maxLabel={t('Maximum')}
        maxPlaceholder="12:00:00"
        serializeValue={serializeValue}
        deserializeValue={deserializeValue}
        params={params}
        setParams={handleSetParams}
        removeParam={removeAndTrackParams}
      />
      <Form.Submit />
    </Form.Root>
  );
}
