import { StatsigClient } from '@statsig/js-client';

export const trackEvent = (
  label: string,
  properties?: Record<string, string>
) => {
  // Statsig
  StatsigClient.instance().logEvent({
    eventName: label,
    metadata: properties,
  });

  // Heap
  window.heap.track(label, properties);
};
