import { useContext, useState } from 'react';
import { trackEvent } from '@studio/lib/heap';
import { ProjectStoreContext } from '@studio/providers';
import { ProjectElementType } from '@lib/types';
import { useInspirationStore } from '../../../brainstorm/stores';
import * as EVENTS from '../../heap.constants';

export const useInspirationPanelAnalytics = () => {
  const { projectStore } = useContext(ProjectStoreContext);
  const { topic, temperature, audience, additional } = useInspirationStore();

  // Use to store previous value, for analytics
  const [localTopic, setLocalTopic] = useState(topic);
  const [localTemperature, setLocalTemperature] = useState(temperature);
  const [localAudience, setLocalAudience] = useState(audience);
  const [localAdditional, setLocalAdditional] = useState(additional);

  const track = (
    label: string,
    properties?: Record<string, string | undefined>
  ) => {
    trackEvent(label, {
      videoProject: projectStore.getState().id,
      ...properties,
    });
  };

  const onTopicBlur = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CHANGE, {
      old_value: localTopic,
      new_value: topic,
    });
    setLocalTopic(topic);
  };

  const onTemperatureBlur = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_INSPIRED_BY_CHANGE, {
      old_value: `${localTemperature}`,
      new_value: `${temperature}`,
    });
    setLocalTemperature(temperature);
  };

  const onAudienceChange = (value: string) => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_AUDIENCE_DESCRIPTION_CHANGE, {
      old_value: localAudience,
      new_value: value,
    });
    setLocalAudience(value);
  };

  const onThumbnailStyleChange = (old_value: string, new_value: string) =>
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_THUMBNAIL_STYLE_CHANGE, {
      old_value,
      new_value,
    });

  const onAdditionalChange = (value: string) => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_ADDITIONAL_DETAIL_CHANGE, {
      old_value: localAdditional,
      new_value: value,
    });
    setLocalAdditional(value);
  };

  const onGenerate = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_GENERATE_CLICK, {
      element,
    });
  };

  const onUploadClick = () => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_UPLOAD_CLICK
    );
  };

  const onUploadSuccess = () => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_UPLOAD_SUCCESS
    );
  };

  const onThumbnailDownload = () => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_DOWNLOAD_CLICK
    );
  };

  const onThumbailDelete = () => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_THUMBNAIL_DELETE_CLICK
    );
  };

  const onTitleChange = (oldValue: string, newValue: string) => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_TITLE_CHANGE,
      {
        old_value: oldValue,
        new_value: newValue,
      }
    );
  };

  const onConceptChange = (oldValue: string, newValue: string) => {
    track(
      EVENTS.PROJECTS_BRAINSTORM_SETTING_INPUT_CURRENT_OPTIONS_CONCEPT_CHANGE,
      {
        old_value: oldValue,
        new_value: newValue,
      }
    );
  };

  return {
    onTopicBlur,
    onTemperatureBlur,
    onAudienceChange,
    onThumbnailStyleChange,
    onAdditionalChange,
    onGenerate,
    onUploadClick,
    onUploadSuccess,
    onThumbnailDownload,
    onThumbailDelete,
    onTitleChange,
    onConceptChange,
  };
};
