import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function DragBothIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        d="M15.1417 9.99948L13.3333 11.8078L14.5083 12.9828L17.5 9.99948L14.5083 7.00781L13.3333 8.19115L15.1417 9.99948ZM4.85833 9.99948L6.66667 8.19115L5.49167 7.01615L2.5 9.99948L5.49167 12.9911L6.66667 11.8078L4.85833 9.99948Z"
        fill="currentColor"
      />
      <path
        d="M10 15.1417L8.1917 13.3333L7.0167 14.5083L10 17.5L12.9917 14.5083L11.8084 13.3333L10 15.1417ZM10 4.85833L11.8084 6.66667L12.9834 5.49167L10 2.5L7.00837 5.49167L8.1917 6.66667L10 4.85833Z"
        fill="currentColor"
      />
    </svg>
  );
}
