import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { TextInput } from '@lib/ui';

type ControlledInputProps = {
  id: string;
  value: string;
  label: string;
  onUpdate: (value: string) => void;
};

export const ControlledInput = forwardRef(
  (props: ControlledInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [tempValue, setTempValue] = useState(props.value);

    useEffect(() => {
      setTempValue(props.value);
    }, [props.value]);

    return (
      <TextInput.Root size="lg">
        <TextInput.Input
          ref={ref}
          id={props.id}
          value={tempValue}
          placeholder={props.label}
          onBlur={props.onUpdate}
          onChange={(value) => setTempValue(value)}
        />
      </TextInput.Root>
    );
  }
);
