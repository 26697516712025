import { listVideos } from '@studio/features/outliers/requests';

export const fetchSubscriberCount = async ({
  channelUcid,
}: {
  channelUcid: string;
}): Promise<number> => {
  try {
    const videoData = await listVideos({
      params: { ucid: channelUcid, itemsPerPage: '1' },
    });

    const subscribers = videoData.results?.[0]?.subscribers;

    if (subscribers) {
      return subscribers;
    }

    throw new Error('Failed to fetch subscriber count');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default { fetchSubscriberCount };
