import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { HorizontalLogo } from '@lib/ui';
import * as Styles from './layout.css';

export function NewAuthLayout(props: PropsWithChildren) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathName = location.pathname;

  const showLogin = pathName === '/auth/signup';

  return (
    <div className={Styles.layout}>
      <header className={Styles.header}>
        <HorizontalLogo />
        {showLogin ? (
          <div className={Styles.actions}>
            <Trans t={t}>
              Already have an account?&nbsp;
              <Link to="/home">
                <strong>Log in</strong>
              </Link>
            </Trans>
          </div>
        ) : null}
      </header>
      <main>{props.children}</main>
    </div>
  );
}
