import { PowerKeyword } from '@lib/types';

export const groupOverlappingKeywords = (keywords: PowerKeyword[]) => {
  const firstGroup: PowerKeyword[] = [];
  const remainingGroups: PowerKeyword[][] = [];

  let currentOverlapGroup: PowerKeyword[] = [];
  let lastEndPos = -1;

  keywords.forEach((keyword) => {
    if (keyword.startPos >= lastEndPos) {
      // No overlap or first keyword in an overlapping sequence
      firstGroup.push(keyword);
      currentOverlapGroup = [keyword]; // Start a new overlap group
    } else {
      // There's an overlap; add to the current overlap group
      currentOverlapGroup.push(keyword);
    }

    // Update the last end position
    lastEndPos = Math.max(
      lastEndPos,
      keyword.startPos + keyword.content.length
    );

    // If this overlap group has more than one keyword,
    // push the remaining ones (after the first) into the next group.
    if (currentOverlapGroup.length > 1) {
      remainingGroups.push([...currentOverlapGroup.slice(1)]);
      currentOverlapGroup = [currentOverlapGroup[0]]; // Reset to only the first keyword
    }
  });

  return [firstGroup, ...remainingGroups];
};
