import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { Button, Flex, Icons } from '@lib/ui';
import * as Styles from './breadcrumbs.css';
import { useBreadcrumbs } from './useBreadcrumbs';

/**
 * Breadcrumbs Root
 */
export type RootProps = PropsWithChildren & {
  className?: string;
  separator?: ReactNode;
};

const Root = ({
  children,
  className,
  separator = <Icons.ChevronRightIcon aria-hidden />,
}: RootProps) => {
  const childrenWithSeparator = useBreadcrumbs({ children, separator });

  return (
    <Flex
      gap="4px"
      alignItems="center"
      className={clsx(Styles.root, className)}
    >
      {childrenWithSeparator}
    </Flex>
  );
};

Root.displayName = 'BreadcrumbsRoot';

/**
 * Breadcrumbs Link
 */
export type LinkProps = PropsWithChildren & {
  className?: string;
  onClick?: () => void;
};
const Link = ({ children, className, onClick, ...rest }: LinkProps) => {
  const props = {
    className: clsx(className, Styles.link),
    children,
    ...rest,
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      fill="text"
      size="sm"
      variant="light"
      onClick={handleClick}
      disabled={!onClick}
      {...props}
    />
  );
};

Link.displayName = 'BreadcrumbsLink';

export { Root, Link };
