import {
  forwardRef,
  ReactNode,
  ForwardedRef,
  ButtonHTMLAttributes,
} from 'react';
import clsx from 'clsx';
import * as Styles from './icon-button.css';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Styles.IconButtonVariants & {
    icon?: ReactNode;
    label?: string;
    testId?: string;
  };

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      border = 'none',
      className,
      fill = 'none',
      icon,
      iconSize = '16',
      label,
      pill = false,
      size = 'sm',
      variant = 'subtle',
      testId,
      ...rest
    } = props;

    return (
      <button
        ref={ref}
        aria-label={label}
        type="button"
        data-testid={testId}
        className={clsx(
          Styles.iconButtonVariants({
            border,
            iconSize,
            size,
            variant,
            fill,
            pill,
          }),
          className
        )}
        {...rest}
      >
        {icon}
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';
