import { create } from 'zustand';
import { CHANNEL_ROLE, UserEntitlement } from './types';

export type ManageUserFormState = {
  entitlements: UserEntitlement[];
  defaultUserEntitlements: UserEntitlement[];
};

const INITIAL_FORM_DATA = {
  entitlements: [],
  defaultUserEntitlements: [],
};

type FormStateActions = {
  setField: (name: keyof ManageUserFormState, value: UserEntitlement[]) => void;
  getField: (name: keyof ManageUserFormState) => UserEntitlement[];
  reset: () => void;
  getIsRemovingEntitlement: () => boolean;
  getValidEntitlements: () => UserEntitlement[];
  getIsFormDirty: () => boolean;
};

export const useManageUserStore = create<
  ManageUserFormState & FormStateActions
>((set, get) => ({
  ...INITIAL_FORM_DATA,
  setField: (name, value) => set({ [name as string]: value }),
  getField: (name) => get()[name],
  getIsRemovingEntitlement: () => {
    const filteredEntitlements = get().entitlements.filter(
      ({ role }) => role === CHANNEL_ROLE.REMOVE
    );
    return filteredEntitlements.length > 0;
  },
  reset: () => {
    set({
      ...INITIAL_FORM_DATA,
    });
  },
  getValidEntitlements: () => {
    return get().entitlements.filter(
      ({ role }) => role === CHANNEL_ROLE.USER || role === CHANNEL_ROLE.ADMIN
    );
  },
  getIsFormDirty: () => {
    const defaultUserEntitlements = get().defaultUserEntitlements;
    const entitlements = get().entitlements;

    const hasChanges = entitlements.some((entitlement) => {
      return !defaultUserEntitlements.some(
        (defaultEntitlement) => defaultEntitlement.role === entitlement.role
      );
    });
    return hasChanges;
  },
}));
