import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function Header3Icon({ className, ...props }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8803 20.564C30.8803 17.9735 28.944 16.6129 26.903 16.1942C28.9701 15.7232 30.4355 14.3364 30.4355 12.0599C30.4355 9.15537 28.0281 7.19287 23.9985 7.19287C20.2043 7.19287 17.6923 9.23387 17.64 12.6617H20.4921C20.5445 10.7515 22.036 9.6002 24.1031 9.6002C26.0656 9.6002 27.4786 10.5945 27.4786 12.2692C27.4786 13.9439 26.2488 15.1475 23.763 15.1475H22.2715V17.5549H23.9461C26.5366 17.5549 27.9235 18.6539 27.9235 20.3547C27.9235 22.2387 26.3011 23.4162 24.1816 23.4162C21.8005 23.4162 20.0996 22.0817 20.0735 19.8052H17.169C17.169 23.5732 19.838 25.8235 23.9985 25.8235C28.1851 25.8235 30.8803 23.704 30.8803 20.564ZM4.01716 7.3237H1.008V25.6665H4.01716V17.7119H12.5213V25.6665H15.5305V7.3237H12.5213V15.0429H4.01716V7.3237Z"
        fill="currentColor"
      />
    </svg>
  );
}
