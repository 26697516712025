export function isDynamicImportError(errorMessage?: string) {
  if (!errorMessage) {
    return false;
  }
  return (
    errorMessage.includes('dynamically imported module') || // Chrome + Edge
    errorMessage.includes('Importing a module script failed') || // Firefox
    errorMessage.includes("'text/html' is not a valid JavaScript MIME type") // Safari
  );
}
