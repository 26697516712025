import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function ArrowDropDownIcon({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.00013 11.1813L4.36377 7.54492H11.6365L8.00013 11.1813Z"
        fill="currentColor"
      />
    </svg>
  );
}
