import { graphql } from '@lib/gql';

export const GET_VIDEOS_QUERY = graphql(`
  query GetVideos($input: ListVideosInput!) {
    videos(input: $input) {
      count
      currentPage
      currentPageSize
      numPages
      results {
        category
        channelThumbnail
        clusterIndex
        createdAt
        duration
        email
        engagement
        id
        madeForKids
        name
        performanceIndex
        predictedCategoryLevels1
        predictedCategoryLevels2
        predictedCategoryLevels3
        predictedCategoryTags1
        predictedCategoryTags2
        predictedCategoryTags3
        subscribers
        thumb
        title1
        titleLanguage
        updatedAt
        uploaded
        ucid
        v30Score
        v7Average
        v7Score
        videoId
        views
        youtubeCategory
      }
    }
  }
`);
