import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useOrganizationStore } from '@studio/stores';
import type {
  ExtractState,
  UserProfileChannel,
  UserProfileEntitlement,
} from '@studio/types';

export type ChannelSelectStore = {
  activeChannelUcid: string;
  getActiveChannel: () => UserProfileChannel;
  getAvailableChannels: () => UserProfileChannel[];
  getAvailableChannelUcids: () => string[];
  getAvailableEntitlements: () => UserProfileEntitlement[];
  isSelected: (id: string) => boolean;
  isValidChannel: (ucid: string) => boolean;
  setActiveChannelId: (ucid: string) => void;
  clearChannels: () => void;
};

export const useChannelStore = create(
  persist<ChannelSelectStore>(
    (set, get) => ({
      /**
       * The currently selected channel id
       */
      activeChannelUcid: '',

      /**
       * Synchronously retrieve all channels available to the user
       */
      getAvailableChannels: () => {
        const channels = useOrganizationStore
          .getState()
          .organizations.map((org) => org.channel);

        return channels || [];
      },

      /**
       * Synchronously retrieve the active channel
       */
      getActiveChannel: () => {
        const orgs = useOrganizationStore.getState().organizations;

        const found = orgs?.find(
          (entitlement) => entitlement.channel.ucid === get().activeChannelUcid
        )?.channel as UserProfileChannel;

        return found;
      },

      /**
       * Synchronously retrieve all available channel UCID's from user entitlements
       */
      getAvailableChannelUcids: () => {
        const orgs = useOrganizationStore.getState().organizations;

        const channelUcids = orgs.reduce(
          (channelUcids: string[], entitlement) => {
            channelUcids.push(entitlement.channel.ucid);
            return channelUcids;
          },
          []
        );

        return channelUcids || [];
      },

      /**
       * Synchronously retrieve all available user entitlements
       */
      getAvailableEntitlements: () => {
        return useOrganizationStore.getState().organizations;
      },

      /**
       * Determines if a given id is active
       */
      isSelected: (id) => {
        return id === get().activeChannelUcid;
      },

      /**
       * Determines if a given channel name is valid
       */
      isValidChannel: (ucid) => {
        const orgs = useOrganizationStore.getState().organizations;

        const found =
          orgs && orgs.some((entitlement) => entitlement.channel.ucid === ucid);

        return !!found;
      },

      /**
       * Synchronously set the active channel
       */
      setActiveChannelId: (ucid) => {
        set({ activeChannelUcid: ucid });
      },

      /**
       * Reset channels data back to default
       */
      clearChannels: () => {
        set({
          activeChannelUcid: '',
        });
      },
    }),
    {
      name: 'channels',
    }
  )
);

type Params<U> = Parameters<
  typeof useStoreWithEqualityFn<typeof useChannelStore, U>
>;

function useStoreHookFactory<U>(selector: Params<U>[1]) {
  return useStoreWithEqualityFn(useChannelStore, selector);
}

export const useActiveChannelUcid = () => {
  return useStoreHookFactory(
    (state: ExtractState<typeof useChannelStore>) => state.activeChannelUcid
  );
};
