export function dataURItoFile(dataURI: string, filename = 'untitled'): File {
  // Split the data URI to get the base64 string
  const byteString = window.atob(dataURI.split(',')[1]);

  // Get the mime type from the data URI
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // Convert base64 string to an array of 8-bit unsigned integers
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the array buffer
  const blob = new Blob([ab], { type: mimeString });

  // Convert blob to file
  return new File([blob], filename, { type: mimeString });
}
