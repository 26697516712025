type EnvironmentType = 'local' | 'development' | 'production';

export function getEnvironment(): EnvironmentType {
  if (isLocal()) {
    return 'local';
  }
  return import.meta.env.MODE as EnvironmentType;
}

export function isLocal(): boolean {
  return (
    // Check if we're in development mode
    import.meta.env.MODE === 'development' &&
    // Check if the hostname is localhost or 127.0.0.1
    (window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1')
  );
}
export const isDevelopment = () => getEnvironment() === 'development';
export const isProduction = () => getEnvironment() === 'production';
