import { useState } from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { Icons, IconButton } from '@lib/ui';
import { Text } from '../text';
import * as Styles from './ideation-element.css';

enum Expression {
  NEUTRAL = 'neutral',
  HAPPY = 'happy',
  EXCITED = 'excited',
  SURPRISED = 'surprised',
  ANNOYED = 'annoyed',
  DISAPPOINTED = 'disappointed',
  SAD = 'sad',
  SHOCKED = 'shocked',
  ANGRY = 'angry',
}

const expressionMap = {
  [Expression.ANGRY]: '😡',
  [Expression.ANNOYED]: '🤨',
  [Expression.DISAPPOINTED]: '😕',
  [Expression.EXCITED]: '😆',
  [Expression.HAPPY]: '😀',
  [Expression.NEUTRAL]: '😐',
  [Expression.SAD]: '😢',
  [Expression.SHOCKED]: '😱',
  [Expression.SURPRISED]: '😲',
};

interface Props {
  onExpression: (emotion: Expression) => void;
  onClose: () => void;
}

export const ExpressionList = ({ onExpression, onClose }: Props) => {
  const [selected, setSelected] = useState<Expression>();

  const handleExpression = (expression: Expression) => {
    onExpression(expression);
    setSelected(expression);
  };

  return (
    <div style={{ width: 'max-content' }}>
      <IconButton
        label="Close Panel"
        icon={<Icons.CloseIcon aria-hidden />}
        iconSize="18"
        size="xs"
        fill="none"
        variant="subtle"
        onClick={onClose}
      />
      <ul className={Styles.expressionList}>
        {Object.values(Expression).map((value) => (
          <li
            key={value}
            className={clsx(
              Styles.expressionListItem,
              selected === value && 'selected'
            )}
          >
            <button onClick={() => handleExpression(value)}>
              <span className={Styles.mood}>{expressionMap[value]}</span>
              <Text textTransform="capitalize">
                <Trans>{value}</Trans>
              </Text>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
