import { useRef, useCallback } from 'react';

export function useAutoFocus() {
  const ref = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

  const setFocus = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const callbackRef = useCallback(
    (node: HTMLTextAreaElement | HTMLInputElement | null) => {
      if (node) {
        node.focus();
      }
      ref.current = node;
    },
    []
  );

  return { ref: callbackRef, setFocus };
}
