import { PROJECT_STATUS, PROJECT_STATUS_ITEMS } from '@lib/types';
import { PublishDateFilter, ScopeFilter, StatusFilter } from './';

const statusItemsSansPublished = PROJECT_STATUS_ITEMS.filter(
  (item) => item.value !== PROJECT_STATUS.PUBLISHED
);

export function FiltersUnpublished() {
  return (
    <>
      <StatusFilter items={statusItemsSansPublished} />
      <ScopeFilter />
      <PublishDateFilter />
    </>
  );
}
