export const buildDateRangeDisplayString = (
  date_start?: string,
  date_end?: string
) => {
  if (date_start && date_end) {
    return `${date_start} - ${date_end}`;
  }
  if (date_start) {
    return `after ${date_start}`;
  }
  if (date_end) {
    return `before ${date_end}`;
  }
  return 'on any date';
};
