import { create } from 'zustand';
import { Team } from '@lib/gql/graphql';

type TeamStore = {
  team?: Team;
  setTeam: (team?: Team) => void;
};

export const useTeamStore = create<TeamStore>((set, get) => ({
  team: undefined,
  setTeam: (team) => set({ team }),
}));
