import React, {
  forwardRef,
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react';
import * as Styles from './carousel.css';

type EmblaCarouselType = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type EmblaOptionsType = UseCarouselParameters[0];

/**
 * Carousel Root
 */
type RootProps = {
  children?: ReactNode;
  className?: string;
};

export const Root = (props: RootProps) => {
  const { children, className } = props;

  return <div className={`embla ${Styles.root} ${className}`}>{children}</div>;
};

Root.displayName = 'CarouselRoot';

/**
 * Carousel Container
 */
type ContainerProps = {
  children?: ReactNode;
  className?: string;
  options?: EmblaOptionsType;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const { children, className } = props;

    return (
      <div className={`embla__viewport ${Styles.viewport}`} ref={ref}>
        <div className={`embla__container ${Styles.container} ${className}`}>
          {children}
        </div>
      </div>
    );
  }
);

Container.displayName = 'CarouselContainer';

/**
 * Carousel Slide
 */
type SlideProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
  style?: HTMLProps<HTMLDivElement>['style'];
};

export const Slide = (props: SlideProps) => {
  const { className, children, id, style } = props;
  return (
    <div
      id={id}
      className={`embla__slide ${Styles.slide} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};
Slide.displayName = 'CarouselSlide';

/**
 * Carousel Controls
 */
type ControlsProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
};

export const Controls = (props: ControlsProps) => {
  const { className, children } = props;
  return <div className={`embla__controls ${className}`}>{children}</div>;
};
Controls.displayName = 'CarouselControls';

/**
 * Carousel Previous Next Buttons
 */
type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    if (!emblaApi) return;
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};
