import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum ImageModel {
  Flux = 'flux',
  Kolors = 'kolors',
  Hyper = 'hyper',
}

type IdeationOverridesState = {
  imageModelOverride: ImageModel | null;
};

type IdeationOverridesActions = {
  setImageModelOverride: (imageModelOverride: ImageModel | null) => void;
};

export const useIdeationOverridesStore = create<
  IdeationOverridesState & IdeationOverridesActions
>()(
  devtools(
    (set) => ({
      imageModelOverride: null,
      setImageModelOverride: (imageModelOverride: ImageModel | null) =>
        set(
          () => ({ imageModelOverride }),
          false,
          'ideation-model-overrides/set-image-model'
        ),
    }),
    { name: 'ideation-model-overrides' }
  )
);
