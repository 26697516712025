import {
  ChangeEvent,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { ProjectSectionHeader } from '@studio/features/projects';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { PROJECT_ELEMENT } from '@lib/types';
import { IdeationElement, TextArea, Toast } from '@lib/ui';
import { DEFAULT_PROJECT_TITLE_MAX_SIZE } from '../../constants';
import { useInspirationPanelAnalytics } from './use-inspriration-panel-analytics';

export const PanelTitle = forwardRef(
  (
    { onBrainstorm, ideating, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const type = PROJECT_ELEMENT.TITLE;

    const { t } = useTranslation();

    const { toast } = Toast.useToast();

    const { projectStore } = useContext(ProjectStoreContext);

    const { updateElement, elementState } = useStore(
      projectStore,
      (state: ProjectStateType) => ({
        elementState: state[type],
        updateElement: state.updateElement,
      })
    );

    const { onTitleChange } = useInspirationPanelAnalytics();

    const [createMode, setCreateMode] = useState<boolean>(false);

    const primaryContent =
      (elementState.options &&
        elementState.options.find((item) => item.id === elementState.primary)
          ?.content) ||
      '';

    const [value, setValue] = useState(primaryContent);

    // Use to store previous value, for analytics
    const [localValue, setLocalValue] = useState(primaryContent);

    const titleTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      setValue(primaryContent);
    }, [primaryContent]);

    const updateElementState = (value: string) => {
      const uuid = createMode ? uuidv4() : elementState.primary || '';
      updateElement(type, value.trim(), uuid);
      setCreateMode(false);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      updateElementState(event.target.value);

      if (event.target.value.length >= DEFAULT_PROJECT_TITLE_MAX_SIZE) {
        toast({
          message: t(
            `Title has a max character limit of ${DEFAULT_PROJECT_TITLE_MAX_SIZE}`
          ),
          id: 'project-title-max',
        });
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      onTitleChange(localValue, inputValue);
      setLocalValue(inputValue);
      if (!inputValue || inputValue === value) {
        return;
      }
      updateElementState(value);
    };

    return (
      <div ref={ref}>
        <ProjectSectionHeader title={t('Title')} />
        <TextArea
          autoHeight
          resize="none"
          ref={titleTextAreaRef}
          variant="dark"
          border="none"
          fill="sheer"
          radii="sm"
          size="sm"
          rows={1}
          placeholder={t(`Add ${type}`)}
          preventNewLine
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={DEFAULT_PROJECT_TITLE_MAX_SIZE}
        />
      </div>
    );
  }
);
