import { useState, useCallback } from 'react';

type CopyToClipboardFn = (text: string) => Promise<void>;

export function useCopyToClipboard(): [CopyToClipboardFn, boolean] {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy: CopyToClipboardFn = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsCopied(false);
      throw e;
    } finally {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, []);

  return [copy, isCopied];
}
