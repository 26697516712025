import { ForwardedRef, forwardRef, type ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icons } from '@lib/ui';

export type NewProjectButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children'
>;

export const NewProjectButton = forwardRef(
  (props: NewProjectButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { t } = useTranslation();

    return (
      <Button
        ref={ref}
        variant="primary"
        fill="solid"
        pill
        size="md"
        {...props}
      >
        <Icons.PlusIcon aria-hidden />
        <span>{t('New Project')}</span>
      </Button>
    );
  }
);

NewProjectButton.displayName = 'NewProjectButton';
