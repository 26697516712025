export const OUTLIERS_FILTER_SOURCE_OPEN = 'outlier_filter_source_open';
export const OUTLIERS_FILTER_INDEX_OPEN = 'outlier_filter_index_open';
export const OUTLIERS_FILTER_UPLOADED_OPEN = 'outlier_filter_uploaded_open';
export const OUTLIERS_FILTER_SUBS_OPEN = 'outlier_filter_subs_open';
export const OUTLIERS_FILTER_MORE_OPEN = 'outlier_filter_more_open';
export const OUTLIERS_FILTER_SOURCE_CHANGE = 'outlier_filter_source_change';
export const OUTLIERS_FILTER_INDEX_CHANGE = 'outlier_filter_index_change';
export const OUTLIERS_FILTER_UPLOADED_CHANGE = 'outlier_filter_uploaded_change';
export const OUTLIERS_FILTER_SUBS_CHANGE = 'outlier_filter_subs_change';
export const OUTLIERS_FILTER_VIDEO_VIEWS_CHANGE = 'outlier_filter_videoviews_change';
export const OUTLIERS_FILTER_VIDEO_DURATION_CHANGE = 'outlier_filter_videoduration_change';
export const OUTLIERS_FILTER_MADE4KIDS_CHANGE = 'outlier_filter_made4kids_change';
export const OUTLIERS_FILTER_SEARCH_CHANGE = 'outlier_filter_search_change';
export const OUTLIERS_FILTER_RESET = 'outlier_filter_reset';
export const OUTLIERS_FAVOURITE_BOARD_OPEN = 'favourite_board_open';
export const OUTLIERS_LAYOUT_TOGGLE_CLICK = 'outlier_layout_toggle';
export const OUTLIERS_CHANNEL_SORT_CLICK = 'outlier_gridsort_channel_sort_click';
export const OUTLIERS_VIDEO_SORT_CLICK = 'outlier_gridsort_video_sort_click';
export const OUTLIERS_OUTLIER_INDEX_SORT_CLICK = 'outlier_gridsort_outlierindex_sort_click';
export const OUTLIERS_UPLOAD_SORT_CLICK = 'outlier_gridsort_upload_sort_click';
export const OUTLIERS_VIEWS_SORT_CLICK = 'outlier_gridsort_views_sort_click';
export const OUTLIERS_FILTER_PRESET_TOGGLE = 'outlier_preset_toggle';
export const OUTLIERS_FILTERS_MENU_RESET_ALL_CLICK = 'outlier_filters_menu_reset_all_click';
export const OUTLIERS_RESET_FILTER_CLICK = 'outlier_reset_filter_click';
export const OUTLIERS_FILTERS_MENU_TOGGLE = 'outlier_filter_menu_toggle';

// TODO: To be deleted once we turn on general availability for this gate:
// https://console.statsig.com/7fyNhUMuM2CYq5H8ExnHUz/gates/outliers_filter_presets
export const OUTLIERS_LIST_CHANNEL_HEADER_SORT_CLICK = 'outlier_list_channelheader_sort_click';
export const OUTLIERS_LIST_SUBS_HEADER_SORT_CLICK = 'outlier_list_subsheader_sort_click';
export const OUTLIERS_LIST_VIDEO_HEADER_SORT_CLICK = 'outlier_list_videoheader_sort_click';
export const OUTLIERS_LIST_OUTLIERS_INDEX_HEADER_SORT_CLICK = 'outlier_list_outlierindexheader_sort_click';
export const OUTLIERS_LIST_UPLOAD_HEADER_SORT_CLICK = 'outlier_list_uploadheader_sort_click';
export const OUTLIERS_LIST_V7_VIEWS_HEADER_SORT_CLICK = 'outlier_list_v7viewsheader_sort_click';
export const OUTLIERS_LIST_VIEWS_HEADER_SORT_CLICK = 'outlier_list_viewsheader_sort_click';
export const OUTLIERS_LIST_CATEGORY_HEADER_SORT_CLICK = 'outlier_list_categoryheader_sort_click';
export const OUTLIERS_ADD_VIDEO_TO_FAVORITES_CLICK = 'outlier_add_video_to_favourite_click';
export const OUTLIERS_ADD_CHANNEL_TO_FAVORITES_CLICK = 'outlier_add_channel_to_favourite_click';
export const OUTLIERS_REMOVE_VIDEO_FROM_FAVORITES_CLICK = 'outlier_remove_video_to_favourite_click';
export const OUTLIERS_REMOVE_CHANNEL_FROM_FAVORITES_CLICK = 'outlier_remove_channel_to_favourite_click';
export const OUTLIERS_VIDEO_LINK_TO_YOUTUBE_CLICK = 'outlier_video_link_to_youtube_click';
export const OUTLIERS_CHANNEL_LINK_TO_YOUTUBE_CLICK = 'outlier_channel_link_to_youtube_click';
