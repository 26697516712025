import { forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
  root,
  overlay,
  container,
  header,
  body,
  PanelVariants,
  panelVariants,
} from './panel.css';

type RootProps = PropsWithChildren &
  PanelVariants & {
    className?: string;
    isVisible: boolean;
    fullscreen?: boolean;
    transition?: boolean;
  };

const Root = forwardRef<HTMLDivElement, RootProps>((props, ref) => {
  const {
    className,
    children,
    isVisible,
    variant = 'subtle',
    type,
    fullscreen = false,
    transition = true,
  } = props;

  return (
    <div
      ref={ref}
      className={clsx(
        panelVariants({ variant, type, transition }),
        root,
        { collapsed: !isVisible },
        { fullscreen: isVisible && fullscreen },
        className
      )}
    >
      {children}
    </div>
  );
});
Root.displayName = 'PanelRoot';

type OverlayProps = {
  className?: string;
  onClick?: () => void;
};

const Overlay = (props: OverlayProps) => {
  const { className, onClick } = props;
  return <div className={clsx(overlay, className)} onClick={onClick} />;
};
Overlay.displayName = 'PanelOverlay';

type ContainerProps = PropsWithChildren & {
  className?: string;
};

const Container = (props: ContainerProps) => {
  const { className, children } = props;
  return <div className={clsx(container, className)}>{children}</div>;
};
Container.displayName = 'PanelContainer';

type HeaderProps = PropsWithChildren & {
  className?: string;
};

const Header = (props: HeaderProps) => {
  const { className, children } = props;
  return <div className={clsx(className, header)}>{children}</div>;
};

type BodyProps = PropsWithChildren & {
  className?: string;
};

const Body = forwardRef<HTMLDivElement, BodyProps>((props, ref) => {
  const { className, children } = props;
  return (
    <div ref={ref} className={clsx(body, className)}>
      {children}
    </div>
  );
});

Body.displayName = 'PanelBody';

export { Root, Container, Header, Overlay, Body };
