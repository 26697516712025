import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { type ExtractState } from '@studio/types';

export type KirbyStore = {
  data: string[];
  setData: (data: string[] | undefined) => void;
};

export const useKirbyStore = create<KirbyStore>()(
  devtools(
    persist(
      (set) => ({
        data: [],
        setData: (data) => {
          set(
            () => ({
              data,
            }),
            false,
            'kirby/set-data'
          );
        },
      }),
      {
        name: 'kirby',
      }
    ),
    { name: 'kirby' }
  )
);

type Params<U> = Parameters<
  typeof useStoreWithEqualityFn<typeof useKirbyStore, U>
>;

function useStoreHookFactory<U>(selector: Params<U>[1]) {
  return useStoreWithEqualityFn(useKirbyStore, selector);
}

export const useKirbyData = () => {
  return useStoreHookFactory(
    (state: ExtractState<typeof useKirbyStore>) => state.data
  );
};
