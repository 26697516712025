import { ForwardedRef, forwardRef } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import { Icons } from '../..';
import * as Styles from './select.css';

export type SelectProps = SelectPrimitive.SelectProps &
  Styles.MenuVariants & {
    className?: string;
    disabled?: boolean;
  };

const Root = forwardRef(
  (
    { className, disabled = false, ...props }: SelectProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={clsx(Styles.root, className)} ref={ref}>
        <SelectPrimitive.Root {...props} />
      </div>
    );
  }
);

export type TriggerProps = SelectPrimitive.SelectTriggerProps &
  Styles.TriggerVariants & {
    label?: string;
  };

const Trigger = forwardRef(
  (
    {
      autoWidth = false,
      border = 'none',
      className,
      fill = 'solid',
      fullWidth = false,
      label,
      size = 'sm',
      square = false,
      variant = 'dark',
      ...props
    }: TriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement> | undefined
  ) => (
    <SelectPrimitive.Trigger
      ref={forwardedRef}
      aria-label={label || 'Select option'}
      className={clsx(
        Styles.triggerVariants({
          autoWidth,
          border,
          fill,
          fullWidth,
          size,
          square,
          variant,
        }),
        className
      )}
      {...props}
    />
  )
);

const Content = forwardRef(
  (
    {
      className,
      position = 'popper',
      variant = 'subtle',
      ...props
    }: SelectPrimitive.SelectContentProps & Styles.MenuVariants,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Content
      ref={forwardedRef}
      position={position}
      className={clsx(Styles.menuVariants({ variant }), className)}
      {...props}
    />
  )
);

const Label = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectLabelProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Label
      ref={forwardedRef}
      className={clsx(Styles.label, className)}
      {...props}
    />
  )
);

const Separator = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectSeparatorProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Separator
      ref={forwardedRef}
      className={clsx(Styles.separator, className)}
      {...props}
    />
  )
);

export type SelectItemProps = SelectPrimitive.SelectItemProps;

const Item = forwardRef(
  (
    { className, ...props }: SelectItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <SelectPrimitive.Item
        ref={forwardedRef}
        className={clsx(Styles.item, className)}
        {...props}
      />
    );
  }
);

const ItemIndicator = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectItemIndicatorProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <SelectPrimitive.ItemIndicator
        ref={forwardedRef}
        className={clsx(Styles.itemIndicator, className)}
        {...props}
      />
    );
  }
);

const ItemWithIndicator = forwardRef(
  (
    { className, children, ...props }: SelectItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <Item {...props}>
        <ItemText>{children}</ItemText>
        <ItemIndicator>
          <Icons.CheckmarkIcon aria-hidden />
        </ItemIndicator>
      </Item>
    );
  }
);

const SelectIcon = ({
  className,
  ...props
}: SelectPrimitive.SelectIconProps) => {
  return (
    <SelectPrimitive.Icon className={className} {...props}>
      <Icons.ArrowDropDownIcon aria-hidden />
    </SelectPrimitive.Icon>
  );
};

const Group = SelectPrimitive.Group;
const Icon = SelectPrimitive.Icon;
const ItemText = SelectPrimitive.ItemText;
const Portal = SelectPrimitive.Portal;
const Value = SelectPrimitive.Value;
const Viewport = SelectPrimitive.Viewport;

export {
  Content,
  Group,
  Icon,
  Item,
  ItemIndicator,
  ItemWithIndicator,
  ItemText,
  Label,
  Portal,
  Root,
  SelectIcon,
  Separator,
  Trigger,
  Value,
  Viewport,
};
