import { PropsWithChildren, forwardRef } from 'react';
import clsx from 'clsx';
import { Icons, Toolbar, IconButton, IconButtonProps } from '@lib/ui';
import { PaginationProvider, usePagination } from './pagination-context';
import * as Styles from './pagination.css';

type PaginationProps = PropsWithChildren &
  Styles.PaginationVariants & {
    className?: string;
    currentPage: number;
    totalPages: number;
    nextPage?: boolean;
    prevPage?: boolean;
    onPageChange?: (page: number) => void;
  };

const PaginationRoot = forwardRef<HTMLDivElement, PaginationProps>(
  ({ children, className, color = 'subtle', ...contextProps }, ref) => {
    return (
      <PaginationProvider {...contextProps}>
        <Toolbar
          className={clsx(Styles.paginationVariants({ color }), className)}
        >
          {children}
        </Toolbar>
      </PaginationProvider>
    );
  }
);

const PaginationPageInfo = () => {
  const { currentPage, totalPages } = usePagination();
  return (
    <div className={Styles.pageButton}>
      {currentPage} / {totalPages}
    </div>
  );
};

export const Pagination = {
  Root: PaginationRoot,
  FastPreviousButton: (props: Omit<IconButtonProps, 'onClick'>) => {
    const { onPageChange, prevPage } = usePagination();
    return (
      <IconButton
        {...props}
        fill="solid"
        variant="subtle"
        disabled={prevPage}
        label="Fast Previous"
        onClick={() => onPageChange?.(1)}
        icon={<Icons.FastFowardArrowLeftIcon />}
      />
    );
  },
  PreviousButton: (props: Omit<IconButtonProps, 'onClick'>) => {
    const { currentPage, onPageChange, prevPage } = usePagination();
    return (
      <IconButton
        {...props}
        fill="solid"
        variant="subtle"
        label="Previous"
        disabled={prevPage}
        icon={<Icons.KeyboardArrowLeftIcon />}
        onClick={() => onPageChange?.(currentPage - 1)}
      />
    );
  },
  NextButton: (props: Omit<IconButtonProps, 'onClick'>) => {
    const { currentPage, onPageChange, nextPage } = usePagination();
    return (
      <IconButton
        {...props}
        fill="solid"
        label="Next"
        variant="subtle"
        disabled={nextPage}
        icon={<Icons.KeyboardArrowRightIcon />}
        onClick={() => onPageChange?.(currentPage + 1)}
      />
    );
  },
  FastNextButton: (props: Omit<IconButtonProps, 'onClick'>) => {
    const { totalPages, onPageChange, nextPage } = usePagination();
    return (
      <IconButton
        {...props}
        fill="solid"
        variant="subtle"
        label="Fast Next"
        disabled={nextPage}
        icon={<Icons.FastFowardArrowRightIcon />}
        onClick={() => onPageChange?.(totalPages)}
      />
    );
  },
  PageInfo: PaginationPageInfo,
};
