import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { UserProfile } from '@studio/types';
import { getEnvironment } from '@studio/utils/env-utils';

Sentry.init({
  environment: getEnvironment(),
  dsn: import.meta.env.VITE_SENTRY_STUDIO_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function setSentryUser(user: UserProfile | null) {
  if (user) {
    Sentry.setUser({
      email: user.email,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      auth0Id: user.auth0Id,
      permissions: user.permissions,
      entitlements: user.entitlements.map((entitlement) => ({
        id: entitlement.id,
        role: entitlement.role,
        source: entitlement.source,
        channelId: entitlement.channelId,
        organizationId: entitlement.organizationId,
        channelName: entitlement.channel.name,
        channelUCID: entitlement.channel.ucid,
      })),
      organizationUsers: user.organizationUsers.map((organizationUser) => ({
        id: organizationUser.id,
        role: organizationUser.role,
        organizationId: organizationUser.organizationId,
        organizationName: organizationUser.organization.name,
        isSpotterOrganization:
          organizationUser.organization.isSpotterOrganization,
      })),
    });
  } else {
    Sentry.setUser(null);
  }
}
