import { useState } from 'react';
import { api } from '@studio/lib';
import { type Customer } from '@studio/types';
import { usePartnerStackParams } from './use-partner-stack-params';

export const useCreateStripeCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const marketingParams = usePartnerStackParams();

  interface CreateStripeCustomerInput {
    email: string;
    orgId: string;
    name?: string;
  }

  const createCustomer = async ({
    email,
    orgId,
    name = 'Customer created via API',
  }: CreateStripeCustomerInput) => {
    setIsLoading(true);
    setError(null);

    try {
      // First, check if the customer exists
      try {
        const [existingCustomer] = await api.boo.get<Customer[]>(
          `/api/stripe/customer?email=${email}&orgId=${orgId}`
        );

        if (existingCustomer) {
          setCustomer(existingCustomer);
          return existingCustomer;
        }
      } catch (err) {
        // Continue to create a new customer if 404
      }

      const newCustomer = await api.boo.post<Customer>(`/api/stripe/customer`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          orgId,
          name,
          metadata: marketingParams,
        },
      });

      setCustomer(newCustomer);
      return newCustomer;
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'An unexpected error occurred'
      );
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { createCustomer, customer, isLoading, error };
};
