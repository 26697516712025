import { useEffect } from 'react';
import { useParamStore } from '@studio/stores';

export type PartnerStackUTMParams = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  plan: string;
  contactid: string;
  pkey: string;
};

export const PARTNER_STACK_KEY = 'partnerstack_params';

const PARTNER_STACK_PARAMS: PartnerStackUTMParams = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  utm_term: 'utm_term',
  plan: 'planid',
  contactid: 'contactid',
  pkey: 'pkey',
};

// Helper function to get values from localStorage
const getParamsFromLocalStorage = (): PartnerStackUTMParams => {
  if (typeof window !== 'undefined') {
    const storedParams = localStorage.getItem(PARTNER_STACK_KEY);
    if (storedParams) {
      return JSON.parse(storedParams);
    }
  }
  // Return empty values if nothing is found
  return {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    plan: '',
    contactid: '',
    pkey: '',
  };
};

// Helper function to save values to localStorage
const saveParamsToLocalStorage = (params: PartnerStackUTMParams) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(PARTNER_STACK_KEY, JSON.stringify(params));
  }
};

export const usePartnerStackParams = (): PartnerStackUTMParams => {
  const { params: paramsStore } = useParamStore();

  // Get parameters from paramsStore or localStorage
  const storedParams = getParamsFromLocalStorage();
  const utm_source =
    paramsStore[PARTNER_STACK_PARAMS.utm_source] || storedParams.utm_source;
  const utm_medium =
    paramsStore[PARTNER_STACK_PARAMS.utm_medium] || storedParams.utm_medium;
  const utm_campaign =
    paramsStore[PARTNER_STACK_PARAMS.utm_campaign] || storedParams.utm_campaign;
  const utm_content =
    paramsStore[PARTNER_STACK_PARAMS.utm_content] || storedParams.utm_content;
  const utm_term =
    paramsStore[PARTNER_STACK_PARAMS.utm_term] || storedParams.utm_term;
  const plan = paramsStore[PARTNER_STACK_PARAMS.plan] || storedParams.plan;
  const contactid =
    paramsStore[PARTNER_STACK_PARAMS.contactid] || storedParams.contactid;
  const pkey = paramsStore[PARTNER_STACK_PARAMS.pkey] || storedParams.pkey;

  // Save the fetched params to localStorage
  useEffect(() => {
    saveParamsToLocalStorage({
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      plan,
      contactid,
      pkey,
    });
  }, [
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    plan,
    contactid,
    pkey,
  ]);

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    plan,
    contactid,
    pkey,
  };
};
