import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { trackEvent } from '@studio/lib/heap';

export type ParamOptions = { withHistory: boolean };
export type TypedParams<K extends string> = Partial<Record<K, string>>;
export type ParamsToUpdate<K extends string> =
  | TypedParams<K>
  | ((params?: { [k: string]: string }) => { [k: string]: string });

// These types are used externally
export type PageParams<T> = T[keyof T];
export type ParamsObject = Record<string, string>;

/**
 * # Overview
 *
 * React hook for managing URL query parameters with optional event tracking.
 *
 * # API
 *
 * `params`
 * The current query parameters as an object.
 *
 * `setParams`
 * Function to update query parameters. Accepts an object or function.
 *
 * `setParamsTracked`:
 * Function to update query parameters and track an event. Accepts an object or function.
 *
 * `removeParam`
 * Function to remove a query parameter.
 *
 * `removeParamTracked`
 * Function to remove a query parameter and track an event.
 *
 * # Examples
 *
 * ### Usage in a component
 *
 * ```ts
 * const { params, setParams, removeParam } = useParamStore<'sort' | 'filter'>({ sort: 'asc' });
 * ```
 *
 * ### Access current parameters
 *
 * ```ts
 * console.log(params); // { filter: 'all', sort: 'asc' }
 * ```
 *
 * ### Update params
 *
 * ```ts
 * setParams({ sort: 'desc' });
 * ```
 *
 * ### Update params with a function
 *
 * ```ts
 * setParams((params) => ({ ...params, page: page.current + 1 }));
 * ```
 *
 * ### Remove a param
 *
 * ```ts
 * removeParam('sort');
 * ```
 *
 * ### Update params and track an event
 *
 * ```ts
 * setParamsTracked('sort_changed', { sort: 'desc' });
 * ```
 *
 * ### Remove a param and track an event
 *
 * ```ts
 * removeParamTracked('filter_toggled', 'biggest_outliers');
 * ```
 */
export const useParamStore = <K extends string>(
  defaultParams?: Record<K, string>
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Convert URLSearchParams to object
  const currentParams = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );

  const setParams = useCallback(
    (
      newParams: ParamsToUpdate<K>,
      options: ParamOptions = { withHistory: false }
    ) => {
      if (!newParams) {
        return;
      }

      if (typeof newParams === 'function') {
        setSearchParams(newParams(currentParams), {
          replace: !options?.withHistory,
        });
        return;
      }

      setSearchParams(
        { ...currentParams, ...newParams },
        { replace: !options?.withHistory }
      );
    },
    [currentParams, setSearchParams]
  );

  const setParamsTracked = useCallback(
    (
      eventName: string,
      paramsToSet: TypedParams<K>,
      options: ParamOptions = { withHistory: false }
    ) => {
      if (!paramsToSet) {
        return;
      }
      setParams(paramsToSet, options);
      trackEvent(eventName, paramsToSet as ParamsObject);
    },
    [setParams]
  );

  const removeParam = useCallback(
    (key: K, options?: ParamOptions) => {
      if (searchParams.has(key)) {
        searchParams.delete(key);
        setSearchParams(searchParams, { replace: !options?.withHistory });
      }
    },
    [searchParams, setSearchParams]
  );

  const removeParamTracked = useCallback(
    (eventName: string, key: K, options?: ParamOptions) => {
      removeParam(key, options);
      trackEvent(eventName, currentParams);
    },
    [currentParams, removeParam]
  );

  // Set default query params and update location (updates address bar)
  useEffect(() => {
    if (defaultParams) {
      setSearchParams(
        (params) => ({ ...defaultParams, ...Object.fromEntries(params) }),
        {
          replace: true,
        }
      );
    }
  }, [defaultParams]);

  return {
    params: currentParams,
    setParams,
    setParamsTracked,
    removeParam,
    removeParamTracked,
  };
};
