import { PropsWithChildren, forwardRef } from 'react';
import clsx from 'clsx';
import * as Styles from './toolbar.css';

type Props = PropsWithChildren & Styles.Variants & { className?: string };

export const Toolbar: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ children, className, color = 'subtle', size = 'lg' }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(Styles.variants({ size, color }), className)}
      >
        {children}
      </div>
    );
  }
);
