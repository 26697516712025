import { useEffect, useMemo } from 'react';
import { useState } from 'react';

export function useMeasure() {
  const defaultState = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  };
  // NOTE: resizeRef uses useState to trigger useEffect
  const [resizeRef, setResizeRef] = useState<Element | null>(null);
  const [rect, setRect] = useState(defaultState);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useEffect(() => {
    if (resizeRef) {
      if (resizeRef) {
        observer.observe(resizeRef as Element);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [resizeRef, observer]);
  return { ref: setResizeRef, rect };
}
