import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { PowerKeywordData } from '@lib/types';
import { Tooltip } from '@lib/ui';
import { getPowerKeywordTooltipVariant } from './get-power-keyword-tooltip-variant';
import * as Styles from './power-keyword-marker.css';
import { PowerKeywordTooltip } from './power-keyword-tooltip';

export type OverlapType = 'single' | 'overlapping';

interface PowerKeywordMarkerProps {
  overlapType: OverlapType;
  hidden?: boolean;
  data: PowerKeywordData;
  onPowerKeywordHover: () => void;
  onPowerKeywordClick?: (keyword: string) => void;
}

export const PowerKeywordMarker = ({
  children,
  overlapType,
  hidden = false,
  data,
  onPowerKeywordHover,
  onPowerKeywordClick,
}: PropsWithChildren<PowerKeywordMarkerProps>) => {
  const variant = getPowerKeywordTooltipVariant(data.performanceRatio);

  if (hidden) {
    return null;
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root
        onOpenChange={(isOpen) => {
          if (isOpen) {
            onPowerKeywordHover();
          }
        }}
        delayDuration={0}
      >
        <Tooltip.Trigger asChild>
          <span
            className={clsx(
              Styles.powerKeywordMarker[overlapType],
              Styles.powerKeywordUnderline[variant]
            )}
          >
            {children}
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <PowerKeywordTooltip
            data={data}
            keyword={children as string}
            onPowerKeywordClick={onPowerKeywordClick}
          />
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
