import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Icons, Text, TextArea } from '@lib/ui';
import * as Styles from './ideation-element.css';

type Props = {
  onCancel: () => void;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  minimized?: boolean;
  setThisButMinimized: Dispatch<SetStateAction<boolean>>;
  setThisButValue: Dispatch<SetStateAction<string>>;
  thisButValue: string;
};

export const ThisButEditor = ({
  onChange,
  onCancel,
  onSubmit,
  minimized,
  setThisButMinimized,
  setThisButValue,
  thisButValue,
}: Props) => {
  const { t } = useTranslation();

  const textAreaRef = useRef<HTMLTextAreaElement>(null); // Reference to the TextArea

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const currentValue = event.target.value;
      setThisButValue(currentValue);
      onChange?.(currentValue);
    },
    [onChange]
  );

  const handleSubmit = useCallback(() => {
    if (thisButValue.length > 0) {
      onSubmit(thisButValue);
    }
  }, [thisButValue, onSubmit]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    // Previous values should not persist between mounts
    setThisButValue('');
    // Keep the cursor at the end of the value
    if (textAreaRef.current) {
      const input = textAreaRef.current;
      const valueLength = input.value.length;
      if (input) {
        input.setSelectionRange(valueLength, valueLength);
      }
    }
  }, []);

  if (minimized) {
    return (
      <button
        className={Styles.thisButMinimized}
        onClick={() => setThisButMinimized(false)}
      >
        <span className={Styles.thisButPreview}>{thisButValue}</span>
      </button>
    );
  }

  return (
    <div className={Styles.thisBut}>
      <TextArea
        ref={textAreaRef}
        className={Styles.editor}
        value={thisButValue}
        onChange={handleChange}
        fill="none"
        autoFocus
        resize="none"
        border="none"
        placeholder={t('This, but...')}
        maxLength={1000}
        radii="xs"
        preventNewLine
        onKeyDown={handleKeyDown}
      />
      <Flex justifyContent="space-between" alignItems="center">
        <button
          onClick={onCancel}
          className={Styles.editorButton}
          aria-label={t('cancel prompt')}
        >
          <Text textDecoration="underline">{t('Cancel')}</Text>
        </button>
        <button
          disabled={thisButValue.length === 0}
          onClick={handleSubmit}
          className={Styles.editorSubmit}
          aria-label={t('submit prompt')}
        >
          <Icons.KeyboardArrowRightIcon aria-hidden />
        </button>
      </Flex>
    </div>
  );
};
