import { create } from 'zustand';
import { SavedItemsType } from '@lib/types';

export type SavedItemsStore = {
  sortOrder: string;
  setSortOrder: (value: string) => void;
  typeCheckFilter: {
    outliers: boolean;
    concept: boolean;
    title: boolean;
    thumbnail: boolean;
  };
  setTypeCheckFilter: (type: SavedItemsType, value: boolean) => void;
  dateRangeFilter: {
    createdAtGte: string;
    createdAtLte: string;
  };
  setDateRangeFilter: (value: { [key: string]: string }) => void;
  removeDateRangeFilter: (type: string) => void;
  fullscreen: boolean;
  toggleFullscreen: () => void;
  visible: boolean;
  toggleVisibility: () => void;
  selections: string[];
  onSelect: (id: string) => void;
  onDeSelect: (id: string) => void;
  isConfirmationModalOpen: boolean;
  toggleConfirmationModal: (open?: boolean) => void;
};

export const useSavedItemsStore = create<SavedItemsStore>((set, get) => ({
  sortOrder: '-createdAt',
  setSortOrder: (value) => set({ sortOrder: value }),
  typeCheckFilter: {
    outliers: true,
    concept: true,
    title: true,
    thumbnail: true,
  },
  setTypeCheckFilter: (type, value) => {
    set((state) => ({
      typeCheckFilter: {
        ...state.typeCheckFilter,
        [type]: value,
      },
    }));
  },
  dateRangeFilter: {
    createdAtGte: '',
    createdAtLte: '',
  },
  setDateRangeFilter: (value) => {
    set((state) => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        ...value,
      },
    }));
  },
  removeDateRangeFilter: (type) => {
    set((state) => ({
      dateRangeFilter: {
        ...state.dateRangeFilter,
        [type]: '',
      },
    }));
  },

  fullscreen: false,
  toggleFullscreen: () => set({ fullscreen: !get().fullscreen }),
  visible: false,
  toggleVisibility: () =>
    set({
      visible: !get().visible,
      fullscreen: !get().visible ? false : get().fullscreen,
    }),
  selections: [],
  onSelect: (id) => set({ selections: [...get().selections, id] }),
  onDeSelect: (id) =>
    set({ selections: get().selections.filter((item) => item !== id) }),
  isConfirmationModalOpen: false,
  toggleConfirmationModal: (open) =>
    set({
      isConfirmationModalOpen:
        open !== undefined ? open : !get().isConfirmationModalOpen,
    }),
}));
