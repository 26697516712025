import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function MinimizeIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1644 1.44867C20.3554 1.63678 20.3554 1.94173 20.1644 2.12974L13.6647 8.53156L17.8628 8.53156C18.1328 8.53156 18.3518 8.74718 18.3518 9.01316C18.3518 9.27913 18.1328 9.49475 17.8628 9.49475L12.4842 9.49475C12.3545 9.49475 12.2302 9.44401 12.1384 9.35369C12.0468 9.26337 11.9953 9.14089 11.9953 9.01316L11.9953 3.71562C11.9953 3.44965 12.2142 3.23403 12.4842 3.23403C12.7542 3.23403 12.9732 3.44965 12.9732 3.71562L12.9732 7.85048L19.4729 1.44867C19.6639 1.26066 19.9734 1.26066 20.1644 1.44867Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45084 20.1667C1.25988 19.9786 1.25988 19.6736 1.45084 19.4856L7.95057 13.0838H3.75243C3.48239 13.0838 3.26347 12.8682 3.26347 12.6022C3.26347 12.3362 3.48239 12.1206 3.75243 12.1206L9.13102 12.1206C9.26069 12.1206 9.38508 12.1713 9.47681 12.2617C9.56844 12.352 9.61998 12.4745 9.61998 12.6022V17.8997C9.61998 18.1657 9.40102 18.3813 9.13102 18.3813C8.86101 18.3813 8.64206 18.1657 8.64206 17.8997V13.7649L2.14233 20.1667C1.95138 20.3547 1.64179 20.3547 1.45084 20.1667Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}
