import {
  ChangeEvent,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { promptOptions } from '@studio/features/brainstorm';
import { ChannelInfo } from '@studio/features/projects';
import {
  DEFAULT_PROJECT_TITLE_MAX_SIZE,
  PANEL_CONTROL_INPUTS,
} from '@studio/features/projects/constants';
import { useIdeationStore } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useBrainstorm, useCopyToClipboard } from '@studio/hooks';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { useModalStore, useParamStore } from '@studio/stores';
import {
  EXIT_IDEATION,
  IDEATE_OPTION,
  IdeateOptionType,
  PACKAGE,
  PROJECT_ELEMENT,
} from '@lib/types';
import { Icons, IdeationElement, TextArea, MenuAction, Toast } from '@lib/ui';
import * as Styles from './project-elements.css';
import { useProjectPackageAnalytics } from './use-project-package-analytics';

export const ProjectTitle = forwardRef(
  (
    { onBrainstorm, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const type = PROJECT_ELEMENT.TITLE;

    const { t } = useTranslation();

    const { toast } = Toast.useToast();

    const [copyToClipboard] = useCopyToClipboard();

    const { openModal } = useModalStore();
    const { projectStore } = useContext(ProjectStoreContext);

    const { setParams } = useParamStore<ProjectQueryParams>();

    const {
      updateElement,
      deleteElement,
      elementState,
      removeElementFromPrimary,
    } = useStore(projectStore, (state: ProjectStateType) => ({
      elementState: state[type],
      updateElement: state.updateElement,
      deleteElement: state.deleteElement,
      removeElementFromPrimary: state.removeElementFromPrimary,
    }));

    const [createMode, setCreateMode] = useState<boolean>(false);

    const ACTIONS = MenuAction.MENU_ACTION;
    const primaryContent =
      (elementState.options &&
        elementState.options.find((item) => item.id === elementState.primary)
          ?.content) ||
      '';
    const hasContent = primaryContent.trim().length > 0;
    const [value, setValue] = useState(primaryContent);

    const { closeIdeation, startIdeation, ideaPanes } = useIdeationStore();
    const { startBrainstorm } = useBrainstorm(type);

    const titleTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      setValue(primaryContent);
    }, [primaryContent]);

    const onOpenSpider = () => {
      setParams({
        [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
        [ProjectQueryParams.PANEL_VISIBILITY]: 'on',
        [ProjectQueryParams.ELEMENT]: type,
      });
      startIdeation();
    };

    const handleInitiateBrainstorm = () => {
      onOpenSpider();
      if (!hasContent) {
        if (onBrainstorm) {
          onBrainstorm({});
        }
        startBrainstorm({ input: { name: 'emptyState' } });
      }
    };

    const handleExitBrainstorm = () => {
      if (ideaPanes?.length > 0) {
        openModal(EXIT_IDEATION);
      } else {
        closeIdeation();
      }
    };

    const updateElementState = (value: string) => {
      const uuid = createMode ? uuidv4() : elementState.primary || '';
      updateElement(type, value.trim(), uuid);
      setCreateMode(false);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      updateElementState(event.target.value);

      if (event.target.value.length >= DEFAULT_PROJECT_TITLE_MAX_SIZE) {
        toast({
          message: t(
            `Title has a max character limit of ${DEFAULT_PROJECT_TITLE_MAX_SIZE}`
          ),
          id: 'project-title-max',
        });
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      if (!inputValue || inputValue === value) {
        return;
      }
      updateElementState(value);
    };

    const { onDeleteElement, onCopyElement, onCreateElement } =
      useProjectPackageAnalytics(projectStore.getState().id);

    const handleCreate = () => {
      setCreateMode(true);
      removeElementFromPrimary(type);
      onCreateElement(type);
    };

    const focusTitleTextArea = () => {
      if (titleTextAreaRef.current) {
        titleTextAreaRef.current.focus();
      }
    };

    const handleDelete = () => {
      if (elementState.primary) {
        deleteElement(type, elementState.primary);
        onDeleteElement(type);
      }
    };

    const handleCopy = () => {
      copyToClipboard(primaryContent);
      onCopyElement(type);
    };

    const handleBrainstorm = (
      variant: IdeateOptionType,
      value?: Record<string, string>
    ) => {
      const properties = {
        [type]: projectStore.getState().getPrimary(type),
        ...value,
      };
      if (onBrainstorm) {
        onBrainstorm({
          variant,
          properties,
        });
      }
      startBrainstorm({
        input: {
          name: variant,
          value: properties,
        },
      });
    };

    const includedPrompts = [
      {
        value: IDEATE_OPTION.REPHRASE,
        handler: () => handleBrainstorm(IDEATE_OPTION.REPHRASE),
      },
      {
        value: IDEATE_OPTION.SHORTEN,
        handler: () => handleBrainstorm(IDEATE_OPTION.SHORTEN),
      },
      {
        value: IDEATE_OPTION.EXPLODE,
        handler: () => handleBrainstorm(IDEATE_OPTION.EXPLODE),
      },
      {
        value: IDEATE_OPTION.MOOD_SHIFT,
        handler: () => handleBrainstorm(IDEATE_OPTION.MOOD_SHIFT),
      },
      {
        value: IDEATE_OPTION.THIS_BUT,
        handler: (value?: Record<string, string>) =>
          handleBrainstorm(IDEATE_OPTION.THIS_BUT, value),
      },
    ];

    const ideationOptions = promptOptions(includedPrompts, type);

    const actions = [
      {
        type: ACTIONS.CREATE_NEW,
        label: t('Add new'),
        icon: <Icons.AddToProjectIcon aria-hidden />,
        handler: handleCreate,
      },
      {
        type: ACTIONS.COPY,
        label: t('Copy'),
        icon: <Icons.CopyIcon aria-hidden />,
        handler: handleCopy,
      },
      {
        type: ACTIONS.DELETE,
        label: t('Delete'),
        icon: <Icons.DeleteIcon aria-hidden />,
        handler: handleDelete,
      },
    ];

    return (
      <IdeationElement.Root id={type} ref={ref} {...props}>
        <div className={Styles.inputContainer}>
          <TextArea
            autoHeight
            resize="none"
            ref={titleTextAreaRef}
            variant="light"
            border="none"
            fill="none"
            radii="sm"
            size="lg"
            rows={1}
            className={Styles.titleInput}
            placeholder={t(`Add ${type}`)}
            preventNewLine
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={DEFAULT_PROJECT_TITLE_MAX_SIZE}
          />
          {value ? (
            <IdeationElement.Actions
              className={Styles.titleActions}
              type={type}
              align="end"
              alignOffset={0}
              side="bottom"
              actions={actions}
              onMenuClose={focusTitleTextArea}
            />
          ) : null}
        </div>
        <ChannelInfo />
        <IdeationElement.OptionsContainer className={Styles.titleOptions}>
          <IdeationElement.Options
            id={type}
            group={PACKAGE}
            noOptions={!hasContent}
            onBrainstorm={handleInitiateBrainstorm}
            onClose={handleExitBrainstorm}
            onOpen={onOpenSpider}
            options={ideationOptions}
          />
        </IdeationElement.OptionsContainer>
      </IdeationElement.Root>
    );
  }
);
