import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function EditIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2565 1.48854C24.9079 0.837152 25.964 0.837154 26.6154 1.48854L30.5115 5.38459C31.1628 6.03598 31.1628 7.09209 30.5115 7.74348L29.6439 8.611L27.5209 10.734L25.3979 8.611L23.389 6.60205L21.266 4.47906L23.389 2.35606L24.2565 1.48854ZM4.00237 25.5797L19.3382 10.3834L21.4498 12.495L6.08675 27.9976H4.00237V25.5797ZM19.3478 6.14704L25.6862 12.4854L7.33834 31H2.00079C1.44807 31 1 30.5519 1 29.9992V24.3281L19.3478 6.14704Z"
        fill="currentColor"
      />
    </svg>
  );
}
