export function isValidUrl(url: string, options?: { pathname: boolean }) {
  try {
    const validUrl = new URL(url);
    if (!['http:', 'https:'].includes(validUrl.protocol)) {
      return false;
    }
    if (options?.pathname && validUrl.pathname === '/') {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}
