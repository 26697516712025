import { ReactNode } from 'react';

export const EXIT_IDEATION = 'exit-ideation-modal';
export const PACKAGE = 'package';

export const IDEATE_OPTION = {
  DIVERSIFY: 'diversify',
  ELABORATE: 'elaborate',
  EXPRESSIONS: 'expressions',
  MOOD_SHIFT: 'moodShift',
  EXPLODE: 'explode',
  INTENSIFY: 'intensify',
  REPHRASE: 'rephrase',
  SHORTEN: 'shorten',
  THIS_BUT: 'thisBut',
  VARIATIONS: 'variations',
  COMPOSITIONS: 'compositions',
} as const;

export type IdeateOptionType =
  (typeof IDEATE_OPTION)[keyof typeof IDEATE_OPTION];

export type OptionItem = {
  value: IdeateOptionType;
  label: string;
  handler?: (value?: Record<string, string>) => void;
  icon?: ReactNode;
  selected?: boolean;
  tooltip?: string;
};

export const THUMBNAIL_STYLE = {
  CONCEPT_ART: 'conceptArt',
  REALISTIC: 'realistic',
  SKETCH: 'sketch',
} as const;
