import {
  IDEATE_OPTION,
  IdeateOptionType,
  ProjectElementType,
} from '@lib/types';

type OptionHandler = {
  value: IdeateOptionType;
  handler: (value?: Record<string, string>) => void;
};

type IdeationOption = {
  value: IdeateOptionType;
  label: string;
  tooltip?: string;
  handler: (value?: Record<string, string>) => void;
};

export const promptOptions = (
  options: OptionHandler[],
  type: ProjectElementType
): IdeationOption[] => {
  return options.map((option) => {
    let label = '';
    let tooltip = '';

    switch (option.value) {
      case IDEATE_OPTION.REPHRASE:
        label = 'Rephrase';
        tooltip = `Creatively rewrite your ${type}`;
        break;
      case IDEATE_OPTION.SHORTEN:
        label = 'Shorten';
        tooltip = `Generate snappy, concise versions of your ${type}`;
        break;
      case IDEATE_OPTION.EXPLODE:
        label = 'Explode';
        tooltip = `Quickly reframe your ${type} using YouTube best practices`;
        break;
      case IDEATE_OPTION.VARIATIONS:
        label = 'Variations';
        tooltip = `Get more ${type}s in this style`;
        break;
      case IDEATE_OPTION.EXPRESSIONS:
        label = 'Expressions';
        tooltip = `Generate alternatives of this ${type} based on different emotions`;
        break;
      case IDEATE_OPTION.COMPOSITIONS:
        label = 'Compositions';
        tooltip = 'Change composition according to YouTube best practices';
        break;
      case IDEATE_OPTION.MOOD_SHIFT:
        label = 'Mood shift';
        tooltip = `Generate alternatives of this ${type} based on different emotions`;
        break;
      case IDEATE_OPTION.INTENSIFY:
        label = 'Intensify';
        tooltip = 'Dial up the tension';
        break;
      case IDEATE_OPTION.THIS_BUT:
        label = 'This, but';
        tooltip = `Redirect the selected ${type} in a specific way, for example "make it in space" or "cats instead of dogs"`;
        break;
      default:
        label = 'Empty';
        tooltip = '';
    }

    return {
      value: option.value,
      label,
      tooltip,
      handler: option.handler,
    };
  });
};
