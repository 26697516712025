import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function AIIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.0002 0L16.7399 2.75L14.0005 3.99976L16.7399 5.26012L18.0002 7.99952L19.25 5.26012L22 3.99976L19.25 2.75M7.99952 3.00048L5.5 8.50048L0 11L5.5 13.4995L7.99952 18.9995L10.499 13.4995L15.999 11L10.499 8.50048M17.9989 14.0005L16.7386 16.7399L13.9992 18.0002L16.7386 19.25L17.9989 22L19.2487 19.25L21.9987 18.0002L19.2487 16.7399"
        fill="currentColor"
      />
    </svg>
  );
}
