import * as VisuallyHiddenPrimitive from '@radix-ui/react-visually-hidden';

export function VisuallyHidden(
  props: VisuallyHiddenPrimitive.VisuallyHiddenProps
) {
  return (
    <VisuallyHiddenPrimitive.Root>
      {props.children}
    </VisuallyHiddenPrimitive.Root>
  );
}
