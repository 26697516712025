import React, { Fragment } from 'react';
import { PowerKeyword } from '@lib/types';
import { groupOverlappingKeywords } from './group-overlapping-keywords';
import { OverlapType, PowerKeywordMarker } from './power-keyword-marker';

interface PowerKeywordsProps {
  powerKeywords: PowerKeyword[];
  text: string;
  className?: string;
  onPowerKeywordHover?: (keywordData: PowerKeyword) => void;
  onPowerKeywordClick?: (keywordData: PowerKeyword) => void;
}

const getOverlapType = (groupIndex: number): OverlapType =>
  groupIndex % 2 === 0 ? 'single' : 'overlapping';

export const PowerKeywords = ({
  powerKeywords,
  text,
  className,
  onPowerKeywordHover,
  onPowerKeywordClick,
}: PowerKeywordsProps) => {
  const groupedKeywords = groupOverlappingKeywords(powerKeywords);

  const handlePowerKeywordClick = (keywordData: PowerKeyword) => {
    if (onPowerKeywordClick) {
      onPowerKeywordClick(keywordData);
    }
  };

  const handlePowerKeywordHover = (keywordData: PowerKeyword) => {
    if (onPowerKeywordHover) {
      onPowerKeywordHover(keywordData);
    }
  };

  // Render layer of overlaping or non overlaping keywords.
  const renderGroup = (group: PowerKeyword[], groupIndex: number) => {
    const fragments: React.ReactNode[] = [];
    let currentIndex = 0;

    group.forEach((keyword, index) => {
      const { startPos, content, data } = keyword;

      if (currentIndex < startPos) {
        fragments.push(
          <Fragment key={`text-${groupIndex}-${currentIndex}`}>
            {text.slice(currentIndex, startPos)}
          </Fragment>
        );
      }

      const keywordText = text.slice(startPos, startPos + content.length);
      const hidden = content.toLowerCase() !== keywordText.toLowerCase();

      fragments.push(
        <PowerKeywordMarker
          overlapType={getOverlapType(groupIndex)}
          key={`keyword-${index}`}
          hidden={hidden}
          data={data}
          onPowerKeywordHover={() => handlePowerKeywordHover(keyword)}
          onPowerKeywordClick={() => handlePowerKeywordClick(keyword)}
        >
          {keywordText}
        </PowerKeywordMarker>
      );

      currentIndex = startPos + content.length;
    });

    if (currentIndex < text.length) {
      fragments.push(
        <Fragment key={`text-${groupIndex}-end`}>
          {text.slice(currentIndex)}
        </Fragment>
      );
    }

    return fragments;
  };

  return (
    <>
      {groupedKeywords
        .slice() // Create a shallow copy to avoid mutating the original array
        .reverse() // Reverse the order to have the leftmost keyword be at the top
        .map((group, index) => (
          <div
            key={`overlap-group-${index}`}
            className={className}
            style={{
              position: 'absolute',
              display: 'inline',
              height: 'inherit',
              alignContent: 'flex-start',
              visibility: 'hidden',
            }}
          >
            {renderGroup(group, index)}
          </div>
        ))}
    </>
  );
};
