import React, {
  useEffect,
  ForwardedRef,
  forwardRef,
  useState,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Toast from '@radix-ui/react-toast';
import clsx from 'clsx';
import { Icons } from '@lib/ui';
import * as styles from './toast.css';

interface ToastProviderProps {
  children?: ReactNode;
}

/**
 * Toast Provider
 */
export const Provider = Toast.Provider;

/**
 * Toast Root
 */
export const Root = Toast.Root;

/**
 * Toast Description
 */
export const Description = forwardRef(
  (props: Toast.ToastDescriptionProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Toast.Description
        ref={ref}
        {...props}
        className={clsx(props.className, styles.toastDescription)}
      />
    );
  }
);
Description.displayName = 'ToastDescription';

/**
 * Toast Action
 */
export const Action = forwardRef(
  (props: Toast.ToastActionProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Toast.Action ref={ref} {...props} className={clsx(props.className)} />
    );
  }
);
Action.displayName = 'ToastAction';

/**
 * Toast Close
 */
export const Close = forwardRef(
  (props: Toast.ToastCloseProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Toast.Close
        ref={ref}
        {...props}
        className={clsx(styles.toastClose, props.className)}
      />
    );
  }
);
Close.displayName = 'ToastClose';

/**
 * Toast Description Underline
 */
type ToastDescriptionUnderlinedProps = { text: string };

export const ToastDescriptionUnderlined = ({
  text,
}: ToastDescriptionUnderlinedProps) => {
  return <span className={clsx(styles.toastCtaUnderline)}>{text}</span>;
};

/**
 * Toast Content
 */
type ToastContentProps = Toast.ToastProps & {
  className?: string;
  duration?: number;
  showTimer?: boolean;
  variant?: 'default' | 'alert';
  cta?: boolean;
  icon?: ReactNode;
  message?: ReactNode | string;
  actionText?: string;
  closeText?: string;
  actionClick?: () => void;
  showCtaIcon?: boolean; // New prop to control CTA icon visibility
};

export const ToastContent = forwardRef(
  (props: ToastContentProps, ref: ForwardedRef<HTMLLIElement>) => {
    const {
      className,
      duration = 5000,
      showTimer = false,
      cta = false,
      icon,
      message,
      actionClick,
      variant = 'default',
      showCtaIcon = false,
      ...restProps
    } = props;

    const { t } = useTranslation();
    const timerId = `toast-timer-${Math.random().toString(36).substr(2, 9)}`;

    useEffect(() => {
      const timerElement = document.getElementById(timerId);
      if (timerElement) {
        timerElement.style.animationDuration = `${duration}ms`;
      }
    }, [duration, timerId]);

    return (
      <Toast.Root
        ref={ref}
        className={clsx(className, styles.toast())}
        duration={duration}
        onOpenChange={(open) => {
          if (!open) {
            props.onOpenChange?.(open);
          }
        }}
        {...restProps}
      >
        <div
          className={`${styles.toastInner} ${
            !cta && !showCtaIcon ? styles.toastExtendedCtaInner : ''
          } `}
        >
          {icon &&
            React.cloneElement(icon as React.ReactElement, {
              className: styles.toastIcon[variant],
            })}
          {/* Render the message prop directly, whether it's a string or React component */}
          <Description>{message}</Description>
          {cta ? (
            <div className={styles.toastCTA}>
              <Close>{props.closeText}</Close>
              <Action
                altText={t('Confirm')}
                onClick={actionClick}
                className={styles.toastIconActionText}
              >
                {props.actionText}
              </Action>
            </div>
          ) : showCtaIcon ? (
            <Close>
              <Icons.CloseIcon className={styles.toastCloseIcon} />
            </Close>
          ) : null}
          {showTimer && (
            <div id={timerId} className={styles.toastTimer[variant]} />
          )}
        </div>
      </Toast.Root>
    );
  }
);
ToastContent.displayName = 'ToastContent';

/**
 * Toast Viewport
 */
export const Viewport = forwardRef(
  (props, ref: ForwardedRef<HTMLOListElement>) => {
    return (
      <Toast.Viewport
        ref={ref}
        {...props}
        className={clsx(styles.toastViewport)}
      />
    );
  }
);
Viewport.displayName = 'ToastViewport';

type ToastContextType = {
  toast: (toast: ToastContentProps) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastContentProps[]>([]);

  const toast = (toast: ToastContentProps) => {
    setToasts((currentToasts) => [...currentToasts, toast]);
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      <Provider>
        {children}
        <Viewport />
        {toasts.map((toast, index) => (
          <Root key={index} open={true} duration={toast.duration}>
            <ToastContent variant={toast.variant} {...toast} />
          </Root>
        ))}
      </Provider>
    </ToastContext.Provider>
  );
};
