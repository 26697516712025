import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icons, NoResults } from '@lib/ui';

export const RoomConnectionError: React.FC = () => {
  const { t } = useTranslation();

  const retryConnection = () => {
    window.location.reload();
  };

  const navigateToProjectsList = () => {
    window.history.back();
  };

  return (
    <NoResults.Root>
      <NoResults.Icon>
        <Icons.ExclamationTriangleIcon aria-hidden />
      </NoResults.Icon>
      <NoResults.Title text={t('Unable to Load Video Project')} />
      <NoResults.Content>
        {t(
          'There was an issue loading the video project. You can try reloading the page, or go back to the projects list.'
        )}
      </NoResults.Content>
      <NoResults.Actions>
        <Button variant="secondary" onClick={retryConnection}>
          {t('Retry')}
        </Button>
        <Button variant="primary" onClick={navigateToProjectsList}>
          {t('Back to Projects List')}
        </Button>
      </NoResults.Actions>
    </NoResults.Root>
  );
};
