import {
  createContext,
  useContext,
  useState,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { type NavigateFunction } from 'react-router-dom';

type SidebarContextType = {
  activeSlug?: string;
  setActiveSlug: Dispatch<SetStateAction<string>>;
  navigate?: NavigateFunction;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
};

export const SidebarContext = createContext<SidebarContextType | null>(null);

export const SidebarProvider = ({
  children,
  navigate,
}: {
  children: ReactNode;
  navigate?: NavigateFunction;
}) => {
  const [collapsed, setCollapsed] = useState(false); // Default state is not collapsed
  const [activeSlug, setActiveSlug] = useState('');
  return (
    <SidebarContext.Provider
      value={{ activeSlug, setActiveSlug, navigate, collapsed, setCollapsed }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
