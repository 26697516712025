import { useEffect } from 'react';
import { useAuthStore } from '@studio/features/auth';

declare global {
  interface Window {
    intercomSettings: {
      api_base: string;
      app_id: string;
      name: string;
      email: string;
      created_at: string;
    };
    Intercom?: (action: string) => void;
  }
}

const update = () => {
  window && window.Intercom && window.Intercom('update');
};

export default function Intercom() {
  const { user } = useAuthStore();

  useEffect(() => {
    if (user) {
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        created_at: user.createdAt,
      };
      update();
    }
  }, [user]);

  return null;
}
