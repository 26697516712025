import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '@studio/components';
import * as AUTH_EVENTS from '@studio/features/auth/heap.constants';
import { PlanType } from '@studio/features/auth/types';
import { useLocalStorage } from '@studio/hooks';
import useIsMobile from '@studio/hooks/use-is-mobile';
import { trackEvent } from '@studio/lib/heap';
import { Flex, Heading, Label, Text, ToggleGroup } from '@lib/ui';
import {
  NewAuthLayout,
  PricingPlanItems,
  ChooseYourPlan,
  AccentSection,
  AboutSpotter,
  NewAuthFooter,
} from '../../../components';
import {
  PARTNER_STACK_KEY,
  PartnerStackUTMParams,
  useFetchStripePlanById,
} from '../../../hooks';
import * as Styles from './pricing-plans.css';

const defaultStripePlanId = import.meta.env.VITE_STRIPE_STUDIO_PLAN_ID;

export function PricingPlans() {
  const { t } = useTranslation();
  const [planType, setPlanType] = useState<PlanType>(PlanType.Monthly);
  const { getItem } = useLocalStorage();
  const utmParams = getItem<PartnerStackUTMParams>(PARTNER_STACK_KEY);
  const planId = utmParams?.plan;
  const stripeProductId = !planId ? defaultStripePlanId : planId;
  const { data: plan, isLoading } = useFetchStripePlanById(stripeProductId);
  const isMobile = useIsMobile();

  useEffect(() => {
    trackEvent(AUTH_EVENTS.SUBSCRIPTION_SIGNUP_SIGN_IN_WITH_GOOGLE_CLICK);
  }, []);

  if (isLoading && !plan) {
    return (
      <AuthLayout>
        <Flex justifyContent="center" alignItems="center">
          <Heading size="36">{t('Loading...')}</Heading>
        </Flex>
      </AuthLayout>
    );
  }

  if (!plan) {
    return (
      <AuthLayout>
        <Flex justifyContent="center" alignItems="center">
          <Heading size="36">{t('No plans available.')}</Heading>
        </Flex>
      </AuthLayout>
    );
  }

  if (isMobile) {
    const planDiscount = plan.discount?.percentage
      ? `${plan.discount.percentage}%`
      : null;

    const handleToggle = (type: string) => {
      type && setPlanType(type as PlanType);
      trackEvent(AUTH_EVENTS.SUBSCRIPTION_SIGNUP_PLAN_TOGGLE);
    };

    return (
      <AuthLayout>
        <Flex
          flexDirection="column"
          alignItems="center"
          className={Styles.container}
        >
          <Text className={Styles.header}>{t('Choose your plan')}</Text>
          <ToggleGroup.Root
            type="single"
            size="sm"
            pill={true}
            variant="subtle"
            value={planType}
            onValueChange={handleToggle}
            className={Styles.toggleGroup}
          >
            <ToggleGroup.Item
              id={PlanType.Monthly}
              value={PlanType.Monthly}
              aria-label={PlanType.Monthly}
              label={PlanType.Monthly}
              className={Styles.toggleItem}
            >
              <Text size="14" className={Styles.toggleName}>
                {t('Monthly')}
              </Text>
            </ToggleGroup.Item>
            <ToggleGroup.Item
              id={PlanType.Yearly}
              value={PlanType.Yearly}
              aria-label={PlanType.Yearly}
              label={PlanType.Yearly}
              className={Styles.toggleItem}
            >
              <Text size="14" className={Styles.toggleName}>
                {t('Yearly')}
              </Text>
              {planDiscount && (
                <Label className={Styles.discountLabel}>
                  {t(`Save ${planDiscount}`)}
                </Label>
              )}
            </ToggleGroup.Item>
          </ToggleGroup.Root>
          <PricingPlanItems plan={plan} planType={planType} />
        </Flex>
      </AuthLayout>
    );
  }

  return (
    <NewAuthLayout>
      <Flex>
        <ChooseYourPlan plan={plan} className={Styles.focusSection} />
        <AccentSection className={Styles.horizontalAccentSection} />
      </Flex>
      <AccentSection className={Styles.verticalAccentSection} />
      <Flex>
        <AboutSpotter />
      </Flex>
      <NewAuthFooter className={Styles.footer} />
    </NewAuthLayout>
  );
}
