import { ForwardedRef, PropsWithChildren, forwardRef } from 'react';
import clsx from 'clsx';
import * as Styles from './profile-card.css';
import { ProfileCardVariants } from './types';

type BaseProps = PropsWithChildren & {
  className?: string;
};

/**
 * Profile Card Root
 */
export const Root = forwardRef(
  (
    props: BaseProps & ProfileCardVariants,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      align = 'center',
      elevation = 'none',
      justify = 'start',
      layout = 'horizontal',
      radii = 'none',
      gap = 8,
    } = props;

    return (
      <div
        className={clsx(
          Styles.rootVariants({
            align,
            elevation,
            justify,
            layout,
            radii,
            gap,
          }),
          props.className
        )}
        ref={ref}
      >
        {props.children}
      </div>
    );
  }
);

Root.displayName = 'ProfileCardRoot';

/**
 * Profile Card Image
 */
export const Image = forwardRef(
  (props: BaseProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <div className={props.className} {...props} ref={ref} />;
  }
);

Image.displayName = 'ProfileCardImage';

/**
 * Profile Card Content wrapper
 */
export const Content = forwardRef(
  (
    props: BaseProps & Pick<ProfileCardVariants, 'align' | 'justify'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { align = 'center', justify = 'start' } = props;
    return (
      <div
        className={clsx(
          Styles.contentVariants({ align, justify }),
          props.className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);

Content.displayName = 'ProfileCardContent';

/**
 * Profile Card Title
 */
export const Title = forwardRef(
  (props: BaseProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <div className={props.className} {...props} ref={ref} />;
  }
);

Title.displayName = 'ProfileCardTitle';

/**
 * Profile Card Description
 */
export const Description = forwardRef(
  (props: BaseProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <div className={props.className} {...props} ref={ref} />;
  }
);

Description.displayName = 'ProfileCardDescription';
