'use client';

import { useEffect } from 'react';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

interface UserData {
  email: string;
  id: string;
  name: string;
  [id: string]: string;
}

const defaultRumInitConfig = {
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  // Specify URLs to propagate trace headers for connection between RUM and backend trace
  allowedTracingUrls: [
    {
      /**
       * Matches (for example):
       * - https://app.spotterstudio.com
       * - https://beta.spotterstudio.com
       * - https://labs.spotterstudio.com
       * - https://spotter.la
       * - http://labs.spotter.la
       * - https://labs-dev.preview.spotter-dev.com
       * - https://studio-dev.preview.spotter-dev.com
       * - https://preview.spotter-dev.com
       * - https://labs-next-git-my-cool-branch.preview.spotter-dev.com
       */
      match:
        /^http(s)?:\/\/([\w-]+\.)*(spotterstudio\.com|spotter\.la|spotter-dev\.com)$/,
      // Spotter uses W3C Trace Context
      propagatorTypes: ['tracecontext'],
    },
  ],
  // NOTE: Unsure if we need the trackSessionAcrossSubdomains flag...
  // trackSessionAcrossSubdomains: true,
} as Omit<RumInitConfiguration, 'clientToken' | 'site'>;

const defaultLogsInitConfig = {
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  // NOTE: Unsure if we need the trackSessionAcrossSubdomains flag...
  // trackSessionAcrossSubdomains: true,
} as Omit<LogsInitConfiguration, 'clientToken' | 'site'>;

type CommonConfiguration = 'clientToken' | 'env' | 'site' | 'version';

export function Datadog({
  clientToken,
  site,
  env,
  logsConfig,
  rumInitConfig,
  userData,
  version,
}: {
  clientToken: string;
  site: string;
  env: string;
  rumInitConfig?: Omit<RumInitConfiguration, CommonConfiguration>;
  logsConfig?: Omit<LogsInitConfiguration, CommonConfiguration>;
  userData?: UserData;
  version: string;
}) {
  useEffect(() => {
    const context = datadogRum.getInternalContext();

    // If Datadog not initialized...
    if (!context) {
      // Initialize Logs
      datadogLogs.init({
        clientToken,
        env,
        site,
        version,
        ...defaultLogsInitConfig,
        ...logsConfig,
      });

      // Initialize Real User Monitoring (RUM)
      datadogRum.init({
        clientToken,
        env,
        site,
        version,
        ...defaultRumInitConfig,
        ...rumInitConfig,
      });

      // Add user profile data
      if (userData) {
        datadogRum.setUser(userData);
      }
    }
  }, []);

  // Nothing to render
  return null;
}
