/**
 * Downloads an image from a given source URL and assigns it a file name based on the provided ID.
 * If the source URL has an extension, it appends the extension to the file name.
 * The image is downloaded by fetching it as a blob, creating an object URL, and triggering a download.
 * Handles potential CORS issues by catching errors.
 *
 * @param {string} [src] - The source URL of the image to be downloaded. If not provided or empty, the function will not execute.
 * @param {string} [id] - An optional identifier to be included in the file name. The final file name will be in the format `spotter-studio-image-{id}`.
 */

export const downloadImage = async (src?: string, id?: string) => {
  if (!src || !src.length) {
    return;
  }

  try {
    const data = await fetch(src);
    const blob = await data.blob();
    const contentType = blob.type;
    const extension = contentType.split('/')[1];

    const fileName = `thumbnail.${id}.${extension}`;

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}`;
    link.click();
  } catch (error) {
    console.error('Failed to download image', error);
    openImageInNewTab(src, id);
  }
};

/**
 * Opens the image in a new tab if downloading fails or as a fallback method.
 *
 * @param {string} src - The source URL of the image.
 * @param {string} id - The identifier to be included in the file name.
 */
const openImageInNewTab = (src: string, id?: string) => {
  const link = document.createElement('a');
  link.href = src;
  link.download = `thumbnail.${id}`;
  link.target = '_blank';
  link.click();
};
