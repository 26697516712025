import { DocumentNode } from 'graphql';
import client from '@studio/graphql-client';
import { VideoDataPagination } from '@lib/gql/graphql';
import { GET_VIDEOS_QUERY } from '../queries';

export const listVideos = async ({
  pageParam = 1,
  ...rest
}): Promise<VideoDataPagination> => {
  const input = { ...rest.params, page: pageParam.toString() };

  const { videos } = await client.request<{ videos: VideoDataPagination }>(
    GET_VIDEOS_QUERY as DocumentNode,
    { input }
  );

  return videos;
};

export default { listVideos };
