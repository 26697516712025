export function getLabsUrl() {
  const { origin, hostname } = window.location;

  // Handle Preview Branch URLs
  if (hostname.includes('.preview.')) {
    if (hostname.startsWith('studio-dev.')) {
      return origin.replace('studio-dev.', 'labs-dev.');
    }
    return origin.replace('studio-', 'labs-next-');
  }

  // Fallback to env variable (PROD, STAGING, etc.)
  return import.meta.env.VITE_LABS_URL;
}
