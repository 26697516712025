import { Node } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';

export const CustomPasteHandler = Node.create({
  name: 'customPasteHandler',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste(
            view: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              state: { tr?: any; selection?: any };
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              dispatch: (arg0: any) => void;
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event: { clipboardData: any; preventDefault: () => void }
          ) {
            const clipboardData = event.clipboardData;
            const text = clipboardData.getData('text/plain');

            if (
              text.startsWith('https://www.youtube.com/') ||
              text.startsWith('https://youtu.be/')
            ) {
              // Prevent default pasting behavior
              event.preventDefault();

              // Insert text instead of iframe
              const { tr } = view.state;
              const { selection } = view.state;
              tr.insertText(text, selection.from, selection.to);
              view.dispatch(tr);

              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
