import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function PlusIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6364 1.63636C16.6364 0.732625 15.9037 0 15 0C14.0963 0 13.3636 0.732625 13.3636 1.63636V13.3636H1.63636C0.732625 13.3636 0 14.0963 0 15C0 15.9037 0.732624 16.6364 1.63636 16.6364H13.3636V28.3636C13.3636 29.2674 14.0963 30 15 30C15.9037 30 16.6364 29.2674 16.6364 28.3636V16.6364H28.3636C29.2674 16.6364 30 15.9037 30 15C30 14.0963 29.2674 13.3636 28.3636 13.3636H16.6364V1.63636Z"
        fill="currentColor"
      />
    </svg>
  );
}
