import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export const calculateFutureDate = (date: Date, type: string): string => {
  if (!date || !type) {
    throw new Error('Both date and type parameters are required.');
  }

  let futureDate;
  if (type === 'month') {
    futureDate = dayjs(date).add(1, 'month');
  } else if (type === 'year') {
    futureDate = dayjs(date).add(1, 'year');
  } else {
    throw new Error('Invalid type parameter. Use "month" or "year".');
  }

  return futureDate.format('MMMM Do, YYYY');
};
