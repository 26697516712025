import { IconProps } from '../types';

export function SortUpIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M9.27831 6.25C9.59906 5.69445 10.4009 5.69444 10.7217 6.25L13.6084 11.25C13.9292 11.8056 13.5283 12.5 12.8867 12.5L7.11325 12.5C6.47175 12.5 6.07081 11.8056 6.39156 11.25L9.27831 6.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
