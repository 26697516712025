import { getLabsUrl } from '@studio/utils/get-labs-url';
import { fetchWithRetry } from './utils';

type HttpMethod = 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';

type RequestOptions = Record<string, unknown>;

function handleErrors(res: Response) {
  if (!res.ok) {
    if (res.status === 401) {
      throw new Error('Unauthorized');
    }
    throw new Error(`${res.status} ${res.statusText}`);
  }
}

class ApiService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new Error('Missing baseUrl in ApiService');
    }
    this.baseUrl = baseUrl;
  }

  async get<T>(pathname: string, options?: RequestOptions): Promise<T> {
    let location = pathname;

    if (options) {
      location +=
        '?' + new URLSearchParams(options as Record<string, string>).toString();
    }

    const req = await this.request('GET', location);

    handleErrors(req);

    try {
      const data = await req.json();
      return data as T;
    } catch (err) {
      return null as T;
    }
  }

  async patch<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('PATCH', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async post<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('POST', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async put<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('PUT', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async delete<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('DELETE', pathname, options);
    handleErrors(req);
    try {
      const data = await req.json();
      return data as T;
    } catch (err) {
      return null as T;
    }
  }

  private async request(
    method: HttpMethod,
    pathname: string,
    options?: RequestOptions
  ): Promise<Response> {
    const url = `${this.baseUrl}${pathname}`;
    const body = options?.data ? JSON.stringify(options.data) : null;
    const config: RequestInit = {
      method,
      credentials: 'include',
      body,
      ...options,
    };

    if (method === 'GET') {
      delete config.body;
    }

    const response = await fetchWithRetry(url, config);
    return response;
  }
}

class Api {
  connect: ApiService;
  bowser: ApiService;
  relayRaptor: ApiService;
  lakitu: ApiService;
  auth0: ApiService;
  boo: ApiService;
  kirby: ApiService;
  labs: ApiService;

  // prettier-ignore
  constructor() {
    this.connect = new ApiService(import.meta.env.VITE_CONNECT_API_URL);
    this.bowser = new ApiService(import.meta.env.VITE_BOWSER_URL);
    this.relayRaptor = new ApiService(import.meta.env.VITE_RELAY_RAPTOR_API_URL);
    this.lakitu = new ApiService(import.meta.env.VITE_BACKEND_API_URL);
    this.auth0 = new ApiService(import.meta.env.VITE_AUTH0_API_URL);
    this.boo = new ApiService(import.meta.env.VITE_BOO_URL);
    this.kirby = new ApiService(import.meta.env.VITE_KIRBY_ORIGIN);
    this.labs = new ApiService(getLabsUrl());
  }
}

export const api = new Api();
