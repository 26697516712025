import type { ReactElement, ReactNode } from 'react';
import { format, subMonths, subWeeks } from 'date-fns';
import { Icons } from '@lib/ui';
import {
  DateRangeFilterInput,
  MadeForKidsFilterToggles,
  OutliersFilterInput,
  SourceFilterToggles,
  SubscribersFilterInput,
  VideoDurationFilterInput,
  VideoViewsFilterInput,
} from './components/filters/inputs';
import { FILTER_PARAMS, SOURCE, SORT_ORDER } from './constants';

type FilterParamValues = (typeof FILTER_PARAMS)[keyof typeof FILTER_PARAMS];
type SortParamValues = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const OUTLIERS_FILTERS = {
  SOURCE: 'source',
  OUTLIER_INDEX: 'outlier_index',
  UPLOAD_DATE: 'upload_date',
  SUBSCRIBER_COUNT: 'subscriber_count',
  VIDEO_VIEWS: 'video_views',
  VIDEO_LENGTH: 'video_length',
  MADE_FOR_KIDS: 'made_for_kids',
} as const;

export type FilterPreset = {
  /**
   * Unique id per filter preset, can be any string.
   */
  id: string;
  /**
   * Human readable label for each filter preset
   */
  label: string;
  /**
   * Controls which filter in the accordion dropdown should be toggled ON when
   * a given filter preset is selected.
   */
  filters: (typeof OUTLIERS_FILTERS)[keyof typeof OUTLIERS_FILTERS][];
  /**
   * Icon to display in the filter preset chip
   */
  icon: ReactElement;
  /**
   * Set the params to filter Outliers by
   */
  params: Partial<Record<FilterParamValues, string>>;
  /**
   * How to sort the given filter preset's results
   */
  sort: SortParamValues;
};

// Note: Order here matters, if you want to re-order these
// in the UI, re-order them here. Top to bottom = left to right.
export const filterPresets: FilterPreset[] = [
  {
    id: 'all_time_big_outliers',
    label: 'All time big outliers',
    icon: <Icons.TrophyIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.OUTLIER_INDEX],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.ALL,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '10',
    },
    sort: SORT_ORDER.PERFORMANCE_INDEX_DESC,
  },
  {
    id: 'all_time_high_views',
    label: 'All time high views',
    icon: <Icons.EyeIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.VIDEO_VIEWS],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.ALL,
      [FILTER_PARAMS.VIEWS_GTE]: '500000',
    },
    sort: SORT_ORDER.VIEWS_DESC,
  },
  {
    id: 'newest_outliers',
    label: 'Newest outliers',
    icon: <Icons.SunIcon aria-hidden />,
    filters: [
      OUTLIERS_FILTERS.SOURCE,
      OUTLIERS_FILTERS.OUTLIER_INDEX,
      OUTLIERS_FILTERS.UPLOAD_DATE,
    ],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.ALL,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '2',
      [FILTER_PARAMS.UPLOADED_START]: format(
        subWeeks(new Date(), 2),
        'MM/dd/yyyy'
      ),
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'my_audience_also_watched',
    label: 'My audience also watched',
    icon: <Icons.TeamIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.UPLOAD_DATE],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.AAW,
      [FILTER_PARAMS.UPLOADED_START]: format(
        subMonths(new Date(), 3),
        'MM/dd/yyyy'
      ),
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'followed_creators_outliers',
    label: 'Followed creators outliers',
    icon: <Icons.FavoriteOnIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE, OUTLIERS_FILTERS.OUTLIER_INDEX],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.FAVORITECHANNELS,
      [FILTER_PARAMS.PERFORMANCE_INDEX_GTE]: '1.1',
    },
    sort: SORT_ORDER.UPLOADED_DESC,
  },
  {
    id: 'my_videos',
    label: 'My Videos',
    icon: <Icons.YouTubeIcon aria-hidden />,
    filters: [OUTLIERS_FILTERS.SOURCE],
    params: {
      [FILTER_PARAMS.SOURCE]: SOURCE.MYVIDS,
    },
    sort: SORT_ORDER.PERFORMANCE_INDEX_DESC,
  },
];

export type OutliersMenuFilterData = {
  label: string;
  input: ReactNode;
  params: string[];
  value: string;
};

export const filters: OutliersMenuFilterData[] = [
  {
    label: 'Source',
    value: OUTLIERS_FILTERS.SOURCE,
    input: <SourceFilterToggles />,
    params: [FILTER_PARAMS.SOURCE],
  },
  {
    label: 'Outlier index',
    value: OUTLIERS_FILTERS.OUTLIER_INDEX,
    input: <OutliersFilterInput />,
    params: [
      FILTER_PARAMS.PERFORMANCE_INDEX_GTE,
      FILTER_PARAMS.PERFORMANCE_INDEX_LTE,
    ],
  },
  {
    label: 'Upload date',
    value: OUTLIERS_FILTERS.UPLOAD_DATE,
    input: <DateRangeFilterInput />,
    params: [FILTER_PARAMS.UPLOADED_END, FILTER_PARAMS.UPLOADED_START],
  },
  {
    label: 'Subscriber count',
    value: OUTLIERS_FILTERS.SUBSCRIBER_COUNT,
    input: <SubscribersFilterInput />,
    params: [FILTER_PARAMS.SUBSCRIBERS_GTE, FILTER_PARAMS.SUBSCRIBERS_LTE],
  },
  {
    label: 'Video views',
    value: OUTLIERS_FILTERS.VIDEO_VIEWS,
    input: <VideoViewsFilterInput />,
    params: [FILTER_PARAMS.VIEWS_GTE, FILTER_PARAMS.VIEWS_LTE],
  },
  {
    label: 'Video length',
    value: OUTLIERS_FILTERS.VIDEO_LENGTH,
    input: <VideoDurationFilterInput />,
    params: [FILTER_PARAMS.DURATION_GTE, FILTER_PARAMS.DURATION_LTE],
  },
  {
    label: 'Made for kids',
    value: OUTLIERS_FILTERS.MADE_FOR_KIDS,
    input: <MadeForKidsFilterToggles />,
    params: [FILTER_PARAMS.MADE_FOR_KIDS],
  },
];
