import { api } from '@studio/lib';
import { Plan } from '../types';

type ApiResponse = {
  status: number;
  message: string;
  data?: Plan;
};

export const fetchStripePlanById = async (
  planId: string
): Promise<Plan | undefined> => {
  try {
    const response = await api.boo.get<ApiResponse>(`/api/plans/${planId}`);
    return response?.data;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
