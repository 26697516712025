'use client';

import { useRef, useState, useCallback } from 'react';
import { KirbyClient } from './kirby-client';
import {
  KirbyConfig,
  KirbyPrompt,
  PromptType,
  Prompt,
} from './kirby-client.types';

export const useKirbyPrompt = (
  type: PromptType,
  { data, username, channel, variant, eventName, source }: Prompt,
  config: KirbyConfig
) => {
  const clientRef = useRef<KirbyClient>();
  const [result, setResult] = useState<unknown>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const meta: KirbyPrompt = {
    data,
    metadata: {
      eventName,
      tracking: {
        variant,
        source,
        app: type,
        user: username,
        channel_ucid: channel,
      },
    },
  };

  const kirbyConfig: KirbyConfig = {
    onResultParsed: setResult,
    onComplete: () => setLoading(false),
    onError: (err) => {
      setLoading(false);
      setError(err);
    },
    ...config,
  };

  const send = useCallback(
    (promptData?: Prompt['data']) => {
      if (clientRef.current) {
        cancel();
      }

      // two ways of supplying the data object:
      // on hook declaration or at time of execution.
      if (promptData) {
        meta.data = promptData;
      }

      if (!Object.keys(meta.data ?? {}).length) {
        console.warn('No data being passed to Kirby client.');
      }

      const connection = new KirbyClient(meta, kirbyConfig);
      clientRef.current = connection;
      setLoading(true);
      connection.start();
    },
    [meta, kirbyConfig]
  );

  const cancel = useCallback(() => {
    setResult(null);
    setLoading(false);
    setError(null);
    clientRef.current?.cancel();
  }, [clientRef.current]);

  return { send, cancel, data: result, isLoading, error };
};
