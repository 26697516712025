export const dashboardRoutes = [
  {
    path: ':organizationId/:channelUcid/dashboard',
    async lazy() {
      const { DashboardRoute } = await import('./dashboard');
      return {
        Component: DashboardRoute,
      };
    },
  },
];
